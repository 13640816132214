import React, {Component} from 'react';
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import UserMobilesList from "../Users/UserMobilesList";
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



class ErrorLogList extends Component {

    constructor(props) {
        super(props);
        console.log("In ErrorLogList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false

        }
    }


    render() {

        let errorLogsToShow = [];

        let elogList = this.props.errorLogs;
        let errorCodeColors = this.props.errorCodeColors;

       // console.log("ILIST " + JSON.stringify(elogList));

        if (elogList === undefined || elogList.length === 0) {
            return <div>No Error Logs</div>
        }


        for (let eLog of elogList) {

            let bgCol = '#90d112';

            if(eLog.type=='app')
            {
                bgCol = '#cdd229'
            }else if(eLog.type=='network')
            {
                bgCol = '#d2859f'
            }
            let date = new Date(eLog.created);

            let thisInvite = <div style={{'background':bgCol}} key={eLog.errorlog_id} >


                <div style={{float:'left',margin:'2px',borderRadius:'15px','width': '19px',background:errorCodeColors[eLog.level]}} >{eLog.level}</div>
                <div style={{'width': '200px'}} className={'listItem'}>{date.toLocaleString([],{ timeZoneName: 'short' })}</div>
                <div style={{'width': '65px'}} className={'listItem'}>{eLog.type}</div>
                <div style={{'width': '280px', 'minHeight': '2PX'}} className={'listItem'}>{eLog.code}</div>
                <div style={{'width': '200px'}} className={'listItem'}>{eLog.invitee_email}</div>
                <div style={{'width': '80px'}} className={'listItem'}>{eLog.invitor}</div>
                <div style={{'width': '90px'}} className={'listItem'}>{eLog.status}</div>
                {/*<div style={{'width': '160px'}} className={'listItem'}>{invite.created.substring(0, 19)}</div>*/}


                <ImpCollapsible collapsed={true} expandText="Details" header={''}>

                    <div style={{'width': '700px', 'marginLeft' : 50, 'textAlign' : 'left', 'margin': '50', 'padding': 30, "border": '0px solid #DDD'}}>
                        <JSONPretty id="json-pretty" data={eLog} theme={JSONPrettyMon}></JSONPretty>
                    </div>

                </ImpCollapsible>


                <div className={'listEnd'}></div>

            </div>

            errorLogsToShow.push(thisInvite);
        }

        errorLogsToShow.reverse();
        return (
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {errorLogsToShow}
                </div>
            </div>
        );
    }
}
export default withAlert()(ErrorLogList);
