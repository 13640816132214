import React, {Component} from 'react';
import '../Admin.css';
import NotificationList from './NotificationsList';
import NotificationasForm from "./NotificationasForm";
import IMPBoxPicker from "../IMPBox/IMPBoxPicker";
import AppUserContext from "../../contexts/AppUserContext";


import {addNotification, getNotificationsForMobile, getIMPBoxesForMobile} from '../../util/apiServiceUtil';
import MobilePicker from "../Mobiles/MobilePicker";

class NotificationAdmin extends Component {

    static contextType = AppUserContext;


    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            data: {notifications: []},
            mobilePicked: undefined,
            impBoxPicked: undefined,
            impboxes: {box_mobile: []},
            notifications:[]
        };
    }

    componentDidMount() {
        // this.getAllNotificationsHandler("init");
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.userContext !== prevProps.userContext) {
            this.setState({
                showForm: false,
                data: {notifications: []},
                mobilePicked: undefined,
                impBoxPicked: undefined,
                impboxes: {box_mobile: []},
                notifications:[]
            });
        }
    }

    getNotificationsForMobileHandler(name) {
        console.log(" getAllNotificationsHandler called...");

        getNotificationsForMobile(
            (notifications) => {
                this.setState({data: notifications});
            },
            (error) => {

                console.log("ERROR in getNotificationsForMobile =  " + error);
                this.setState({"error": error});
            },
        );
    }

    showForm() {
        this.setState({showForm: true})
    }

    updateList() {

        getNotificationsForMobile({"mobile_id":this.state.mobilePicked},
            (notifications) => {
                let mobile = notifications.mobile.box_mobiles[0].Notifications;
                //    let notificationList = mobile.box_mobiles[0].Notifications;

                this.setState({notifications: mobile});
            },
            (error) => {

                console.log("ERROR in getNotificationsForMobile =  " + error);
                this.setState({"error": error});
            },
        );


    }

    pickMobile(mobile_id) {
        this.setState({mobilePicked: mobile_id});

        getIMPBoxesForMobile(mobile_id,
            (mobiles) => {
                this.setState({impboxes: mobiles});
            },
            (error) => {

                console.log("ERROR in getIMPBoxesForMobile =  " + JSON.stringify(error));
                this.setState({"error": error});
            },
        );

    }

    pickIMPBox(impbox_id) {
        this.setState({impBoxPicked: impbox_id});


        getNotificationsForMobile({"mobile_id":this.state.mobilePicked},
            (notifications) => {

                let mobile = notifications.mobile.box_mobiles[0].Notifications;
            //    let notificationList = mobile.box_mobiles[0].Notifications;

                this.setState({notifications: mobile});
            },
            (error) => {

                console.log("ERROR in getNotificationsForMobile =  " + error);
                this.setState({"error": error});
            },
        );
    }

    saveNotification(notification) {

           alert("Sorry this doesn't work");
       // alert(JSON.stringify(newNot));
    }

    render() {

        console.log("data - " + JSON.stringify(this.state.data));
        let _notifyList = this.state.notifications;
        let uc = this.props.userContext;

        let authAs = !!this.context.app_username ? this.context.app_username : "None";

        let mobiles = [];

        let formToShow = <div></div>;
        let formButtonToShow = <div></div>;
        if (this.state.showForm) {
            formToShow = <NotificationasForm onFormSubmit={(notification) => this.saveNotification(notification)}/>
        }

        if (this.state.impBoxPicked !== undefined) {
           // formButtonToShow = <button onClick={() => this.showForm()}>Add Notification</button>
            formButtonToShow = <div></div>
        }

        if (!!uc && !!uc.user) {
            console.log("we have mobiles")
            mobiles = uc.user.Mobiles
        }

        return (
            <div style={{width: "950px"}}>

                <div><p style={{color: 'grey'}}>
                    <b> App User Auth As</b>: {authAs}
                </p></div>

                <div style={{borderRadius:'5px',background:"rgba(37,159,21,0.27)", width:'80%', margin: 'auto'}}>
                    <div><b>Key for detail.History:</b></div>
                    disposition: [“GL” : 0 ,”RL” : 1, ”UNKNOWN” : 2, ”URGENT_CALL” : 3, “UNASSIGNED”: 4]<br/>

                    action: [“RING” : 0, ”TERMINATE” : 1, ”MESSAGE” : 2]<br/>

                    resolution: [“ANSWERED” : 0, ”UNANSWERED” : 1]<br/>

                </div>

                <div>
                    <div className={'fieldHolder'}>
                        <MobilePicker mobiles={mobiles} onChange={(mobile_id) => this.pickMobile(mobile_id)}/>
                    </div>

                    <div className={'fieldHolder'}>

                        <IMPBoxPicker impboxes={this.state.impboxes}
                                      onChange={(impbox_id) => this.pickIMPBox(impbox_id)}/>
                    </div>

                    <div className={'fieldHolder'}>
                        {formButtonToShow}
                    </div>
                    {formToShow}
                    <NotificationList
                        notifications={_notifyList}
                        userContext={uc}
                        status={this.state.error}
                        onRefresh={() => this.updateList()}/>
                </div>
            </div>
        );

    }
}
export default NotificationAdmin;
