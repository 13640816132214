import {getIMPBoxRefreshTokenApi, impBoxAuthenticateApi} from "./apiServiceUtil";

const authenticateAsIMPBox = function (impbox_uuid, setContextIMPBox, dataHandler) {

    let cl = {};
    cl.impbox_uuid = impbox_uuid;

    getIMPBoxRefreshTokenApi(cl, (returnDetails) => {

            console.log("API return from getIMPBoxRefreshTokenApi " + JSON.stringify(returnDetails));

            // Add to  Session.
            localStorage.setItem('impbox_refresh_token', returnDetails.refresh_token);
            localStorage.setItem('impbox_id', returnDetails.impbox_id);
            localStorage.setItem('impbox_uuid', returnDetails.impbox_uuid);

            if (returnDetails.success === true) {
                this.setState({"refresh_success": "Get Refresh Token: Successfull Call"});
            } else {
                this.setState({"refresh_success": "Get Refresh Token: UnSuccessfull Call"});
            }


            let imp_auth = {};
            imp_auth.impbox_uuid = impbox_uuid;
            imp_auth.refresh_token = returnDetails.refresh_token;

            impBoxAuthenticateApi(imp_auth, (returnDetails2) => {

                    console.log("API return from impBoxAuthenticateApi " + JSON.stringify(returnDetails2));

                    // Add to  Session.
                    localStorage.setItem('impbox_access_token', returnDetails2.access_token);

                    if (returnDetails2.success === true) {
                        this.setState({"access_success": "Authenticate: Successfull Call"});
                        let ibx = {
                            impbox_uuid: imp_auth.impbox_uuid,
                            impbox_id: returnDetails.impbox_id
                        }
                        setContextIMPBox(ibx);
                        dataHandler(ibx);
                    } else {
                        this.setState({"access_success": "Authenticate: UnSuccessfull Call"});
                    }

                },
                (error) => {
                    console.log("ERROR in impBoxAuthenticateApi =  " + JSON.stringify(error));
                    this.setState({"error": error});
                });

        },
        (error) => {
            console.log("ERROR in getIMPBoxRefreshTokenApi =  " + JSON.stringify(error));
            this.setState({"error": error});
        });

}

export {
    authenticateAsIMPBox
}