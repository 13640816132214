import React, {Component} from 'react';


import { UploadOutgoingMessage } from "./APIs/UploadOutgoingMessage";

import { UpdateSetting } from "./APIs/UpdateSetting";
import {AuthenticateAsAppUser} from "./APIs/AuthenticateAsAppUser";

class AppSim extends Component {

    constructor(props) {
        super(props);
        console.log("In AppSim constructor");
        this.state = {

        };
    }

    render() {
        return (
            <div>
                {/*<AuthenticateAsAppUser/>*/}
                <UploadOutgoingMessage/>
                <UpdateSetting/>
            </div>
        );
    }
}
export default AppSim;
