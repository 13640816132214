import React, {Component} from 'react';
import {createAdminAccount} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";

class AdminCreate extends Component {

    constructor(props) {
        super(props);
        console.log("In AdminCreate constructor");
        this.state = {
            "adminAccount": {
                "username": "",
                "password": "",
                "role": "CS_ADMIN"
            },
            "error": "",
            "details": {}

        };
    }


    changeText(e, fld_id) {

        let val = e.target.value;
        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        this.setState(change);

    }

    createNewAdmin() {

        console.log("Starting the User Creation Process");

        let adminUser = {
            "username": this.state.adminAccount.username,
            "password": this.state.adminAccount.password,
            "role": this.state.adminAccount.role,
        }

        if (!this.state.adminAccount.username || this.state.adminAccount.username.trim() === ""  || !this.state.adminAccount.password || this.state.adminAccount.password.trim() === "" ){
            this.props.alert.error("Please fill out all fields");
            return;
        }

        createAdminAccount(adminUser, (returnDetails) => {

                console.log("API return from createAdminAccount " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    account_id: returnDetails.account_id
                };
                this.setState({"details": details});
                let success_message = "Created Admin: " + returnDetails.account.account_id

                this.props.alert.success(success_message);
                this.props.refreshAdmins();
            },
            (error) => {

                console.log("ERROR in createAdminAccount =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }


    render() {


        let success_message;
        if (this.state.details.account_id) {
            success_message =
                <div>
                    <p style={{color: 'green'}}>New Admin Account Created: account_id = {this.state.details.account_id}</p>
                </div>
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Create new Admin Account '}>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>username:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.adminAccount.username}
                                               onChange={(event, fld_id) => this.changeText(event, 'adminAccount.username')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>password:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.adminAccount.password}
                                               onChange={(event, fld_id) => this.changeText(event, 'adminAccount.password')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>admin role:</div>
                                    <div className={'fieldInput'}>
                                        <select id={'notification_type'} value={this.state.adminAccount.role}
                                                onChange={(event, fld_id) => this.changeText(event, 'adminAccount.role')}>
                                            <option value={"CS_ADMIN"}>CS Admin</option>
                                            <option value={"ADMIN"}>Admin</option>
                                            <option value={"SUPER_ADMIN"}>Super Admin</option>
                                        </select>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>


                            </div>

                            <button onClick={() => this.createNewAdmin()}>Create Admin Account</button>
                        </div>
                    </div>


                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(AdminCreate);
