export const  disposition = {
    'GL' : 0,
    'RL' : 1,
    'UNKNOWN' : 2,
    'URGENT_CALL' : 3,
    'UNASSIGNED' : 4
};

export const  action = {
    'RING' : 0,
    'TERMINATE' : 1,
    'MESSAGE' : 2
};

export const  resolution = {
    'ANSWERED' : 0,
    'UNANSWERED' : 1
};
