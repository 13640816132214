import React, {Component} from 'react';
import UserMobilesList from './UserMobilesList';
import {ImpCollapsible} from "../Util/ImpCollapsible";
import {deleteUser, archiveUser, getAppAccessTokenApi} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import AppUserContext from "../../contexts/AppUserContext";
import moment from "moment";
import _ from "lodash";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



class UserList extends Component {

    static contextType = AppUserContext;

    constructor(props) {
        super(props);
        console.log("In UserList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false,
            showOwners:true,
            showCoOwners:false,
            showUsers:false,
            showNone:false,
            nameFilter:""
        }
    }

    createNewUser(user_id) {
        console.log("Starting the User Creation  Process");
    }

    userDetails(user_id) {
        console.log("Starting the User Details Process");
    }

    handleModalShowHide() {
        this.setState({showHide: !this.state.showHide})
    }

    changeNameFilter(e)
    {
        this.setState({nameFilter:e.target.value});
    }

    toggleShowUser(whichUser)
    {
        if(whichUser=="showOwners")
        {
            this.setState({showOwners: !this.state.showOwners});
        }else if(whichUser=="showCoOwners")
        {
            this.setState({showCoOwners: !this.state.showCoOwners});
        }else if(whichUser=="showUsers")
        {
            this.setState({showUsers: !this.state.showUsers});
        }else if(whichUser=="showNone")
        {
            this.setState({showNone: !this.state.showNone});
        }

       // this.setState({whichUser: !this.state[whichUser]});
    }

    deleteUser(user_id, username) {

        var answer = window.confirm("Are you sure you want to delete "+ username+" ?");
        if (answer) {

        }
        else {
            return
        }

        console.log("Starting the UserDeletion Process");
        deleteUser(user_id, (returnDetails) => {

                console.log("API return from deleteUser " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    process: returnDetails.process,
                    debug: returnDetails.debug
                };
                this.setState({"details": details});
                this.props.alert.success(details.process);
                console.log("DEBUG: \n" + returnDetails.debug);
                console.log("COMMANDS: \n" + returnDetails.commands);
                this.props.refreshUsers();

            },
            (error) => {

                console.log("ERROR in deleteUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR" + JSON.stringify(error));
            });
    }

    archiveUser(user_id, username) {

        var answer = window.confirm("Are you sure you want to archive "+ username+" ?");
        if (answer) {

        }
        else {
            return
        }


        console.log("Starting the User Archiving Process");
        archiveUser(user_id, (returnDetails) => {

                console.log("API return from archiveUser " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                };
                this.setState({"details": details});
                this.props.refreshUsers();

            },
            (error) => {

                console.log("ERROR in archiveUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR" + JSON.stringify(error));
            });
    }

    authenticateAsAppUser(username, setUsername) {


        let cl = {};
        cl.username = username;

        getAppAccessTokenApi(cl, (returnDetails) => {

                console.log("API return from getAppAccessTokenApi " + JSON.stringify(returnDetails));

                //this.setState({"access_token": returnDetails.jwt});

                console.log(`Adding to  local storage: ${returnDetails.Account.JWT} ${returnDetails.username}`)
                // Add to  Session.
                localStorage.setItem('app_access_token', returnDetails.Account.JWT);
                localStorage.setItem('app_username', returnDetails.Account.username);
                localStorage.setItem('app_user_id', returnDetails.Account.user_id);

                if (returnDetails.success === true) {
                    this.setState({"access_success": "Get Refresh Token: Successfull Call"});
                    setUsername(username);
                } else {
                    this.setState({"access_success": "Get Refresh Token: UnSuccessfull Call"});
                }
            },
            (error) => {
                console.log("ERROR in getAppAccessTokenApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    componentDidMount() {
        console.log("UserList did mount");

    }

    render() {

        let usersToShow = [];
        let uList = this.props.users;

        const username = this.context.app_username;
        const setUsername = this.context.setUsername;


        if (uList === undefined || uList.length === 0) {
            return <div></div>
        }


        let cbRole = _.countBy(uList, "Mobiles[0].box_mobiles[0].Roles[0].role");

        console.log(JSON.stringify(cbRole));

        let userFilter = <div>
            <input type={"text"}></input>
            </div>


        let header = <div key={"user_head"} className={'headerRow'}>
            <div style={{'width': '70px'}} className={'headerItem'}>User Id</div>
            <div style={{'width': '70px'}} className={'headerItem'}>ImpBox</div>
            <div style={{'width': '300px'}} className={'headerItem'}>UserName</div>
            <div style={{'width': '180px'}} className={'headerItem'}>Actions</div>
            <div className={'headerEnd'}></div>
        </div>

        usersToShow.push(header)

        for (let user of uList) {

            let impbox_id = "None";
            let userRole = "None";
            if (!!user.Mobiles && !!user.Mobiles[0] && user.Mobiles[0].box_mobiles[0] && !!user.Mobiles[0].box_mobiles[0] && !!user.Mobiles[0].box_mobiles[0].impbox_id  ) {
                impbox_id = user.Mobiles[0].box_mobiles[0].impbox_id;
                if( !!user.Mobiles && !!user.Mobiles[0] && !!user.Mobiles[0].box_mobiles && !!user.Mobiles[0].box_mobiles[0] && !!user.Mobiles[0].box_mobiles[0].Roles  && !!user.Mobiles[0].box_mobiles[0].Roles[0] ) {
                //    if( !!user.Mobiles && !!user.Mobiles[0] && !!user.Mobiles[0].box_mobiles && !!user.Mobiles[0].box_mobiles[0].Roles[0] && !!user.Mobiles[0].box_mobiles[0].Roles[0].role) {
                    userRole = user.Mobiles[0].box_mobiles[0].Roles[0].role;
                }
            }

            //Eliminate listing by Role
            if((this.state.showOwners===false && userRole==="Owner")||(this.state.showCoOwners===false && userRole==="Co-Owner")||(this.state.showUsers===false && userRole==="User")||(this.state.showNone===false && userRole==="None"))
            {
                continue;
            }

            // eliminate listings by filter
            let daFilter = this.state.nameFilter;
            let daName = user.PII.email;
            if(daFilter!=="" && !daName.toUpperCase().includes(daFilter.toUpperCase()) && !impbox_id.toString().includes(daFilter))
            {

                continue;
            }

            let subStart = "";
            let subColor = "green"
            if(user.Subscriptions[0])
            {
                if(userRole==="None") {

                    let today = new moment();
                let begDate = new moment(user.Subscriptions[0].begin_date);

                let daysDiff = today.diff(begDate, 'days');

                if(userRole==="None") {

                    subStart = "Subscription Start: " + begDate.format("YYYY-MM-DD") + " - "+ (daysDiff +" days");

                    if (daysDiff > 30 && daysDiff < 91) {
                        subColor = "#fdd111"
                    } else if (daysDiff > 90 && daysDiff < 181) {
                        subColor = "orange"
                    } else if (daysDiff > 180) {
                        subColor = "red"
                    }

                }

                }
            }



            let thisUser = <div key={user.user_id} className={'listRow'}>

                <div style={{'width': '70px', 'minHeight': '2PX'}} className={'listItem'}>{user.user_id}</div>
                <div style={{'width': '70px','minHeight': '2PX'}} className={'listItem'}>{impbox_id}</div>
                <div style={{'width': '300px'}} className={'listItem'}>{user.PII.email}</div>
                <div style={{'width': '70px'}} className={'listItem'}>
                    <button onClick={() => this.deleteUser(user.user_id, user.PII.email)}>Delete</button>
                </div>
                <div style={{'width': '70px'}} className={'listItem'}>
                    <button onClick={() => this.archiveUser(user.user_id, user.PII.email)}>Archive</button>
                </div>
                <div style={{'width': '60px'}} className={'listItem'}>
                    <button onClick={() => this.authenticateAsAppUser( user.PII.email, setUsername )}>Login</button>
                </div>



                <ImpCollapsible collapsed={true} expandText="User Details" header={''}>

                    <div style={{'width': '700px', 'marginLeft' : 50, 'textAlign' : 'left', 'margin': '50', 'padding': 30, "border": '0px solid #DDD'}}>
                        <JSONPretty id="json-pretty" data={user} theme={JSONPrettyMon}></JSONPretty>
                    </div>

                </ImpCollapsible>
                <div style={{width:"300px",clear:"both", color:subColor}}> {subStart}</div>

                <div className={'listEnd'}></div>

            </div>

            usersToShow.push(thisUser);
        }

        return (
            <div>
                <div>
                    Users to Show:
                    <label style={{fontWeight: "normal"}}>
                        <input type="checkbox" onChange={()=>this.toggleShowUser("showOwners")} checked={this.state.showOwners} />
                        Owner ({cbRole["Owner"]}) &nbsp;
                    </label>
                    <label style={{fontWeight: "normal"}}>
                        <input type="checkbox" onChange={()=>this.toggleShowUser("showCoOwners")} checked={this.state.showCoOwners} />
                        CoOwner ({cbRole["Co-Owner"]}) &nbsp;
                    </label>
                    <label style={{fontWeight: "normal"}}>
                        <input type="checkbox" onChange={()=>this.toggleShowUser("showUsers")} checked={this.state.showUsers} />
                        User ({cbRole["User"]}) &nbsp;
                    </label>
                    <label style={{fontWeight: "normal"}}>
                        <input type="checkbox" onChange={()=>this.toggleShowUser("showNone")} checked={this.state.showNone} />
                        None ({cbRole["undefined"]}) &nbsp;
                    </label>

                </div>

                <div>
                    UserName or impbox_id contains: <input type={"text"} onChange={this.changeNameFilter.bind(this)} value={this.state.nameFilter} size={"40"} />

                </div>


                <div> Currently Logged In As: {username}</div>
                <div>
                    <div style={{'fontSize': '10pt'}}>
                        {usersToShow}
                    </div>
                </div>
            </div>
        );
    }
}
export default withAlert()(UserList);
