import React, {useState} from 'react';
import './App.css';
import EmailValidate from './components/EmailValidate';
import StripeConfirmation from './components/StripeConfirmation';


import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import useToken from './util/useToken';
import Logout from "./components/Login/Logout";
import Admin from "./components/Admin";
import Login from "./components/Login/Login";
import history from "./util/history";
import AppUserContext from './contexts/AppUserContext';
import IMPBoxContext from './contexts/IMPBoxContext';
import {ga_imp_tracking_id} from './util/config'
import ReactGA from 'react-ga';


function App() {

    const [appUser, setAppUser] = useState("");
    const [authIMPBox, setAuthIMPBox] = useState({});

    const {token, username, role, setToken} = useToken();

    let user = {
        app_username: appUser,
        setUsername: username => {
            setAppUser(username)
        }
    }

    let impbox = {
        impbox_uuid: authIMPBox.impbox_uuid,
        impbox_id: authIMPBox.impbox_id,
        refresh_token: authIMPBox.refresh_token,
        access_token: authIMPBox.access_token,
        currentIMPBox: authIMPBox.currentIMPBox,
        setIMPBox: impbox => {
            console.log(`Updating impbox: ${JSON.stringify(impbox)} `)
            setAuthIMPBox(impbox)
        }
    }

    //Google Analytics
    const TRACKING_ID = ga_imp_tracking_id; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);


    return (

        <IMPBoxContext.Provider value={impbox}>
            <AppUserContext.Provider value={user}>
                <Router history={history}>
                    <div className="App">
                        <meta charSet="UTF-8"/>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                        <link rel="stylesheet"
                              href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css"/>

                        <link
                            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                            rel="stylesheet"/>

                        <Switch>
                            <Route path="/imp-email-validation">
                                <EmailValidate/>
                            </Route>
                            <Route path="/stripe-confirmation">
                                <StripeConfirmation/>
                            </Route>
                            <Route path="/logout">
                                <Logout/>
                            </Route>
                            <Route path="/">
                                {token ? < Admin setToken={setToken} username={username} role={role} log/> :
                                    <Login setToken={setToken}/>}
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </AppUserContext.Provider>
        </IMPBoxContext.Provider>
    );

}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

export default App;
