import React, {Component} from 'react';
import UserList from './UserList';
import UserCreate from './UserCreate';
import AddUserWithSubscription from './AddUserWithSubscription';
import {getAllUsers} from "../../util/apiServiceUtil";


class UserAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            "error": null,
            users : []
        }
    }

    componentDidMount() {
        console.log("User Admin did mount");
        this.refreshUsers();
    }

    refreshUsers()  {

        console.log("Calling getAllUsers from UserAdmin")

        getAllUsers(
            (users) => {
                console.log("users - " + JSON.stringify(users));
                this.setState({users: users.users});
            },
            (error) => {

                console.log("ERROR in getAllUsers =  " + error);
                this.setState({"error": error});
            })

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>
                    <div style={layoutStyle}>
                        <AddUserWithSubscription refreshUsers={() => this.refreshUsers()} />
                    <UserCreate refreshUsers={() => this.refreshUsers()}/>
                    </div>
                        <div style={layoutStyle}>
                    <UserList
                        users={this.state.users}
                        refreshUsers={() => this.refreshUsers()}
                    />
                        </div>
                </div>
            </div>
        );
    }
}

export default UserAdmin;
