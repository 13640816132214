import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible"
import {getIMPBoxUsers} from "../../../util/apiServiceUtil";
import {UserDetails} from "./UserDetails";

class IMPBoxUsers extends Component {

    constructor(props) {
        super(props);
        console.log("In IMPBoxUsers constructor");
        this.state = {
            error: null,
            success: null,
            impboxUsers: null,
            usersToShow: null,
            currentIMPBox: null,
            impbox_id: null
        };
    }

    componentDidUpdate(prevProps) {

        console.log(`IMPBoxUsers: in componentDidUpdate... Prev: ${prevProps.impbox_id} Current: ${this.props.impbox_id}  `)

        if (prevProps.impbox_id !== this.props.impbox_id || !this.state.usersToShow ) {
            this.setState({
                impbox_id: this.props.impbox_id
            });

            // Clear existing State.
            this.setState({
                usersToShow: []
            });

            console.log(`Props Changed in IMPBoxUsers : ${this.props.impbox_id} `)

            this.getAllIMPBoxUsers(this.props.impbox_id, (err, resp) => {

                    let daUsers = this.state.impboxUsers;
                    let usersToShow = [];


                    if (daUsers) {
                        for (let user of daUsers.users) {
                            let thisUser = <UserDetails key={"user" + user.user_id} user={user}/>;
                            usersToShow.push(thisUser);
                        }
                    }

                    if (daUsers) {
                        for (let user of daUsers.invitees) {
                            let thisUser = <UserDetails key={"user" + user.user_id} user={user} isInvitee={true}/>;
                            usersToShow.push(thisUser);
                        }
                    }

                    if (usersToShow.length > 0) {
                        this.setState({
                            usersToShow: usersToShow
                        });
                    } else {
                        this.setState({
                            usersToShow: "No Users associated with IMPBox."
                        });

                    }

                }
            )
        }
    }

    getAllIMPBoxUsers(impbox_id, callback) {

        let callData = {
            "impbox_id": impbox_id
        }

        console.log(`Calling getIMPBoxUsers ... w/ impbox_id =  ${impbox_id}`);

        this.setState({"error": null});
        this.setState({"success": null});
        this.setState({"impboxUsers": null});

        getIMPBoxUsers(
            callData,
            (response) => {
                console.log(`Setting success to ${response.success} `)
                console.log(`Setting impboxUsers to ${JSON.stringify(response)} `)
                this.setState({impboxUsers: response});
                this.setState({success: response.success});
                if(!!response.users && !!response.users[0] && !!response.users[0].boxmobiles && response.users[0].boxmobiles[0]) {
                    this.props.ownerFunct(response.users[0].boxmobiles[0].box_mobile_id);
                } else {
                    this.props.ownerFunct( undefined);
                }
                return callback(null, response);

            },
            (error) => {
                console.log("ERROR in getIMPBoxUsers =  " + error);
                this.setState({"error": error});
                return callback(error);
            })
    }

    render() {

        console.log(`Rendering IMPBoxUsers with ${this.state.currentIMPBox} Props: ${this.props.impbox_id} `)
        let api_result;
        if (this.state.success == true) {
            api_result = "API Call Completed";
        }
        let api_error;
        if (!!this.state.error && !!this.state.error.details) {
            api_error = this.state.error.details;
            console.log(`api_error = ${JSON.stringify(api_error)}`);
        } else {
            api_error = "";
        }

        return (
                <div >
                    {this.state.usersToShow}
                </div>

        );

    }

}
export {IMPBoxUsers};

