import React, {Component} from 'react';
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import UserMobilesList from "../Users/UserMobilesList";
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



class FirmwareList extends Component {

    constructor(props) {
        super(props);
        console.log("In FirmwareList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false

        }
    }


    render() {

        let firmwaresToShow = [];
        let iList = this.props.firmwares;
        console.log("ILIST " + JSON.stringify(iList));

        if (iList === undefined || iList.length === 0) {
            return <div>No Firmwares</div>
        }

        let header = <div key={"user_head"} className={'headerRow'}>
            <div style={{'width': '60px'}} className={'headerItem'}>ID</div>
            <div style={{'width': '120px'}} className={'headerItem'}>Version</div>
            <div style={{'width': '520px'}} className={'headerItem'}>Location</div>
            <div className={'headerEnd'}></div>
        </div>



        for (let firmware of iList) {
            var date = new Date(firmware.timestamp);
            let thisFirmware = <div key={firmware.firmware_id} className={'listRow'}>

                <div style={{'width': '60px', 'minHeight': '2PX'}} className={'listItem'}>{firmware.firmware_id}</div>
                <div style={{'width': '120px'}} className={'listItem'}>{firmware.version}</div>
                <div style={{'width': '520px'}} className={'listItem'}>{firmware.location}</div>


                <ImpCollapsible collapsed={true} expandText="Details" header={''}>

                    <div style={{'width': '700px', 'marginLeft' : 50, 'textAlign' : 'left', 'margin': '50', 'padding': 30, "border": '0px solid #DDD'}}>
                        <JSONPretty id="json-pretty" data={firmware} theme={JSONPrettyMon}></JSONPretty>
                        Timestamp Local:{date.toLocaleString([],{ timeZoneName: 'long' })}
                    </div>

                </ImpCollapsible>


                <div className={'listEnd'}></div>

            </div>

            firmwaresToShow.push(thisFirmware);
        }

        firmwaresToShow.push(header)
        firmwaresToShow.reverse();

        return (
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {firmwaresToShow}
                </div>
            </div>
        );
    }
}
export default withAlert()(FirmwareList);
