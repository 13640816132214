import React, {Component} from 'react';
import {sendHeartbeat} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible"

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class Heartbeat extends Component {

    constructor(props) {
        super(props);
        console.log("In Heartbeat constructor");
        this.state = {
            "error": "",
            "warning": "",
            "update_list": null
        };
    }

    sendHeartbeat() {

        this.setState({"update_list": null});
        this.setState({"error": null});
        this.setState({"warning": null});
        this.setState({"success": null});


        let ul = {};

        ul.impbox_uuid = this.state.impbox_uuid;
        ul.lastUpdate_id = this.state.lastUpdateID;

        sendHeartbeat(ul, (returnDetails) => {

                console.log("API return from sendHeartbeat " + JSON.stringify(returnDetails));

                this.setState({"update_list": returnDetails.updateMessages});
                if (returnDetails.success === true) {
                    this.setState({"success": "Successfull Call"});
                } else {
                    this.setState({"success": "UnSuccessfull Call"});
                }

                if (returnDetails.warn) {
                    this.setState({"warning": returnDetails.warn});
                }

            },
            (error) => {

                console.log("ERROR in sendHeartbeat =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }


    changeText(e, fld_id) {

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        this.setState(no);
    }


    render() {

        let update_data = <div></div>;
        if (this.state.update_list) {
            update_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.update_list} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Heartbeat'}>


                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            <div><p style={{color: 'orange'}}>{this.state.warning}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.success}</p></div>

                            <div style={{float:'left', width:'70%'}}>
                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>IMPBox uuid:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.impbox_uuid}
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox_uuid')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Last Update ID:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.lastUpdateID}
                                               onChange={(event, fld_id) => this.changeText(event, 'lastUpdateID')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <button onClick={() => this.sendHeartbeat()}>Send Heartbeat</button>


                                {update_data}

                            </div>
                            <div style={{float:'left'}}>



                            </div>
                            <div className={'fieldEnd'}></div>
                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {Heartbeat};

