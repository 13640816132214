import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible"
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class SettingsDetails extends Component {

    constructor(props) {
        super(props);
        console.log("In SettingsDetails constructor");
        this.state = {
            "error": "",
            "contact_list": null
        };
    }


    render() {
        /*********
         * selected box settings
         *************/

        let s;
        let ibSettings = [];
        let bgc = '#f2aaff';
        if (!!this.props.settings) {
            if(!this.props.settings.settings)
            {
                return (<div></div>);
            }

            s = this.props.settings.settings
            if (s.urgent_calls.details.enabled) {
                ibSettings.push(<div key={"uc_enb"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}><b>Urgent Calls</b> is turned <b>On </b> if someone calls {s.urgent_calls.details.call_count} times
                    in {s.urgent_calls.details.urgent_window_duration} minutes. </div>)
            } else {
                ibSettings.push(<div key={"uc_enb"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}>Urgent Calls is turned <b>Off </b></div>)
            }

            if (s.answering_machine.details.take_message_from_greenlist) {
                ibSettings.push(<div key={"mfg_enb"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}><b>Answering Machine</b> is turned <b>On </b> and will answer if phone
                    rings {s.answering_machine.details.ring_count} times. </div>)
            } else {
                ibSettings.push(<div key={"mfg_enb"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}><b>Answering Machine</b> is turned <b>Off </b></div>)
            }


            if (s.greenlist_dialed_numbers.details.enabled) {
                ibSettings.push(<div key={"gdn_enb"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}><b>Greenlist Dialed Numbers</b> is turned <b>On </b></div>)
            } else {
                ibSettings.push(<div key={"gdn_enb"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}><b>Greenlist Dialed Numbers</b> is turned <b>Off </b></div>)
            }


            ibSettings.push(<div key={"ogm_enb"} style={{
                backgroundColor: bgc,
                margin: '3px 3px 3px 20px',
                padding: '3px',
                borderRadius: '5px'
            }}><b>Outoing message is: </b> can be heard here
                <audio controls>

                    <source src={s.outgoing_message.details.message_link} type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio></div>)


            if (s.wifi) {
                s.wifi.details.password = '***********';
                ibSettings.push(<div key={"wifi"} style={{
                    backgroundColor: bgc,
                    margin: '3px 3px 3px 20px',
                    padding: '3px',
                    borderRadius: '5px'
                }}>IMPBox is connected to the<b> {s.wifi.details.ssid} </b> wifi network </div>)
            }

        }else{
            return (<div></div>)
        }


        return (
            <div>

                <div>
                {ibSettings}
                </div>
                <div style={{'clear':"both"}}></div>
                <div style={{'width': '600px',  'textAlign' : 'center', 'padding': 10, "border": '0px solid #DDD'}}>
                    <ImpCollapsible collapsed={true} expandText="details" header={'Raw Doc'}>

                        <div style={{'width': '550px', 'marginLeft' : 30, 'textAlign' : 'left',  'padding': 10, "border": '0px solid #DDD'}}>
                            <JSONPretty id="json-pretty" data={s} theme={JSONPrettyMon}></JSONPretty>
                        </div>

                    </ImpCollapsible>
                </div>

            </div>
        );
    }
}
export {SettingsDetails};

