import React, {Component} from 'react';

class MobileAdmin extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing MobileAdmin")
        this.state= {"error" : ""};
        this.state= {"response" : ""};
    }

    render() {
        return (
            <div >
               Mobile Admin TBD
            </div>
        );
    }
}
export default MobileAdmin;
