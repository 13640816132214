import React, {Component } from 'react';


import {getAllErrorLogs} from "../../util/apiServiceUtil";
import ErrorLogList from "./ErrorLogList";

import _ from "lodash";
import ErrorCodeList from "./ErrorCodeList";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

class ErrorLogAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            ErrorLogs : [],
            filter:{'timefilter':4, 'typefilter':'', 'levelfilter':[1,2,3],'testfilter':'NO_TEST'},
            'autoUpdate':false,
            'isRunning':false
        }
    }

    componentDidMount() {
        console.log("ErrorLogs Admin did mount");
        this.setTypeFilter('fg_type_all','');
        this.setTimeFilter('fg_time_4',4);

        this.refreshErrorLogs(this.state.filter);
    }

    setTimeFilter(tf,val)
    {
       // alert(tf);
        document.getElementById('fg_time_4').style.background = '#ffffff';
        document.getElementById('fg_time_24').style.background = '#ffffff';
        document.getElementById('fg_time_48').style.background = '#ffffff';
        document.getElementById('fg_time_W').style.background = '#ffffff';
        document.getElementById('fg_time_M').style.background = '#ffffff';
        document.getElementById('fg_time_Y').style.background = '#ffffff';

        document.getElementById(tf).style.background = '#b3b3b3';
        let oldFilter = this.state.filter;
        oldFilter.timefilter = val;

        this.setState({filter:oldFilter});
        this.refreshErrorLogs();

    }

    setLevelFilter(tf,val)
    {

        let errorCodeColors = ['','#ff0515','#ff6e11','#b8ff12','#10ffe2','#61a8ff'];

        let oldFilter = this.state.filter;
        let oldLevelFilter = oldFilter.levelfilter;

        if(oldLevelFilter.indexOf(val) === -1)
        {
            oldLevelFilter.push(val);
            document.getElementById(tf).style.background = '#b3b3b3';
        } else
        {


            var index = oldLevelFilter.indexOf(val);
            if (index !== -1) {
                oldLevelFilter.splice(index, 1);
            }
            document.getElementById(tf).style.background = '#ffffff';
        }

        this.setState({filter:oldFilter});
        this.refreshErrorLogs();

    }
    setTypeFilter(tf,val)
    {
       // alert(tf);
        document.getElementById('fg_type_app').style.background = '#cdd229';
        document.getElementById('fg_type_impbox').style.background = '#90d112';
        document.getElementById('fg_type_all').style.background = '#ffffff';
        document.getElementById('fg_type_network').style.background = '#d2859f';

        document.getElementById(tf).style.background = '#b3b3b3';

        let oldFilter = this.state.filter;
        oldFilter.typefilter = val;

        this.setState({filter:oldFilter});
        this.refreshErrorLogs();

    }
 setTestFilter(tf,val)
    {
       // alert(tf);
        document.getElementById('fg_test_off').style.background = '#ffffff';
        document.getElementById('fg_test_on').style.background = '#ffffff';
        document.getElementById('fg_test_both').style.background = '#ffffff';


        document.getElementById(tf).style.background = '#b3b3b3';

        let oldFilter = this.state.filter;
        oldFilter.testfilter = val;

        this.setState({filter:oldFilter});
        this.refreshErrorLogs();

    }

    clearOtherLocalFilters(fts)
    {
        let lcf = this.state.localCodeFilter;
        let lif = this.state.localImpboxFilter;
        let limf = this.state.localImpboxMobileFilter;

        if(fts==="code")
        {
            this.applyLocalImpboxFilter(lif);
            this.applyLocalImpboxMobileFilter(limf);
        }else if(fts==="impbox")
        {
            this.applyLocalCodeFilter(lcf);
            this.applyLocalImpboxMobileFilter(limf);
        }else if(fts==="impboxMobile")
        {
            this.applyLocalCodeFilter(lcf);
            this.applyLocalImpboxFilter(lif);
        }



    }

    applyLocalCodeFilter(daCode)
    {
        //console.log(daCode);
        let els = this.state.ErrorLogs;
        let lcf = this.state.localCodeFilter;

        let filteredList;

        if(lcf===daCode)
        {
            filteredList = els;
            daCode=""
        }else{
            this.clearOtherLocalFilters("code");
            filteredList = els.filter(function (el) {
                return el.code === daCode});

        }
        this.setState({FilteredErrorLogs:filteredList, localCodeFilter:daCode});
    }

    applyLocalImpboxFilter(daImpbox)
    {
        //console.log(daCode);
        let els = this.state.ErrorLogs;
        let lcf = this.state.localImpboxFilter;

        let filteredList;

        if(lcf===daImpbox)
        {
            filteredList = els;
            daImpbox=""
        }else{
            this.clearOtherLocalFilters("impbox");
            filteredList = els.filter(function (el) {
                return el.impbox_id+"" === daImpbox});

        }
        this.setState({FilteredErrorLogs:filteredList, localImpboxFilter:daImpbox});
    }


    applyLocalImpboxMobileFilter(daImpbox)
    {
        //console.log(daCode);
        let els = this.state.ErrorLogs;
        let lcf = this.state.localImpboxMobileFilter;

        let filteredList;

        if(lcf===daImpbox)
        {
            filteredList = els;
            daImpbox=""
        }else{
            this.clearOtherLocalFilters("impboxMobile");
            filteredList = els.filter(function (el) {
                return el.box_mobile_id+"" === daImpbox});

        }
        this.setState({FilteredErrorLogs:filteredList, localImpboxMobileFilter:daImpbox});
    }

    toggleAutorun()
    {
        if(this.state.isRunning)
        {
            this.setState({isRunning:false})
        }else
        {
            this.setState({isRunning:true})
        }

    }

    refreshErrorLogs() {

        console.log("Calling refreshErrorLogs from ErrorLogAdmin");

        let filter=this.state.filter


       getAllErrorLogs(
           filter,
            (ErrorLogs) => {
                //console.log("ErrorLogs - " + JSON.stringify(ErrorLogs));
                this.setState({ErrorLogs: ErrorLogs.error_logs});
                this.setState({FilteredErrorLogs: ErrorLogs.error_logs});
            },
            (error) => {

                console.log("ERROR in getAllErrorLogs =  " +  JSON.stringify(error));
                this.setState({"error": JSON.stringify(error)});
            })

    }

    render() {


        let errorCodeColors = ['','#ff0515','#ff6e11','#28ff2e','#10ffe2','#61a8ff'];

        const layoutStyle = {

            margin: 20,

            border: '1px solid #DDD'
        };

        const queryGroup = {
            height:'44px',
            margin: '5px',
            padding: '5px',
            border: '1px solid #DDD',
            float:'left'
        };

        const buttonStyle = {
            cursor:'pointer',
            float:'left',
            margin: 2,
            padding: 2,
            borderRadius:'15px',
            background: '#0ec0ff',
            border: '1px solid #DDD'
        };

        const queryShowStyle = {

            float:'left',
            margin: 2,
            padding: 2,
            border: '1px inset',
            width:'550px'
        };

        const filterOptionStyle = {
            cursor:'pointer',
            float:'left',
            marginLeft:'2px',
            border: '1px solid #DDD',
            borderRadius:'15px',
            padding: '5px'
        };

        const filterlabelStyle = {

            float:'left',
            border: '0px solid #DDD',
            borderRadius:'15px',
            padding: '5px'
        };
        const filterOptionStyleOn = {
            cursor:'pointer',
            float:'left',
            border: '1px solid #DDD',
            borderRadius:'15px',
            padding: '5px',
            background:'#b3b3b3'
        };



        const renderTime = ({ remainingTime }) => {
            if (remainingTime === 0) {
                return <div className="timer">0</div>;
            }

            return (
                <div className="timer">

                    <div className="value">{remainingTime}</div>


                </div>
            );
        };

        let eLogs = this.state.ErrorLogs;
        let eLogCount = eLogs.length;

        let distinctCodes = [... new Set(eLogs.map(x=>x.code+'~'+x.level))];
        let distinctImpboxes = [... new Set(eLogs.map(x=>x.impbox_id+"~"))];
        let distinctImpboxMobiles = [... new Set(eLogs.map(x=>x.box_mobile_id+"~"))];

        let cb = _.countBy(eLogs, 'code');
        let ibCount = _.countBy(eLogs, 'impbox_id');
        let ibmCount = _.countBy(eLogs, 'box_mobile_id');

        const timerProps = {
            isPlaying: this.state.isRunning,
            size: 35,
            strokeWidth: 3
        };

        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>

                    <div style={layoutStyle}>
                        <div style={{float:'left', width:'180px',marginTop:'10px'}}> <b>Query Builder</b> refreshing in </div>
<div style={{float:'left'}} >
                        <CountdownCircleTimer
                            {...timerProps}
                            onComplete={() => {
                                // do your stuff here
                                this.refreshErrorLogs(this.state.filter);
                                if(this.state.autoUpdate===true)
                                {
                                    return [true, 1000]
                                }else
                                {
                                    return [false, 1000]
                                }

                            }}

                            duration={60}
                            colors="#A30000"
                        >{renderTime}
                        </CountdownCircleTimer>
</div><div style={{float:'left', marginLeft:'5px',marginTop:'10px'}} >seconds</div>

                        <button
                        className="buttons"
                        onClick={() => {
                            this.toggleAutorun();

                        }}
                    >
                        Toggle Auto Refresh
                    </button>
                        <div style={{clear:'both'}}></div>

                        <div style={queryGroup}>

                            <div id='time_label' style={filterlabelStyle} ><b>Time:</b> logs from the last </div>
                            <div id='fg_time_4' onClick={()=>this.setTimeFilter('fg_time_4',4)} style={filterOptionStyle} > 4 </div>
                            <div id='fg_time_24' onClick={()=>this.setTimeFilter('fg_time_24',24)} style={filterOptionStyle}>  24 </div>
                            <div id='fg_time_48'  onClick={()=>this.setTimeFilter('fg_time_48',48)} style={filterOptionStyle}>  48 </div>
                            <div id='fg_time_W'  onClick={()=>this.setTimeFilter('fg_time_W',168)} style={filterOptionStyle}> 168 (week)</div>
                            <div id='fg_time_M'  onClick={()=>this.setTimeFilter('fg_time_M',730)} style={filterOptionStyle}> 730 (month)</div>
                            <div id='fg_time_Y'  onClick={()=>this.setTimeFilter('fg_time_Y',8760)} style={filterOptionStyle}> 8760 (year)</div>
                            <div id='time_label' style={filterlabelStyle} > hours</div>
                        </div>

                        <div style={queryGroup}>
                            <div id='time_label' style={filterlabelStyle} ><b>Type:</b> </div>
                            <div id='fg_type_app' onClick={()=>this.setTypeFilter('fg_type_app','app')} style={filterOptionStyle} > App</div>
                            <div id='fg_type_network' onClick={()=>this.setTypeFilter('fg_type_network','network')} style={filterOptionStyle}> Network</div>
                            <div id='fg_type_impbox' onClick={()=>this.setTypeFilter('fg_type_impbox','impbox')} style={filterOptionStyle}> imp Box</div>
                            <div id='fg_type_all'  onClick={()=>this.setTypeFilter('fg_type_all','')} style={filterOptionStyle}> All</div>


                        </div>
                        <div style={{clear:'both'}}></div>
                        <div style={queryGroup}>
                            <div id='level_label' style={filterlabelStyle} ><b>Log Level:</b> </div>
                            <div id='fg_level_1' onClick={()=>this.setLevelFilter('fg_level_1',1)} style={filterOptionStyleOn} > <div style={{width:'19px',borderRadius:'15px',background:errorCodeColors[1]}} >1</div></div>
                            <div id='fg_level_2' onClick={()=>this.setLevelFilter('fg_level_2',2)} style={filterOptionStyleOn}> <div style={{width:'19px',borderRadius:'15px',background:errorCodeColors[2]}} >2</div></div>
                            <div id='fg_level_3' onClick={()=>this.setLevelFilter('fg_level_3',3)} style={filterOptionStyleOn}> <div style={{width:'19px',borderRadius:'15px',background:errorCodeColors[3]}} >3</div></div>
                            <div id='fg_level_4'  onClick={()=>this.setLevelFilter('fg_level_4',4)} style={filterOptionStyle}> <div style={{width:'19px',borderRadius:'15px',background:errorCodeColors[4]}} >4</div></div>
                            <div id='fg_level_5'  onClick={()=>this.setLevelFilter('fg_level_5',5)} style={filterOptionStyle}> <div style={{width:'19px',borderRadius:'15px',background:errorCodeColors[5]}} >5</div></div>


                        </div>

                        <div style={queryGroup}>
                            <div id='test_label' style={filterlabelStyle} ><b>Test Data:</b> </div>
                            <div id='fg_test_off' onClick={()=>this.setTestFilter('fg_test_off','NO_TEST')} style={filterOptionStyleOn} > No Test Logs</div>
                            <div id='fg_test_on' onClick={()=>this.setTestFilter('fg_test_on','TESTS_ONLY')} style={filterOptionStyle}> Only Tests Logs</div>
                            <div id='fg_test_both' onClick={()=>this.setTestFilter('fg_test_both',"BOTH")} style={filterOptionStyle}> All Logs</div>



                        </div>
                        <div style={{clear:'both'}}></div>
                        <div>

                            <div style={{float:'left'}}  style={queryShowStyle}>{JSON.stringify(this.state.filter)} </div>
                            <div style={{float:'left'}} onClick={()=>this.refreshErrorLogs()} style={buttonStyle}> Refresh </div>


                        </div>
                        <div style={{clear:'both'}}></div>

                        <div>
                            <div><b>Log Count:</b> {eLogCount}</div>

                            <div><b>Error Codes:</b></div>
                            <ErrorCodeList selectedCode={this.state.localCodeFilter}  errorCodes={distinctCodes} errorCodeCounts={cb} errorCodeColors={errorCodeColors}  localCodeFilter={(daCode)=>this.applyLocalCodeFilter(daCode)} />
                            <div><b>impbox_id:</b></div>
                            <ErrorCodeList selectedCode={this.state.localImpboxFilter} errorCodes={distinctImpboxes} errorCodeCounts={ibCount} errorCodeColors={errorCodeColors}  localCodeFilter={(daCode)=>this.applyLocalImpboxFilter(daCode)} />
                              <div><b>box_mobile_id:</b></div>
                            <ErrorCodeList selectedCode={this.state.localImpboxMobileFilter} errorCodes={distinctImpboxMobiles} errorCodeCounts={ibmCount} errorCodeColors={errorCodeColors}  localCodeFilter={(daCode)=>this.applyLocalImpboxMobileFilter(daCode)} />



                        </div>

                        <ErrorLogList  errorCodeColors={errorCodeColors} errorLogs={this.state.FilteredErrorLogs}/>

                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorLogAdmin;
