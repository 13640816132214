import React, {Component} from 'react';
import {createOrUpdateContextHelp} from "../../util/apiServiceUtil";

import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
Quill.register('modules/imageResize', ImageResize)


class ContextHelpCreate extends Component {

    constructor(props) {
        super(props);
        console.log("In ContextHelp Create constructor");
        this.handleChange = this.handleChange.bind(this)

        this.state = {
            "contexthelp": {
                "name": "",
                "html": "",
                "redurectURL": ""
            },
            "error": "",
            "details": {}
        };
    }

    handleChange (html) {
        let ch = this.state.contexthelp;
        ch.html = html;
        this.setState({contexthelp: ch });
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        const target = e.target;
        const val = target.name === 'mkting_agree' ? target.checked : target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        this.setState(change);
        console.log("changeText: after updating state :  " + JSON.stringify( this.state["contexthelp"]));

    }

    createNewContextHelp() {
        console.log("Starting the ContextHelp Creation Process");

        let contexthelp = {
            "name": this.state.contexthelp.name,
            "redirectURL": this.state.contexthelp.redirectURL,
            "html": this.state.contexthelp.html,
        }

        createOrUpdateContextHelp(contexthelp, (returnDetails) => {

                console.log("API return from createOrUpdateContextHelp " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    contexthelp: returnDetails.contexthelp
                };
                this.setState({"details": details});
                let success_message = "Created ContextHelp: " + returnDetails.contexthelp.contexthelp_id;

                this.props.alert.success(success_message);
                this.props.refreshContextHelps();

            },
            (error) => {

                console.log("ERROR in createNewUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }



    render() {


        let success_message;
        if (this.state.details.impbox_id) {
            success_message =
                <div>
                    <p style={{color: 'green'}}>New IMPBox Created: impbox_id = {this.state.details.impbox_id}</p>
                </div>
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Create New Context Help '}>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.contexthelp.name}
                                               onChange={(event, fld_id) => this.changeText(event, 'contexthelp.name')}
                                               size={'40'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>redirectURL:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.contexthelp.redirectURL}
                                           onChange={(event, fld_id) => this.changeText(event, 'contexthelp.redirectURL')}
                                        size={'80'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>


                        <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>html generated:</div>
                                    <div className={'fieldInput'}>
                                        <textarea value={this.state.contexthelp.html}
                                                  rows="10" cols="80"/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <ReactQuill theme="snow" value={this.state.contexthelp.html}
                                            modules={ContextHelpCreate.modules}
                                            formats={ContextHelpCreate.formats}
                                            onChange={this.handleChange}
                                />



                        <button onClick={() => this.createNewContextHelp()}>Create ContextHelp</button>
                    </div>

                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(ContextHelpCreate);

/*
* Quill modules to attach to editor
* See https://quilljs.com/docs/modules/ for complete options
*/
ContextHelpCreate.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image' ],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
    imageResize: {
        modules: [ 'Resize', 'DisplaySize' ]
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ContextHelpCreate.formats = [
    'header', 'font', 'size','color','background', 'align',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'image',
    'link'
]
