import React, {Component} from 'react';

import { LogCall } from "./APIs/LogCall";
import { NewMessage } from "./APIs/NewMessage";
import { GetContactList } from "./APIs/GetContactList";
import { GetShortNumberList } from "./APIs/GetShortPhoneNumberList";
import { Heartbeat } from "./APIs/Heartbeat";
import { GetSettings } from "./APIs/GetSettings";
import { Setup } from "./APIs/Setup";
import { GetFirmware } from "./APIs/GetFirmware";
import { SettingApplied } from "./APIs/SettingApplied";
import { SettingProcessed } from "./APIs/SettingProcessed";
import { AuthenticateAsIMPBox } from "./APIs/AuthenticateAsIMPBox";

class IMPSim extends Component {

    constructor(props) {
        super(props);
        console.log("In IMPSim constructor");
        this.state = {
        };
    }

    render() {
        return (
            <div>
                {/*<AuthenticateAsIMPBox/>*/}
                <NewMessage/>
                <LogCall/>
                {/*<GetContactList/>*/}
                <GetShortNumberList/>
                <Heartbeat/>
                <GetSettings/>
                <Setup/>
                <GetFirmware/>
                <SettingApplied/>
                <SettingProcessed/>
            </div>
        );
    }
}
export default IMPSim;
