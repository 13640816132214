import React, {Component} from 'react';
import AdminList from './AdminList';
import AdminCreate from './AdminCreate';
import {getAdminAccounts} from "../../util/apiServiceUtil";


class AdminManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            "error": null,
            adminAccounts : []
        }
    }

    componentDidMount() {
        console.log("AdminManagement did mount");
        this.refreshAdmins();
    }

    refreshAdmins()  {

        console.log("Calling getAdminAccounts from AdminManagement")

        getAdminAccounts(
            (admin_accounts) => {
                console.log("Admins - " + JSON.stringify(admin_accounts));
                this.setState({adminAccounts: admin_accounts.accounts});
            },
            (error) => {

                console.log("ERROR in getAllUsers =  " + error.message);
                this.setState({"error": error});
            })

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>
                    <div style={layoutStyle}>
                    <AdminCreate refreshAdmins={() => this.refreshAdmins()}/>
                    </div>
                        <div style={layoutStyle}>
                    <AdminList
                        adminAccounts={this.state.adminAccounts}
                        refreshAdmins={() => this.refreshAdmins()}
                    />
                        </div>
                </div>
            </div>
        );
    }
}

export default AdminManagement;
