import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible"
import {ShowActivity} from "./ShowActivity";
import {HistoryDetails} from "./HistoryDetails";


class IMPBoxTimeline extends Component {

    constructor(props) {
        super(props);
        console.log("In IMPBoxTimeline constructor");
        this.state = {
            error: null,
            success: null,
            showCallLog:true,
            showActLog:true,
            tToShow: "No Timeline associated with IMPBox.",
        };
    }

    changeFilter(toChange)

    {
        console.log("changeFilter");
        let newState = {};
        newState[toChange] = !this.state[toChange];
        this.setState(newState);


    }




    componentDidUpdate(prevProps) {

        console.log(`IMPBoxTimeline: in componentDidUpdate... Prev: ${prevProps.impboxActivity} Current: ${this.props.impboxActivity}  `)



        if (prevProps.impboxActivity !== this.props.impboxActivity || prevProps.history !== this.props.history ) {
            this.setState({
                impbox_id: this.props.impbox_id
            });

        }
    }



    render() {

        /**************************
         *
         *
         *
         */

        let uniView = [];


        let tToShow = [];
        // Process Activity Log
        let daActs = this.props.impboxActivity;

        if(this.state.showActLog) {
            if (daActs) {
                for (let act of daActs.history) {
                    let thisAct = <ShowActivity key={"act_" + act.activitylog_id} activity={act}/>;
                    let uniObj = {};
                    uniObj.view = thisAct;
                    uniObj.time = act.createdAt;
                    uniView.push(uniObj)
                }
            }

        }
        // Process History
        let daHists = this.props.history;

        if (daHists && daHists.impbox) {
            daHists = daHists.impbox.Histories
        } else {
            daHists = [];
        }

        if(this.state.showCallLog) {
            for (let h of daHists) {
                let thisH = <HistoryDetails key={h.history_id} history={h}/>
                let uniObj = {};
                uniObj.view = thisH;
                uniObj.time = h.created;
                uniView.push(uniObj);
            }
        }
        uniView.sort(function (a, b) {
            let c = new Date(a.time);
            let d = new Date(b.time);
            return d - c;
        });

        // Create Unified View of daActs and daHists
        for (let t of uniView) {
            tToShow.push(t.view);
        }

        if (tToShow.length > 0) {

                tToShow= tToShow

        } else {

                tToShow= "No Timeline associated with IMPBox."

        }


        /*******************
         *
         */

        console.log(`Rendering IMPBoxTimeline with ${this.state.currentIMPBox} Props: ${this.props.impbox_id} `)
        let api_result;
        if (this.state.success == true) {
            api_result = "API Call Completed";
        }
        let api_error;
        if (!!this.state.error && !!this.state.error.details) {
            api_error = this.state.error.details;
            console.log(`api_error = ${JSON.stringify(api_error)}`);
        } else {
            api_error = "";
        }

        return (
            <ImpCollapsible collapsed={true} header={'Timeline'}>
                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                <div><p style={{color: 'green'}}>{this.state.success}</p></div>


                <div>
                    <input type="checkbox" name={'calllog'} checked={this.state.showCallLog} onChange={()=>this.changeFilter('showCallLog')}/> Call Log &nbsp;&nbsp;&nbsp;
                    <input type="checkbox" name={'activitylog'}  checked={this.state.showActLog}  onChange={()=>this.changeFilter('showActLog')} /> Activity Log

                </div>

                <div style={{'maxHeight': '800px', overflow: "auto"}}>
                    {tToShow}
                </div>
            </ImpCollapsible>
        );
    }

}
export {IMPBoxTimeline};
