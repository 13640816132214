import {
    notificationRead_service_api,
    getAllUsers_service_api,
    getAllSubscriptions_service_api,
    updateSubscriptionDetails_service_api,
    removeSubscription_service_api,
    deleteUser_service_api,
    archiveUser_service_api,
    place_call_service_api,
    getAllContextHelps_service_api,
    deleteContextHelp_service_api,
    createOrUpdateContextHelp_service_api,
    registerUser_service_api,
    registerIMPBox_service_api,
    getContactsForMobile_service_api,
    addNotification_service_api,
    push_apn_service_api,
    push_fcm_service_api,
    create_subscription_data_with_user_api,
    getNotificationsForMobile_service_api,
    getUser_service_api,
    getIMPBoxesForMobile_service_api,
    logCall_service_api,
    setup_service_api,
    newMessage_service_api,
    uploadOutgoingMessage_service_api,
    uploadFirmware_service_api,
    updateSetting_service_api,
    settingProcessed_service_api,
    settingApplied_service_api,
    getContactList_service_api,
    deleteContacts_service_api,
    createContactSet_service_api,
    createContact_service_api,
    getPagedPhoneNumberList_service_api,
    getShortPhoneNumberList_service_api,
    getSettings_service_api,
    getFirmware_service_api,
    getCallStats_service_api,
    getAllIMPBoxes_service_api,
    sendHeartbeat_service_api,
    sendAdminEmail_service_api,
    getAllInvites_service_api,
    getAllFirmwares_service_api,
    updateFirmwareAdmin_service_api,
    createFirmwareAdmin_service_api,
    getInvite_service_api,
    getInvitesByEmail_service_api,
    inviteUser_service_api,
    validateEmail_service_api,
    getStripeSession_service_api,
    getAPNLogsForDevice_service_api,
    getAPNStats_service_api,
    login_service_api,
    getAppAccessToken_service_api,
    getIMPBoxRefreshToken_service_api,
    impBoxAuthenticate_service_api,
    getIMPBoxActivity_service_api,
    getIMPBoxUsers_service_api,
    getIMPBoxCallHistory_service_api,
    getAllErrorLogs_service_api,
    createContactAndRemoveNotification_service_api,
    get_admin_accounts_service_api,
    create_admin_accounts_service_api,
    delete_admin_accounts_service_api,
    factory_reset_service_api,
    getAppVersions_service_api,
    updateAppVersion_service_api,
    getProducts_service_api,
    updateProduct_service_api,
    addProduct_service_api,
    deleteProduct_service_api,
    updateSubscription_service_api,
    exportUserImpContacts_service_api,
    getImportableBatches_service_api,
    importUserImpContacts_service_api,
    setMobileAuditMode_service_api,
    setUserContactBumper_service_api,
    updateIMPBoxPhoneNumber_service_api

} from './config';

import history from "./history";

require('es6-promise').polyfill();
require('isomorphic-fetch');
const axios = require('axios');


//Admin APIs

const factoryReset = function (impbox_data, dataHandler, errorHandler) {
    callRPC(factory_reset_service_api, impbox_data, dataHandler, errorHandler)
};

const login = function (credentials, dataHandler, errorHandler) {
    callRPC(login_service_api, credentials, dataHandler, errorHandler)
};

const getAdminAccounts = function (dataHandler, errorHandler) {
    callRPC(get_admin_accounts_service_api, undefined,  dataHandler, errorHandler, "admin" )
};

const placeCall = function (callDetails,dataHandler, errorHandler) {
    callRPC(place_call_service_api, callDetails,  dataHandler, errorHandler, "admin" )
};

const createAdminAccount = function (admin_account, dataHandler, errorHandler) {
    console.log(`AdminAccount in createAdminAccount: ${JSON.stringify(admin_account)} `);
    callRPC(create_admin_accounts_service_api, admin_account, dataHandler, errorHandler, "admin")
};

const deleteAdminAccount = function (admin_account, dataHandler, errorHandler) {
    console.log(`AdminAccount in deleteAdminAccount: ${JSON.stringify(admin_account)} `);
    callRPC(delete_admin_accounts_service_api, admin_account, dataHandler, errorHandler, "admin")
};

const getAppAccessTokenApi = function (credentials, dataHandler, errorHandler) {
    callRPC(getAppAccessToken_service_api, credentials, dataHandler, errorHandler)
};

const getIMPBoxRefreshTokenApi = function (auth_data, dataHandler, errorHandler) {
    callRPC(getIMPBoxRefreshToken_service_api, auth_data, dataHandler, errorHandler)
};

const impBoxAuthenticateApi = function (auth_data, dataHandler, errorHandler) {
    callRPC(impBoxAuthenticate_service_api, auth_data, dataHandler, errorHandler)
};

const getAllInvites = function (dataHandler, errorHandler) {
    callRPC(getAllInvites_service_api, {}, dataHandler, errorHandler)
};

const updateFirmwareAdmin = function (updateData, dataHandler, errorHandler) {
    callRPC(updateFirmwareAdmin_service_api, updateData, dataHandler, errorHandler)
};

const createFirmware = function (createData, dataHandler, errorHandler) {
    callRPC(createFirmwareAdmin_service_api, createData, dataHandler, errorHandler)
};

const getAllFirmwares = function (dataHandler, errorHandler) {
    callRPC(getAllFirmwares_service_api, {}, dataHandler, errorHandler)
};

const getIMPBoxes = function (dataHandler, errorHandler) {
    callRPC(getAllIMPBoxes_service_api, {}, dataHandler, errorHandler)
};

const sendAdminEmail = function (emailData, dataHandler, errorHandler) {
    callRPC(sendAdminEmail_service_api, emailData, dataHandler, errorHandler)
};

const getCallStats = function (dataHandler, errorHandler) {
    callRPC(getCallStats_service_api, {}, dataHandler, errorHandler)
};

const getIMPBoxCallHistory = function (callData, dataHandler, errorHandler) {
    callRPC(getIMPBoxCallHistory_service_api, callData, dataHandler, errorHandler)
};

const getAPNStatsApi = function (callData, dataHandler, errorHandler) {
    callRPC(getAPNStats_service_api, callData, dataHandler, errorHandler)
};

const getAPNLogsForDeviceApi = function (callData, dataHandler, errorHandler) {
    callRPC(getAPNLogsForDevice_service_api, callData, dataHandler, errorHandler)
};

const getAllErrorLogs = function (callData, dataHandler, errorHandler) {
    callRPC(getAllErrorLogs_service_api, callData, dataHandler, errorHandler)
};

const uploadFirmwareApi = function (callData, fileToUpload, dataHandler, errorHandler) {
    callFirmwareRPCMultiForm(uploadFirmware_service_api, callData, fileToUpload, dataHandler, errorHandler)
};

const getUser = function (userId, dataHandler, errorHandler) {
    callRPC(getUser_service_api, {"user_id": userId}, dataHandler, errorHandler)
};

const deleteUser = function (userId, dataHandler, errorHandler) {
    callRPC(deleteUser_service_api, {"user_id": userId}, dataHandler, errorHandler)
};

const archiveUser = function (userId, dataHandler, errorHandler) {
    callRPC(archiveUser_service_api, {"user_id": userId}, dataHandler, errorHandler)
};

const getAllUsers = function (dataHandler, errorHandler) {
    callRPC(getAllUsers_service_api, {}, dataHandler, errorHandler)
};

const getAllSubscriptions = function (reqScope, dataHandler, errorHandler) {
    callRPC(getAllSubscriptions_service_api, reqScope, dataHandler, errorHandler)
};
const updateSubscriptionDetails = function (updatedSub, dataHandler, errorHandler) {
    callRPC(updateSubscriptionDetails_service_api, updatedSub, dataHandler, errorHandler)
};

const removeSubscription = function (updatedSub, dataHandler, errorHandler) {
    callRPC(removeSubscription_service_api, updatedSub, dataHandler, errorHandler)
};

const getAllContextHelps = function (dataHandler, errorHandler) {
    callRPC(getAllContextHelps_service_api, {}, dataHandler, errorHandler)
};

const deleteContextHelp = function (contexthelp_id, dataHandler, errorHandler) {
    callRPC(deleteContextHelp_service_api, {"contexthelp_id": contexthelp_id}, dataHandler, errorHandler)
};

const createOrUpdateContextHelp = function (contexthelp, dataHandler, errorHandler) {
    callRPC(createOrUpdateContextHelp_service_api, {"contexthelp": contexthelp}, dataHandler, errorHandler)
};

const addNotification = function (notification, dataHandler, errorHandler) {
    callRPC(addNotification_service_api, {"notification": notification}, dataHandler, errorHandler)
};

const getContactsForMobile = function (mobileId, dataHandler, errorHandler) {
    console.log("called getIMPBoxesForMobile mobileId=" + mobileId)
    callRPC(getContactsForMobile_service_api, {"mobile_id": mobileId}, dataHandler, errorHandler)
};

const validateEmail = function (email_validation_code, dataHandler, errorHandler) {
    console.log("called validateEmail email_validation_code = " + email_validation_code)
    callRPC(validateEmail_service_api, {email_validation_code: email_validation_code}, dataHandler, errorHandler)
};

const getStripeSession = function (stripe_session_id, dataHandler, errorHandler) {
    console.log("called getStripeSession stripe_session_id = " + stripe_session_id)
    callRPC(getStripeSession_service_api, {stripe_session_id: stripe_session_id}, dataHandler, errorHandler)
};

const deleteContactsApi = function (mobileId, dataHandler, errorHandler) {
    callRPC(deleteContacts_service_api, {"mobile_id": mobileId}, dataHandler, errorHandler, "admin")
};

const createContactSetApi = function (contact_details, dataHandler, errorHandler) {
    callRPC(createContactSet_service_api, contact_details, dataHandler, errorHandler, "admin")
};

const createContactApi = function (contact_details, dataHandler, errorHandler) {
    callRPC(createContact_service_api, contact_details, dataHandler, errorHandler, "admin")
};

const updateIMPBoxPhoneNumberApi = function (impbox_details, dataHandler, errorHandler) {
    callRPC(updateIMPBoxPhoneNumber_service_api, impbox_details, dataHandler, errorHandler, "admin")
};



// Transfers
const getIMPBoxUsers = function (callData, dataHandler, errorHandler) {
    callRPC(getIMPBoxUsers_service_api, callData, dataHandler, errorHandler, "admin")
};

const getIMPBoxActivity = function (callData, dataHandler, errorHandler) {
    callRPC(getIMPBoxActivity_service_api, callData, dataHandler, errorHandler, "admin")
};


// IMPBox APIs

const logCallApi = function (callData, dataHandler, errorHandler) {
    callRPC(logCall_service_api, callData, dataHandler, errorHandler, "impbox")
};

const setupApi = function (callData, dataHandler, errorHandler) {
    callRPC(setup_service_api, callData, dataHandler, errorHandler, "impbox")
};

const newMessageApi = function (callData, fileToUpload, dataHandler, errorHandler) {
    callRPCMultiForm(newMessage_service_api, callData, fileToUpload, dataHandler, errorHandler, "impbox")
};

const getContactListApi = function (callData, dataHandler, errorHandler) {
    callRPC(getContactList_service_api, callData, dataHandler, errorHandler, "impbox")
};



const getPagedPhoneNumberListApi = function (callData, dataHandler, errorHandler) {
    callRPC(getPagedPhoneNumberList_service_api, callData, dataHandler, errorHandler, "impbox")
};

const getShortPhoneNumberListApi = function (callData, dataHandler, errorHandler) {
    callRPC(getShortPhoneNumberList_service_api, callData, dataHandler, errorHandler, "impbox")
};

const sendHeartbeat = function (callData, dataHandler, errorHandler) {
    callRPC(sendHeartbeat_service_api, callData, dataHandler, errorHandler, "impbox")
};

const getFirmwareApi = function (callData, dataHandler, errorHandler) {
    callRPC(getFirmware_service_api, callData, dataHandler, errorHandler, "impbox")
};

const settingProcessedApi = function (settingData, dataHandler, errorHandler) {
    callRPC(settingProcessed_service_api, settingData, dataHandler, errorHandler, "impbox")
};

const settingAppliedApi = function (settingData, dataHandler, errorHandler) {
    callRPC(settingApplied_service_api, settingData, dataHandler, errorHandler, "impbox")
};

const getSettingsApi = function (callData, dataHandler, errorHandler) {
    callRPC(getSettings_service_api, callData, dataHandler, errorHandler, "impbox")
};

//APP APIs
const updateSettingApi = function (settingData, dataHandler, errorHandler) {
    callRPC(updateSetting_service_api, settingData, dataHandler, errorHandler, "app")
};

const notificationRead = function (notification_id, dataHandler, errorHandler) {
    callRPC(notificationRead_service_api, {"notification_id": notification_id}, dataHandler, errorHandler, "app")
};


const uploadOutgoingMessageApi = function (callData, fileToUpload, dataHandler, errorHandler) {
    callRPCMultiForm(uploadOutgoingMessage_service_api, callData, fileToUpload, dataHandler, errorHandler, "app")
};

const registerUserWithDevice = function (userAndDevice, dataHandler, errorHandler) {
    callRPC(registerUser_service_api, userAndDevice, dataHandler, errorHandler, "app")
};

const registerIMPBox = function (impbox, dataHandler, errorHandler) {
    callRPC(registerIMPBox_service_api, impbox, dataHandler, errorHandler, "app")
};

const getInvite = function (inviteData, dataHandler, errorHandler) {
    callRPC(getInvite_service_api, inviteData, dataHandler, errorHandler, "app")
};
const getInvitesByEmail = function (inviteData, dataHandler, errorHandler) {
    callRPC(getInvitesByEmail_service_api, inviteData, dataHandler, errorHandler, "app")
}


const inviteUser = function (inviteData, dataHandler, errorHandler) {
    callRPC(inviteUser_service_api, inviteData, dataHandler, errorHandler, "app")
};

const createContactAndRemoveNotification = function (contactNotificationData, dataHandler, errorHandler) {
    callRPC(createContactAndRemoveNotification_service_api, contactNotificationData, dataHandler, errorHandler, "app")
};

// In Transition
const getIMPBoxesForMobile = function (mobileId, dataHandler, errorHandler) {
    console.log("called getIMPBoxesForMobile mobileId=" + mobileId)
    callRPC(getIMPBoxesForMobile_service_api, {"mobile_id": mobileId}, dataHandler, errorHandler, "admin")
};

const getNotificationsForMobile = function (mobileId, dataHandler, errorHandler) {
    console.log("called getNotificationsForMobile mobileId=" + mobileId)
    callRPC(getNotificationsForMobile_service_api, mobileId, dataHandler, errorHandler, "admin")
};

const getAppVersions = function ( dataHandler, errorHandler) {
    console.log("called getAppVersions" );
    callRPC(getAppVersions_service_api, {}, dataHandler, errorHandler, "admin")
};

const updateAppVersion = function (app_version, dataHandler, errorHandler) {
    console.log("called updateAppVersion" );
    callRPC(updateAppVersion_service_api, app_version, dataHandler, errorHandler, "admin")
};

const getProducts = function ( dataHandler, errorHandler) {
    console.log("called getProducts" );
    callRPC(getProducts_service_api, {}, dataHandler, errorHandler, "admin")
};

const updateProduct = function (product, dataHandler, errorHandler) {
    console.log("called updateProduct" );
    callRPC(updateProduct_service_api, product, dataHandler, errorHandler, "admin")
};

const addProduct = function (product, dataHandler, errorHandler) {
    console.log("called addProduct" );
    callRPC(addProduct_service_api, product, dataHandler, errorHandler, "admin")
};

const deleteProduct = function (product, dataHandler, errorHandler) {
    console.log("called deleteProduct" );
    callRPC(deleteProduct_service_api, product, dataHandler, errorHandler, "admin")
};

const updateSubscriptionApi = function (subscription, dataHandler, errorHandler) {
    console.log("called updateSubscription" );
    callRPC(updateSubscription_service_api, subscription, dataHandler, errorHandler, "admin")
};

const exportUserImpContacts = function (expData, dataHandler, errorHandler) {
    console.log("called exportUserImpContacts" );
    callRPC(exportUserImpContacts_service_api, expData, dataHandler, errorHandler, "admin")
};

const getImportableBatches = function ({}, dataHandler, errorHandler) {
    console.log("called getImportableBatches" );
    callRPC(getImportableBatches_service_api, {}, dataHandler, errorHandler, "admin")
};

const importUserImpContacts = function (batchDetails, dataHandler, errorHandler) {
    console.log("called importUserImpContacts" );
    callRPC(importUserImpContacts_service_api, batchDetails, dataHandler, errorHandler, "admin")
};

const setMobileAuditMode = function (mobile_audit_mode, dataHandler, errorHandler) {
    console.log("called setMobileAuditMode" );
    callRPC(setMobileAuditMode_service_api, mobile_audit_mode, dataHandler, errorHandler, "admin")
};

const setUserContactBumper = function (mobile_contact_bumper_mode, dataHandler, errorHandler) {
    console.log("called setUserContactBumper" );
    callRPC(setUserContactBumper_service_api, mobile_contact_bumper_mode, dataHandler, errorHandler, "admin")
};


const pushAPNCustom = function (data, dataHandler, errorHandler) {

    let messageObj = {
        "aps": {
            "category": data.category,
            "alert": {
                "title": data.title,
                "body": data.body
            },
            "sound": data.sound,
            "badge": data.badge
        }
    };

    let reqData = {
        deviceToken: data.deviceToken,
        sns_apn_arn: data.sns_apn_arn,
        apn_env: data.apn_env,
        appMessage: JSON.stringify(messageObj)
    }

    callRPC(push_apn_service_api, reqData, dataHandler, errorHandler)

};

const pushAPNGeneric = function (data, dataHandler, errorHandler) {

    let reqData = {
        deviceToken: data.deviceToken,
        sns_apn_arn: data.sns_apn_arn,
        apn_env: data.apn_env,
        appMessage: data.apnStr
    }

    callRPC(push_apn_service_api, reqData, dataHandler, errorHandler)

};

const pushFCM = function (data, dataHandler, errorHandler) {

    let reqData = {
        deviceToken: data.deviceToken,
        sns_android_arn: data.sns_android_arn,
        apn_env: data.apn_env,
        appMessage: data.apnStr
    }

    callRPC(push_fcm_service_api, reqData, dataHandler, errorHandler)

};

const createSubWithUser = function (subUserData, dataHandler, errorHandler) {

    callRPC(create_subscription_data_with_user_api, subUserData, dataHandler, errorHandler)

};


/*************** The generic RPC caller ******************/

const getToken = () => {
    const tokenString = localStorage.getItem('admin-token');
    const userToken = !!tokenString ? JSON.parse(tokenString).token : tokenString;
    return userToken;
};

const getImpBoxAccessToken = () => {
    const impbox_access_token = localStorage.getItem('impbox_access_token');
    return impbox_access_token;
};

const getAppAccessToken = () => {
    let app_access_token = localStorage.getItem('app_access_token');
    return app_access_token;
};

const logoutSession = (message) => {
    console.log(`Logging Out Session with Message ${message}`)
    localStorage.removeItem('admin-token');
    localStorage.removeItem('impbox_refresh_token');
    localStorage.removeItem('impbox_access_token');
    localStorage.removeItem('impbox_id');
    localStorage.removeItem('impbox_uuid');
    localStorage.removeItem('app_access_token');
    localStorage.removeItem('app_username');
    localStorage.setItem('admin_message', message);
}
const isAdminAPI = api => {
    let result = (!api || api == "admin") ? "true" : "false";
    console.log(`Checking isAdminAPI for api ${api}. Result ${result}`);
    return result;
}
const callRPC = function (url, inputParams, dataHandler, errorHandler, api) {

    let auth = createAuth(api);


    let notifyHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': auth
        }
    };

    console.log("Calling - " + url + " with auth = " + auth + " and inputParams = " + JSON.stringify(inputParams));

    //build body object

    fetch(url,
        {
            method: 'POST',
            headers: notifyHeader.headers,
            body: JSON.stringify(inputParams)
        })
        .then(function (response) {
            // Only logout with  Auth failures on Admin APIs. For others just  return an Error.

            let message;

            if (isAdminAPI(api) && (response.status == 401 || response.status == 403)) {
                console.log("ERROR: Failure Message Returned: Status " + response.status);
                response.json().then(function (data) {
                    console.log(`ERROR: Failure Message Returned: Response: ${JSON.stringify(data)}`);
                    switch (data.error) {
                        case "Validation Failure: jwt expired":
                            message = "Session timeout";
                            break;
                        default:
                            message = data.error;
                    }
                    logoutSession(data.error);
                    history.push(`/logout?msg=${message}`);
                });
            }
            return response.json();
        })
        .then(function (jsonData) {
            //console.log("Response from " + url + ": " + JSON.stringify(jsonData));
            let respSuccess = jsonData.success;

            // Check For Error Case in Response: => success = false
            if (respSuccess === false) {
                let errorMessage = jsonData.error;
                console.log("ERROR Message: " + errorMessage);
                if (!!errorHandler) {
                    return errorHandler(errorMessage)
                } else {
                    throw new Error(errorMessage);
                }
            } else {
                dataHandler(jsonData);
            }
        })
        .catch(function (ex) {
            //logoutSession(ex.message);
            let errStr = `Failed to  connect to ${url}: Err: ${ex.message}`;
            //history.push(`/logout?msg=${errStr}`);
            console.error('Failed to  connect to ' + url, ex)
            errorHandler(errStr);
        });
}

const callRPCMultiForm = function (url, inputParams, fileToSend, dataHandler, errorHandler, api) {

    //isomorphic-fetch does not work well with multipart forms using axios instead.
    let auth = createAuth(api);


    let multiFormHeader = {
        headers: {
            'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
            'Authorization': auth
        }
    };

    console.log("Calling - " + url);

    //build body object
    const postBody = new FormData()
    postBody.append('file', fileToSend)
    postBody.append('impbox_id', inputParams.impbox_id)
    if (inputParams.debug) {
        postBody.append('debug', inputParams.debug)
    }


    axios({
        method: 'post',
        url: url,
        headers: multiFormHeader.headers,
        data: postBody
    })
        .then(function (response) {

            let message;

            if (isAdminAPI(api) && (response.status == 401 || response.status == 403)) {
                console.log("ERROR: Failure Message Returned: Status " + response.status);
                response.json().then(function (data) {
                    console.log(`ERROR: Failure Message Returned: Response: ${JSON.stringify(data)}`);
                    switch (data.error) {
                        case "Validation Failure: jwt expired":
                            message = "Session timeout";
                            break;
                        default:
                            message = data.error;
                    }
                    logoutSession(data.error);
                    history.push(`/logout?msg=${message}`);
                });
            }

            let jsonData = response.data;
            //console.log("Response from " + url + ": " + JSON.stringify(jsonData));

            let respSuccess = jsonData.success;

            // Check For Error Case in Response: => success = false
            if (respSuccess === false) {
                let errorMessage = jsonData.error;
                console.log("ERROR Message: " + errorMessage);
                if (!!errorHandler) {
                    return errorHandler(errorMessage)
                } else {
                    throw new Error(errorMessage);
                }
            } else {
                dataHandler(jsonData);
            }
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("callRPCMultiForm error data " + JSON.stringify(error.response.data));
                console.log("callRPCMultiForm error status " + error.response.status);
                console.log("callRPCMultiForm error headers " + error.response.headers);
                return errorHandler(error.response.data);

            } else if (!!error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                let errStr = "callRPCMultiForm error no response";
                console.log("callRPCMultiForm error no response" + JSON.stringify(error.request));
                return errorHandler(errStr);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('callRPCMultiForm SetUp Error', error.message);
                return errorHandler(error.message);
            }
        });
}

const createAuth = function (api){

    let auth_token = getToken() || "";
    if (api == "impbox") {
        auth_token = getImpBoxAccessToken() || "";
    } else if (api == "app") {
        auth_token = getAppAccessToken() || "";
    }
    let auth = `Bearer ${auth_token}`

    return auth;

}

const callFirmwareRPCMultiForm = function (url, inputParams, fileToSend, dataHandler, errorHandler, api) {

    //isomorphic-fetch does not work well with multipart forms using axios instead.

    let auth = createAuth(api);

    let multiFormHeader = {
        headers: {
            'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
            'Authorization': auth
        }
    };

    console.log("Calling - " + url);

    //build body object
    const postBody = new FormData()
    postBody.append('file', fileToSend)
    postBody.append('version', inputParams.version)
    postBody.append('environment', inputParams.environment)
    if (inputParams.debug) {
        postBody.append('debug', inputParams.debug)
    }


    axios({
        method: 'post',
        url: url,
        headers: multiFormHeader.headers,
        data: postBody
    })
        .then(function (response) {

            let message;

            if (isAdminAPI(api) && (response.status == 401 || response.status == 403)) {
                console.log("ERROR: Failure Message Returned: Status " + response.status);
                response.json().then(function (data) {
                    console.log(`ERROR: Failure Message Returned: Response: ${JSON.stringify(data)}`);
                    switch (data.error) {
                        case "Validation Failure: jwt expired":
                            message = "Session timeout";
                            break;
                        default:
                            message = data.error;
                    }
                    logoutSession(data.error);
                    history.push(`/logout?msg=${message}`);
                });
            }

            let jsonData = response.data;
            //console.log("Response from " + url + ": " + JSON.stringify(jsonData));

            let respSuccess = jsonData.success;

            // Check For Error Case in Response: => success = false
            if (respSuccess === false) {
                let errorMessage = jsonData.error;
                console.log("ERROR Message: " + errorMessage);
                if (!!errorHandler) {
                    return errorHandler(errorMessage)
                } else {
                    throw new Error(errorMessage);
                }
            } else {
                dataHandler(jsonData);
            }
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("callFirmwareRPCMultiForm error data " + JSON.stringify(error.response.data));
                console.log("callFirmwareRPCMultiForm error status " + error.response.status);
                console.log("callFirmwareRPCMultiForm error headers " + error.response.headers);
                return errorHandler(error.response.data);

            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                let errStr = "callRPCMultiForm error no response";
                console.log("callRPCMultiForm error no response" + JSON.stringify(error.request));
                return errorHandler(errStr);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('callFirmwareRPCMultiForm SetUp Error', error.message);
                return errorHandler(error.message);
            }
        });
}


export {
    notificationRead,
    getAllUsers,
    getAllSubscriptions,
    updateSubscriptionDetails,
    removeSubscription,
    deleteUser,
    archiveUser,
    registerUserWithDevice,
    registerIMPBox,
    getContactsForMobile,
    getNotificationsForMobile,
    pushAPNCustom,
    pushAPNGeneric,
    pushFCM,
    getUser,
    getIMPBoxesForMobile,
    addNotification,
    logCallApi,
    setupApi,
    newMessageApi,
    uploadOutgoingMessageApi,
    uploadFirmwareApi,
    updateSettingApi,
    settingAppliedApi,
    settingProcessedApi,
    getContactListApi,
    createContactSetApi,
    createContactApi,
    deleteContactsApi,
    getPagedPhoneNumberListApi,
    getShortPhoneNumberListApi,
    getSettingsApi,
    getFirmwareApi,
    createFirmware,
    getCallStats,
    getIMPBoxes,
    sendHeartbeat,
    deleteContextHelp,
    getAllContextHelps,
    createOrUpdateContextHelp,
    sendAdminEmail,
    getAllInvites,
    getAllFirmwares,
    updateFirmwareAdmin,
    getInvite,
    getInvitesByEmail,
    inviteUser,
    validateEmail,
    getStripeSession,
    getAPNStatsApi,
    getAPNLogsForDeviceApi,
    login,
    getAppAccessTokenApi,
    getIMPBoxRefreshTokenApi,
    impBoxAuthenticateApi,
    getIMPBoxActivity,
    getIMPBoxUsers,
    getIMPBoxCallHistory,
    getAllErrorLogs,
    createContactAndRemoveNotification,
    getAdminAccounts,
    placeCall,
    createAdminAccount,
    deleteAdminAccount,
    createSubWithUser,
    factoryReset,
    getAppVersions,
    updateAppVersion,
    getProducts,
    updateProduct,
    addProduct,
    deleteProduct,
    updateSubscriptionApi,
    exportUserImpContacts,
    getImportableBatches,
    importUserImpContacts,
    setMobileAuditMode,
    setUserContactBumper,
    updateIMPBoxPhoneNumberApi
};
