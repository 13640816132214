import React, {Component} from 'react';
import {
    getIMPBoxes,
    getIMPBoxActivity,
    getIMPBoxUsers,
    getSettingsApi,
    getIMPBoxCallHistory, getIMPBoxRefreshTokenApi, impBoxAuthenticateApi, getAppAccessTokenApi, getAllUsers
} from "../../util/apiServiceUtil";
import {IMPBoxUsers} from "./Details/ImpBoxUsers";
import {IMPBoxSettings} from "./Details/IMPBoxSettings";
import {IMPBoxSubscription} from "./Details/IMPBoxSubscription";
import IMPBoxDetails from "./Details/IMPBoxDetails";
import {IMPBoxTimeline} from "./Details/IMPBoxTimeline";
import IMPBoxContext from "../../contexts/IMPBoxContext";
import IMPBoxList from "./IMPBoxList";
import {authenticateAsIMPBox} from "../../util/authenticateUtils";
import _ from "lodash";
import {DateRangePicker} from "../Util/DateRangePicker";
import { useHistory } from "react-router-dom";

const moment = require('moment');

class IMPBoxAdmin extends Component {

    static contextType = IMPBoxContext;

    constructor(props) {
        super(props);
        console.log("Constructing IMPBoxAdmin")
        this.state = {
            error: "",
            //impboxes : [],
            currentIMPBox: {},
            from_date: moment().subtract(6, "days").utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            to_date: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            rangeView: moment().subtract(6, "days").utc().format('YYYY-MM-DDTHH:mm:ss[Z]')+ " - " +moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
        };
    }

    refreshIMPBoxes()  {

        console.log("refreshIMPBoxes: Calling refreshIMPBoxes from IMPBoxAdmin")
        this.context.setIMPBox({});
        let current_impbox_id = this.state.currentIMPBox.impbox_id;
        this.state.currentIMPBox = {};

        getIMPBoxes(
            (impboxes) => {
                let count = impboxes.impboxes.length;
                console.log(`refreshIMPBoxes: Got IMPBoxes: ${count} ${current_impbox_id}`);
                let impboxes_obj = this.makeImpBoxesFullObject(impboxes);
                console.log(`refreshIMPBoxes: Got IMPBoxes: ${JSON.stringify(impboxes_obj.impboxes[0])}`);
                this.setState({impboxes: impboxes_obj});
                // Get CurrentImpBox from impboxes_obj and set currentIMPBox in state.
                 const current_box = _.find(impboxes_obj.impboxes, function (impbox){
                     return (impbox.impbox_id == current_impbox_id)
                 })
                 console.log(`Current Box  =  ${JSON.stringify(current_box)}`)
                 this.setState({currentIMPBox: current_box});

            }
        );

    }

    componentDidMount() {

        console.log(`In IMPBoxAdmin: componentDidMount`);
        let loggedInAs = this.context.impbox_id;

        getIMPBoxes(
            (impboxes) => {

                let imp_objs = this.makeImpBoxesFullObject(impboxes)
                this.setState({impboxes: imp_objs});

                let loggedInIMPBox;

                if(!!loggedInAs && this.state.impboxes.impboxes ) {
                    loggedInIMPBox = _.find(impboxes.impboxes, function (impbox) {
                        return (impbox.impbox_id == loggedInAs)
                    });
                }

                // Refresh Details with
                if (!!loggedInAs ){
                    console.log(`In IMPBoxAdmin: componentDidMount loggedInAs as ${loggedInAs}`);
                    this.showDetails(loggedInAs, loggedInIMPBox, this.context.setIMPBox   );
                }
            },
            (error) => {
                console.log("ERROR in getIMPBoxes =  " + error);
                this.setState({"error": error});
            })

    }

    makeImpBoxesFullObject(impboxes){

        // The natural form of Settings in the DB is in String Form. Convert to  Object form.
        let impboxes_obj = [];

        for ( let impbox of impboxes.impboxes ) {
            let new_impbox;
            new_impbox = impbox;
            if(!!impbox.Setting && !!impbox.Setting.settings) {
                new_impbox.Setting.settings = JSON.parse(impbox.Setting.settings);
            }
            impboxes_obj.push(new_impbox);
        }

        let ret_imps = {"impboxes" : impboxes_obj  };

        return ret_imps;

    }

    setDateRange(fromDate, toDate) {

        let newRange = {};
        newRange.from_date = fromDate;
        newRange.to_date = toDate;
        newRange.rangeView = fromDate+" - "+toDate;

        this.setState(newRange);


    }

    setWTS(wts) {
        this.setState({'whatToShow':wts})
    }

    showDetails(impbox_id, ib, setIMPBox) {

        this.state = {"error": ""};
        this.state = {"impboxActivity": null };
        this.state = {"history": null };

        if (!!impbox_id) {
            console.log(`ShowDetails with ib ${impbox_id}`);
            authenticateAsIMPBox.bind(this)(ib.impbox_uuid, setIMPBox, (returnDetails) => {

                console.log(`Return from authenticateAsIMPBox: ${JSON.stringify(returnDetails)}`);

                let callData = {
                    "impbox_id": impbox_id
                }

                console.log(`currentIMPBox = ${JSON.stringify(ib)}`);

                this.setState({currentIMPBox: ib});

                //getIMPBoxActivity

                callData = {
                    "impbox_id": impbox_id,
                    "from_date": this.state.from_date,
                    "to_date": this.state.to_date,
                    "action_type": "ALL"
                };

                console.log("callData - " + JSON.stringify(callData));

                getIMPBoxActivity(
                    callData,
                    (activity) => {
                        this.setState({impboxActivity: activity});
                    },
                    (error) => {
                        console.log("ERROR in getIMPBoxActivity =  " + error);
                        this.setState({"error": error});
                    })

                let cl = {};
                cl.impbox_id = impbox_id;

                getIMPBoxCallHistory(callData, (returnDetails) => {
                        this.setState({history: returnDetails});
                    },
                    (error) => {
                        console.log("ERROR in getIMPBoxCallHistory =  " + JSON.stringify(error));
                        this.setState({"error": error, history: []});
                    });

            });
        } else {
            this.setState({currentIMPBox: {} });
        }
    }

    render() {

        if (!this.state.impboxes ) {
            return <div></div>
        } else {

            const layoutStyle = {
                border: '0px solid #DDD'
            };

            console.log(`Rendering IMPBoxAdmin w/ impbox = ${this.state.currentIMPBox.impbox_id}  `);

            return (
                <div style={{width: "950px"}}>
                    <div style={{'float': 'left', 'width': '28%', height: '650px', overflow: 'auto'}}>
                        <DateRangePicker  onRangeChange={(fromDate, toDate)=>this.setDateRange(fromDate,toDate)}/>
                        <div style={{backgroundColor:"#efefef", padding:"3px" ,borderBottomStyle:"solid"}}>
                            <input type="radio" id="wts_number"
                                   name="wts" value="number"  onClick={()=>this.setWTS("number")} />&nbsp;
                            <label htmlFor="onlineStatus_online">Phone</label> &nbsp; &nbsp;
                            <input type="radio" id="wts_uuid"
                                   name="wts" value="UUID" onClick={()=>this.setWTS("UUID")} />&nbsp;
                            <label htmlFor="onlineStatus_online">UUID</label> &nbsp; &nbsp;
                            <input type="radio" id="wts_email"
                                   name="wts" value="email"  defaultChecked={true} onClick={()=>this.setWTS("email")} />&nbsp;
                            <label htmlFor="onlineStatus_online">Email</label> &nbsp; &nbsp;

                        </div>

                        <IMPBoxList whatToShow={this.state.whatToShow} currentIMPBoxID={this.state.currentIMPBox.impbox_id} IMPBoxes={this.state.impboxes.impboxes} showDetails={this.showDetails.bind(this)} />
                    </div>
                    <div style={{'float': 'left', 'width': '670px', margin: "5px", maxHeight:"600px",overflow:"auto" }}>

                        <div style={layoutStyle}>
                            <div style={{textAlign: 'left', marginLeft: '20px'}}>
                                <IMPBoxDetails refreshIMPBoxes={this.refreshIMPBoxes.bind(this)} currentIMPBox={this.state.currentIMPBox}/>
                            </div>
                        </div>

                        <div style={layoutStyle}>

                            <IMPBoxSubscription currentIMPBox={this.state.currentIMPBox} />
                            <IMPBoxSettings currentIMPBox={this.state.currentIMPBox}  />
                            <IMPBoxTimeline impbox_id={this.state.currentIMPBox.impbox_id}
                                            impboxActivity={this.state.impboxActivity} history={this.state.history}/>

                        </div>
                    </div>
                </div>

            )
        }
    }

}
export default IMPBoxAdmin;
