import React, {Component} from 'react';
import {newMessageApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";
import IMPBoxContext from "../../../contexts/IMPBoxContext";
import ApiInfo from "../../Util/ApiInfo";

class NewMessage extends Component {

    static contextType = IMPBoxContext;
    //Api Details
    api = "newMessage";
    service = "IMPBox";
    id = "impbox_uuid";

    constructor(props) {
        super(props);
        console.log("In newMessage constructor");
        this.state = {
            "error": null,
            "details": null,
            "impbox_id": null,
            "message_id": null,
            "duration": null,
            "selectedFile": null
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        let val = e.target.value;
        let no = {};
        no[fld_id] = val;
        console.log("changeText: updating state with  " + JSON.stringify(no));
        this.setState(no);
        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }

    onChangeHandler = event => {

        console.log(event.target.files[0])
        this.setState({
            selectedFile: event.target.files[0]
        })
        console.log("FILE in state = " + JSON.stringify(this.state.selectedFile));

    }

    newMessage(impbox_id) {

        this.setState({"details": {}});
        this.setState({"error": ""});

        let um = {};
        um.impbox_id = impbox_id;
        um.debug = true;

        console.log("newMessage Data = " + JSON.stringify(um));
        console.log("newMessage File = " + this.state.selectedFile);

        newMessageApi(um, this.state.selectedFile, (returnDetails) => {

                console.log("API return from newMessage " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    message_id: returnDetails.message_id,
                    message_link: returnDetails.debug.message_link,
                    s3Message: returnDetails.debug.s3Message,
                    s3_status: returnDetails.debug.s3_status,
                    duration: returnDetails.duration
                };
                this.setState({"details": details});

            },
            (error) => {

                console.log("ERROR in newMessage =  " + JSON.stringify(error));
                if(!!error && !!error.error){
                    error = error.error
                } else if (!!error && !!error.message){
                    error = error.message
                }
                this.setState({"error": error});
            });

    }

    render() {

        //Used to get the API Info
        let id = this.context.impbox_uuid;

        let success_message;
        if ( this.state.details && this.state.details.message_id) {
            success_message =
               <div>
                <div><p style={{color: 'green'}}>Message created: Id = {this.state.details.message_id}</p></div>
                <div><p style={{color: 'green'}}>Message Link = {this.state.details.message_link}</p></div>
                <div><p style={{color: 'green'}}>S3 Message = {this.state.details.s3Message}</p></div>
                <div><p style={{color: 'green'}}>S3 Status = {this.state.details.s3_status}</p></div>
                <div><p style={{color: 'green'}}>Message Duration = {this.state.details.duration}</p></div>
               </div>
        }


        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'New Message'}>

                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>

                            {success_message}
                            <ApiInfo api={this.api} service={this.service} id={id}/>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>IMPBox ID:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.context.impbox_id}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Message File:</div>
                                <div className={'fieldInput'}>
                                    <input type="file" name="file" onChange={this.onChangeHandler}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <button onClick={() => this.newMessage(this.context.impbox_id)}>Upload Message</button>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {NewMessage};