import React, {Component} from 'react';
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import { updateSubscriptionDetails, removeSubscription} from "../../util/apiServiceUtil";
const moment = require('moment');
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');




class SubscriptionUpdates extends Component {

    constructor(props) {
        super(props);
        console.log("In SubscriptionList constructor");

        let plans = [
            {plan_id:1, primary_plan:"P Zero"},
            {plan_id:2, primary_plan:"P X"},
            {plan_id:3, primary_plan:"Annual Plan"},
            {plan_id:4, primary_plan:"Monthly Plan"},
            {plan_id:5, primary_plan:"Fixed Price"},
        ]


        this.state = {
            "error": null,
            "details": null,
            showHide: false,
            subscription:{},
            products:[],
            plans:plans

        }
    }

    componentDidMount() {

        this.setState({subscription:this.props.subscription, products:this.props.products});

    }

    removeSubscription(sub_id)
    {
        let subToUpdate = {subscription_id:sub_id};

        var result = window.confirm("You sure you want to delete?");
        if (!result) {
            return;
        }


        removeSubscription(subToUpdate, (returnDetails) => {

                //console.log("API return from updateSubscriptionDetails " + JSON.stringify(returnDetails));

                this.props.refreshSubscriptions();

            },
            (error) => {

                console.log("ERROR in updateSubscriptionDetails =  " + JSON.stringify(error));
                if(!!error && !!error.error){
                    error = error.error
                } else if (!!error && !!error.message){
                    error = error.message
                }
                this.setState({"error": error});
            });
    }

    updateProduct(subToUpdate) {


        updateSubscriptionDetails(subToUpdate, (returnDetails) => {

                //console.log("API return from updateSubscriptionDetails " + JSON.stringify(returnDetails));

                this.props.refreshSubscriptions();

            },
            (error) => {

                console.log("ERROR in updateSubscriptionDetails =  " + JSON.stringify(error));
                if(!!error && !!error.error){
                    error = error.error
                } else if (!!error && !!error.message){
                    error = error.message
                }
                this.setState({"error": error});
            });

    }


    changeProduct(e)
    {
        const prod_id = e.target.value;
        let products = this.state.products;


        let pickedProduct = products.find(({product_id})=> product_id == prod_id);

        let oldSub = this.props.subscription;

        oldSub.product_id=prod_id;
        oldSub.product_name = pickedProduct.name;

        this.setState({subscription:oldSub});
        this.updateProduct(oldSub);


    }



    changePlan(e)
    {
        const plan_id = e.target.value;

        let oldSub = this.props.subscription;

        oldSub.plan_id=plan_id;

        this.setState({subscription:oldSub});
        this.updateProduct(oldSub);


    }

    changeExpiration(e)
    {
        const newDate = e.target.value +" 12:00:00";

        let oldSub = this.props.subscription;

        oldSub.end_date=newDate;

        this.setState({subscription:oldSub});
        this.updateProduct(oldSub);


    }

    render() {

        let sub = this.props.subscription;

        let products = this.state.products;
        let plans = this.state.plans;
        let prods = [];
        let plansToShow = [];

        if (!products || products.length === 0) {
            return <div></div>
        }

        for (let prod of products) {
            let thisProdShow = <option
                key={"prodSelect_" + prod.product_id}
                value={prod.product_id}>{ `${prod.name} `}
            </option>;

            prods.push(thisProdShow);
        }

        for (let plan of plans) {
            let thisPlanShow = <option
                key={"planSelect_" + plan.plan_id}
                value={plan.plan_id}>{ `${plan.primary_plan} `}
            </option>;

            plansToShow.push(thisPlanShow);
        }


        let formattedDate = new moment(sub.end_date).format("YYYY-MM-DD");

        return (
            <div>
                <div style={{padding:'10px'}}>
                    Change the product:
                    <select id="allProducts" value={sub.product_id}
                            onChange={(event, fld_id) => this.changeProduct(event)}>
                        {prods}
                    </select>

                </div>

                <div>
                    Change the plan:
                    <select id="allPlans" value={sub.plan_id}
                            onChange={(event, fld_id) => this.changePlan(event)}>
                        {plansToShow}
                    </select>

                </div>


                <div>
                    Change the Expiration:  {formattedDate} <input type={"date"} value={formattedDate}  onChange={(event, fld_id) => this.changeExpiration(event)}/>
                </div>



                <div style={{'fontSize': '10pt', margin:'0 auto', width:'20%', paddingTop:'10px'}}>
                    <button onClick={()=>this.removeSubscription(sub.subscription_id)}>Delete this subscription</button>
                </div>

                <ImpCollapsible collapsed={true} expandText="See Subscription Details" header={''}>
                    <div style={{clear:"BOTH"}}></div>
                    <div style={{'width': '700px', 'marginLeft' : 50, 'textAlign' : 'left', 'margin': '50', 'padding': 30, "border": '0px solid #DDD'}}>
                        <JSONPretty id="json-pretty" data={sub} theme={JSONPrettyMon}></JSONPretty>
                    </div>

                </ImpCollapsible>
                <div style={{clear:"BOTH"}}></div>
            </div>
        );
    }
}
export default withAlert()(SubscriptionUpdates);
