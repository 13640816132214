import React, {Component} from 'react';
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import SubscriptionUpdates from "./SubscriptionUpdates";
const moment = require('moment');
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



class SubscriptionList extends Component {

    constructor(props) {
        super(props);
        console.log("In SubscriptionList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false,
            products:[]

        }
    }


    render() {
        const datetime_options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };

        let subsToShow = [];
        let subsList = this.props.subscriptions;
        //console.log("ILIST " + JSON.stringify(iList));

        if (subsList === undefined || subsList.length === 0) {
            return <div>No Subscriptions</div>
        }

        let header = <div key={"user_head"} className={'headerRow'}>
            <div style={{'width': '80px'}} className={'headerItem'}>Sub ID</div>
            <div style={{'width': '200px'}} className={'headerItem'}>User Email</div>
            <div style={{'width': '80px'}} className={'headerItem'}>imp Box Id </div>
            <div style={{'width': '200px'}} className={'headerItem'}>Product</div>
            <div style={{'width': '90px'}} className={'headerItem'}>Plan</div>
            <div style={{'width': '150px'}} className={'headerItem'}>Expires</div>

            <div className={'headerEnd'}></div>
        </div>

        subsToShow.push(header);

        for (let sub of subsList) {

            var date = new Date(sub.end_date);
            let endDate = date.toLocaleString('en-US',datetime_options);

            let thisSub = <div key={sub.subscription_id} className={'listRow'}>

                <div style={{'width': '80px', 'minHeight': '2PX',minWidth:'80px'}} className={'listItem'}>{sub.subscription_id}</div>
                <div style={{'width': '200px',minWidth:'200px'}} className={'listItem'}>{sub.User.PII.email}</div>
                <div style={{'width': '80px',minWidth:'80px'}} className={'listItem'}>{sub.impbox_id}&nbsp;</div>
                <div style={{'width': '200px',minWidth:'200px'}} className={'listItem'}>{sub.product_name}</div>
                <div style={{'width': '90px',minWidth:'90px'}} className={'listItem'}>{sub.Plan.primary_plan}</div>
                <div style={{'width': '150px',minWidth:'150px'}} className={'listItem'}>{endDate}</div>


                <ImpCollapsible collapsed={true} expandText="Update" header={''}>
                    <div style={{clear:"BOTH"}}></div>
                    <div style={{backgroundColor:'lightgrey','width': '900px', 'marginLeft' : 50, 'textAlign' : 'left', 'margin': '10', 'padding': 10, "border": '0px solid #DDD'}}>
                       <SubscriptionUpdates subscription={sub} products={this.props.products }  refreshSubscriptions={() => this.props.refreshSubscriptions() }/>
                    </div>

                </ImpCollapsible>


                <div className={'listEnd'}></div>

            </div>

            subsToShow.push(thisSub);
        }

        return (
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {subsToShow}
                </div>
            </div>
        );
    }
}
export default withAlert()(SubscriptionList);
