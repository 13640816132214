import React, {Component} from 'react';
import {ImpCollapsible} from "../Util/ImpCollapsible";
import {createOrUpdateContextHelp, deleteContextHelp, getContextHelp} from "../../util/apiServiceUtil";
import {app_api_v1} from "../../util/config";
import {withAlert} from 'react-alert'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
Quill.register('modules/imageResize', ImageResize)

class ContextHelpList extends Component {

    constructor(props) {
        super(props);
        console.log("In ContextHelpList constructor");
        this.handleChange = this.handleChange.bind(this)
        console.log(`contexthelps-props: ${this.props.contexthelps}`)

        this.state = {
            "error": null,
            showHide: false,
            contexthelps: this.props.contexthelps
        }
    }

    componentDidUpdate() {
        console.log("componentDidUpdate: in ContextHelpList " );
    }

    handleChange (editorhtml, index) {
        if (!!index) {
            console.log("INDEX = " + index);
        }

        //this.setState({contexthelp: {"html" : html }});


        let contexthelps = [...this.state.contexthelps];
        contexthelps[index]["html"] = editorhtml;

        this.setState({contexthelps});

    }



    changeText(e, fld_id) {

        //console.log("changeText: updated field " + JSON.stringify(fld_id));
        const target = e.target;
        const val = target.name === 'mkting_agree' ? target.checked : target.value;
        //console.log("changeText: updated value " + val);

        let contexthelps = [...this.state.contexthelps];
        //console.log("Working with: " + fld_id['index'] + " and " + fld_id['field']);

        contexthelps[fld_id["index"]][fld_id["field"]] = val;

        //console.log("changeText: setting state " + JSON.stringify(contexthelps));
        this.setState({contexthelps});

    }


    updateContextHelp(index) {
        console.log("Starting the ContextHelp Update Process");

        let contexthelp = {
            "name": this.state.contexthelps[index].name,
            "html": this.state.contexthelps[index].html,
            "redirectURL":this.state.contexthelps[index].redirectURL,
        }

        console.log("In updateContextHelp: " + JSON.stringify(contexthelp));

        createOrUpdateContextHelp(contexthelp, (returnDetails) => {

                console.log("API return from createOrUpdateContextHelp " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    contexthelp: returnDetails.contexthelp
                };
                this.setState({"details": details});
                let success_message = "Created ContextHelp: " + returnDetails.contexthelp.contexthelp_id;

                this.props.alert.success(success_message);
                this.props.refreshContextHelps();
            },
            (error) => {

                console.log("ERROR in createNewUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }

    getContextHelp(name) {

        console.log("Starting the ContextHelp GET Process: name = " + name);

        let width = 300;
        let height = 600;

        let leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
        let topPosition = (window.screen.height / 2) - ((height / 2) + 50);


        window.open(app_api_v1 + "/contextHelp?helpContext=" + name, "",
            "width=" + width + ",height=" + height +
            ", left=" + leftPosition + ",top=" + topPosition +
            ", screenX=" + leftPosition + ",screenY=" + topPosition);

    }


    deleteContextHelp(contexthelp_id) {

        console.log("Starting the ContextHelp Delete Process: ID = " + contexthelp_id);
        deleteContextHelp(contexthelp_id, (returnDetails) => {

                console.log("API return from deleteContextHelp " + JSON.stringify(returnDetails));

                if (returnDetails.success) {
                    this.props.alert.success("ContextHelp #" + returnDetails.contexthelp_id + " deleted");
                } else {
                    this.props.alert.success(returnDetails.error);
                }

                this.props.refreshContextHelps();

            },
            (error) => {

                console.log("ERROR in deleteContextHelp =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR" + JSON.stringify(error));
            });
    }


    render() {

        console.log("render in ContextHelpList " );


        let chList = this.props.contexthelps;

        for (let i = 0; i < chList.length; i++) {
            console.log("Adding: " + chList[i]  );
            this.state.contexthelps[i] = chList[i];
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let chToShow = [];

        if (this.props.contexthelps === undefined || this.props.contexthelps.length === 0) {
            return <div></div>
        }

        let header = <div key={"user_head"} className={'headerRow'}>
            <div style={{'width': '80px'}} className={'headerItem'}>Id</div>
            <div style={{'width': '300px'}} className={'headerItem'}>Name</div>
            <div className={'headerEnd'}></div>
        </div>

        chToShow.push(header)
        for (let i = 0; i < chList.length; i++) {

            let ch = chList[i];

            let thisCH = <div key={ch.contexthelp_id} className={'listRow'}>

                <div style={{'width': '80px', 'minHeight': '2PX'}} className={'listItem'}>{ch.contexthelp_id}</div>
                <div style={{'width': '300px'}} className={'listItem'}>{ch.name}</div>
                <div style={{'width': '100px'}} className={'listItem'}>
                    <button onClick={() => this.deleteContextHelp(ch.contexthelp_id)}>Delete</button>
                </div>
                <div style={{'width': '100px'}} className={'listItem'}>
                    <button onClick={() => this.getContextHelp(ch.name)}>Test</button>
                </div>

                <ImpCollapsible collapsed={true} expandText="ContextHelp Details" header={''}>

                        <div style={layoutStyle}>


                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {/*{success_message}*/}


                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.contexthelps[i].name}
                                               onChange={(event, fld_id) => this.changeText(event, {
                                                   "field": "name",
                                                   "index": i
                                               })}
                                               size={'40'} readonly="readonly"/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>redirectURL:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.contexthelps[i].redirectURL}
                                               onChange={(event, fld_id) => this.changeText(event, {
                                                   "field": "redirectURL",
                                                   "index": i
                                               })}
                                               size={'80'} />
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>html generated:</div>
                                    <div className={'fieldInput'}>
                                        <textarea value={this.state.contexthelps[i].html}
                                               rows="10" cols="60"/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <ReactQuill theme="snow"
                                            modules={ContextHelpList.modules}
                                            formats={ContextHelpList.formats}
                                            value={this.state.contexthelps[i].html}
                                            onChange={(value, index) => this.handleChange(value, i)}
                                />

                    </div>

                    <button onClick={(index) => this.updateContextHelp(i)}>Update ContextHelp</button>

                </ImpCollapsible>

                <div className={'listEnd'}></div>

            </div>

            chToShow.push(thisCH);
        }

        return (
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {chToShow}
                </div>
            </div>
        );
    }
}
export default withAlert()(ContextHelpList);

/*
* Quill modules to attach to editor
* See https://quilljs.com/docs/modules/ for complete options
*/
ContextHelpList.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image' ],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
    imageResize: {
        modules: [ 'Resize', 'DisplaySize' ]
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ContextHelpList.formats = [
    'header', 'font', 'size','color','background', 'align',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

