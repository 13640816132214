import React, {Component} from 'react';
import {getAPNStatsApi, getAPNLogsForDeviceApi} from "../../util/apiServiceUtil";
import {ImpCollapsible} from "../Util/ImpCollapsible";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class APNLogs extends Component {

    constructor(props) {
        super(props);
        console.log("In APNLogs constructor");
        this.state = {
            "error1": "",
            "error2": "",
            "apn_stats": null,
            "apn_stats_by_device": null,
        };
    }


    componentWillReceiveProps(nextProps) {

        let uc = nextProps.userContext;

        if ( !!uc && uc.user) {
            try {
                let defMobile = "";
                console.log("USER = " +  JSON.stringify(uc.user))
                defMobile = uc.user.Mobiles[0].notification_token;
                this.setState({"device_token": defMobile });
                console.log("User: " + uc.email + " Token = " + defMobile );
            } catch (e) {
                console.error("No notification_token found for user " + uc.user.PII.email + " Error: " + e.message)
                this.setState({"device_token": "No Notification Token for this user"  });
            }
        }
        console.log("componentDidUpdate: in APNLogs " );
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));

        this.setState(no);

        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }

    APNStats() {

        this.setState({"apn_stats": null});
        this.setState({"error1": null});
        this.setState({"success1": null});


        let cl = {};

        this.setState({"group_by_device": 1});

        //cl.group_by_device = this.state.group_by_device;
        let group_by_device = this.refs.group_by_device.checked;

        cl.group_by_device = group_by_device;

        getAPNStatsApi(cl, (returnDetails) => {

                console.log("API return from APNLogsApi " + JSON.stringify(returnDetails));

                this.setState({"apn_stats": returnDetails.stats});

                if (returnDetails.success === true) {
                    this.setState({"success1": "Successfull Call"});
                } else {
                    this.setState({"success1": "UnSuccessfull Call"});
                }

            },
            (error) => {

                console.log("ERROR in getAPNStatsApi =  " + JSON.stringify(error));
                this.setState({"error1": error});
            });

    }

    APNStatsByDevice() {

        this.setState({"apn_stats_by_device": null});
        this.setState({"error2": null});
        this.setState({"success2": null});


        let cl = {};
        cl.device_token = this.state.device_token;

        getAPNLogsForDeviceApi(cl, (returnDetails) => {

                console.log("API return from APNLogsForDeviceApi " + JSON.stringify(returnDetails));

                this.setState({"apn_stats_by_device": returnDetails.stats});
                if (returnDetails.success === true) {
                    this.setState({"success2": "Successfull Call"});
                } else {
                    this.setState({"success2": "UnSuccessfull Call"});
                }

            },
            (error) => {

                console.log("ERROR in getAPNLogsForDeviceApi =  " + JSON.stringify(error));
                this.setState({"error2": error});
            });

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let all_stats_data = <div></div>;
        let stats_by_device_data = <div></div>;

        if (this.state.apn_stats) {
            all_stats_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.apn_stats} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        if (this.state.apn_stats_by_device) {
            stats_by_device_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.apn_stats_by_device}
                                theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }


        return (

            <div style={layoutStyle}>


                <div style={{'fontSize': '10pt'}}>

                    <ImpCollapsible collapsed={true} header={'Push Notification Logs'}>

                        <div style={layoutStyle}>
                            <div>
                                <div className={'sectionBody'}>

                                    <div><p style={{color: 'red'}}>{this.state.error1}</p></div>
                                    <div><p style={{color: 'green'}}>{this.state.success1}</p></div>


                                    <table align={"center"}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <button onClick={() => this.APNStats()}>Get Push Notification Stats</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>

                                                <div style={{
                                                    margin: 'auto',
                                                    padding: 5,
                                                }}>
                                                    <label htmlFor="group_by_device">Group By Device</label>
                                                    <input style={{margin: 10}}
                                                           id="group_by_device"
                                                           type="checkbox"
                                                           ref="group_by_device"
                                                    />

                                                </div>

                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>

                                    </table>
                                    <div>
                                        {all_stats_data}
                                    </div>


                                </div>

                            </div>
                        </div>

                        <div style={layoutStyle}>


                            <div>
                                <div className={'sectionBody'}>

                                    <div><p style={{color: 'red'}}>{this.state.error2}</p></div>
                                    <div><p style={{color: 'green'}}>{this.state.success2}</p></div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Device Token:</div>
                                        <div className={'fieldInput'}>
                                            <input
                                                type={'text'}
                                                value={this.state.device_token}
                                                onChange={(event, fld_id) => this.changeText(event, 'device_token')}
                                                size={'60'}
                                            />
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <button onClick={() => this.APNStatsByDevice()}>Get Push Notification Stats By Device</button>
                                    {stats_by_device_data}

                                </div>

                            </div>

                        </div>


                    </ImpCollapsible>

                </div>

            </div>
        );
    }
}
export {APNLogs};

