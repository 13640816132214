import React, {Component} from 'react';




class UserBoxMobilesList extends Component {

    constructor(props) {
        super(props);
        console.log("In UserList constructor");
    }

    render() {

        let bmsToShow = [];

        let bmList = this.props.boxmobiles;


        if (bmList === undefined || bmList.length === 0) {
            return <div></div>
        }
        for (let bm of bmList)
        {
            let thisBM = <div  key={bm.box_mobile_id} className={'listRow'}>

                <div style={{'width': '30px','minHeight':'2PX'}} className={'listItem'}>{bm.impbox_id}</div>
                <div style={{'width': '230px','minHeight':'2PX'}} className={'listItem'}>{bm.nickname}</div>



                <div className={'listEnd'}></div>
            </div>

            bmsToShow.push(thisBM);
        }



        return (
            <div  style={{'paddingLeft':'20px','clear':'both'}} >


                <div  style={{'background':'rgba(157,121,88,0.78)'}}>
                    {bmsToShow}
                </div>

            </div>
        );
    }
}
export default UserBoxMobilesList;
