import React, {Component} from 'react';
import {
    exportUserImpContacts,
    getImportableBatches,
    importUserImpContacts,
    setMobileAuditMode,
    setUserContactBumper
} from "../../../util/apiServiceUtil";
import AuditModeCheckBox from "./AuditModeCheckBox";
import DisableContactBumperCheckBox from "./DisableContactBumperCheckBox";


var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class UserDetails extends Component {

    constructor(props) {
        super(props);
        console.log("In ShowActivity constructor");
        this.state = {
            "error": "",
            "success":"",
            "contact_list": null,
            "showOptions": false,
            "showExport": false,
            "showImport": false,
            "expName":"",
            "importableBatches":[],
            "batchToImport":"select"
        };
    }

    toggleOptions()
    {
        let newVal = !this.state.showOptions;
        this.setState({"showOptions":newVal})
    }

    toggleExport()
    {
        let newVal = !this.state.showExport;
        this.setState({"showExport":newVal})
    }

    toggleImport()
    {
        let newVal = !this.state.showImport;
        this.setState({"showImport":newVal})
    }



    showExportContacts(BMid)
    {
        let user = this.props.user;
        this.getImportableBatches();
        this.setState({"expName":user.email});
        this.toggleExport();
    }
    showImportContacts(BMid)
    {
        let user = this.props.user;
        this.getImportableBatches();

        this.toggleImport();
    }

    updateExpName(e)
    {
        let newVal = e.target.value;

        this.setState({"expName":newVal})
    }

    //get the list of importable batches
    getImportableBatches()
    {
        getImportableBatches(
            {},
            (response) => {

                this.setState({importableBatches: response.batches});
                return ;

            },
            (error) => {

                this.setState({"error": error});
                return ;
            })

    }

    //Actually execute the contact export
    exportContacts()
    {

        let user = this.props.user;
        let bmId = user.boxmobiles[0].box_mobile_id;
        let batchName = this.state.expName;


        let existingBatches = this.state.importableBatches;

        if(existingBatches.includes(batchName))
        {
            alert("A batch with the name "+batchName+ " already exists, please use a different name (just add a number to the end?) ");
            return;
        }

        let payload = {};
        payload.box_mobile_id = bmId;
        payload.batch_name = batchName;


        exportUserImpContacts(
            payload,
            (response) => {

                this.setState({success: JSON.stringify(response)});
                return ;

            },
            (error) => {

                this.setState({"error": error});
                return ;
            })


    }

    setImportable(e)
    {
        let newVal = e.target.value;
        this.setState({"batchToImport":newVal});

    }

    //execute the contact import
    importContacts(BMid)
    {
        let user = this.props.user;
        let bmId = user.boxmobiles[0].box_mobile_id;
        let batchName = this.state.batchToImport;

        if(batchName=="select")
        {

            alert("You must select a batch to import.");
            return;
        }


        let payload = {};
        payload.box_mobile_id = bmId;
        payload.batch_name = batchName;




        importUserImpContacts(
            payload,
            (response) => {

                this.setState({success: JSON.stringify(response)});
                return ;

            },
            (error) => {

                this.setState({"error": error});
                return ;
            })

    }


    calculate_imp_created(create_date){

        let now = new Date(Date.now());
        console.log(`Now = ${now.toDateString()}`);
        console.log(`Create_Date = ${create_date}`);
        let c_date = Date.parse(create_date);
        console.log(`c_date = ${c_date.toDateString}`);

        if ( !!c_date && !isNaN(c_date.valueOf())  ){

            // The number of milliseconds in one day
            const ONE_DAY = 1000 * 60 * 60 * 24;
            // Calculate the difference in milliseconds
            const differenceMs = Math.abs(now - c_date);
            // Convert back to days and return
            let differenceDays = Math.round(differenceMs / ONE_DAY);
            return `${differenceDays} days ago`;


        } else {
            return "Invalid date";
        }
    }




    render() {

        let userButtonStyle={
            backgroundColor:"lightGrey",
            borderRadius:"10px",
            width:'93px',
            paddingLeft: "4px",
            cursor:'pointer', margin:'2px'
        }
        let importableBs = this.state.importableBatches;
        let batchPicker=<div></div>;
        let options =[];
        if(importableBs)
        {
            //console.log("building select for " + JSON.stringify(importableBs));
            let rnd = Math.floor(Math.random() * 100000)
            importableBs.map((op, i) => {

                options.push( <option value={op} id={i} key={Math.floor(Math.random() * 100000)} >{op}</option>);
            });

            // console.log("build options - "+JSON.stringify(options));
            batchPicker = <select style={{color:'black'}} value={this.state.batchToImport} onChange={(e)=>this.setImportable(e)}>
                <option value={"select"} id={0} key={Math.floor(Math.random() * 100000)} >Select</option>

                {options}

            </select>
        }


        let user = this.props.user;
        let isInvitee = this.props.isInvitee;
        let userToShow;
        let bgc;

        if (isInvitee) {
            bgc = '#f2aaff';
            userToShow =
                <div style={{backgroundColor: bgc, margin: '3px 3px 3px 20px', padding: '3px', borderRadius: '5px'}}>

                    <b>INVITED:</b>  &nbsp;&nbsp;&nbsp; <b>Name:</b> {user.invitee_name}  &nbsp;&nbsp;
                    <b>Email:</b> {user.invitee_email}  &nbsp;&nbsp; <b>Role:</b> {user.invitee_role}

                </div>
        } else {
            if (user.boxmobiles[0].role === "Owner") {
                bgc = '#ffaaaa';
            } else if (user.boxmobiles[0].role === "Co-Owner") {
                bgc = '#aaffb5';
            } else if (user.boxmobiles[0].role === "User") {
                bgc = '#aad7ff';
            }

            let dispOptions = "none";
            let optLabel = "Show Options"
            if(this.state.showOptions)
            {
                dispOptions='block';
                optLabel = "Hide Options"

            }
            else
            {
                dispOptions='none';
                optLabel = "Show Options"

            }

            let dispExp = "none";
            let expLabel = "Show Export";
            if(this.state.showExport)
            {
                dispExp='block';
                expLabel = "Hide Export"

            }
            else
            {
                dispExp='none';
                expLabel = "Show Export"

            }

            let dispImp = "none";
            let impLabel = "Show Import";
            if(this.state.showImport)
            {
                dispImp='block';
                impLabel = "Hide Import"

            }
            else
            {
                dispImp='none';
                impLabel = "Show Import"

            }

            let box_mobile_created_days = this.calculate_imp_created(user.boxmobiles[0].created);

            userToShow =
                <div style={{backgroundColor: bgc, margin: '3px 3px 3px 20px', padding: '3px', borderRadius: '5px', fontSize:'10pt'}}>

                    <div style={{float:"left"}} ><b>User ID:</b> {user.user_id} &nbsp;&nbsp;</div>
                    <div style={{float:"left"}} ><b>Name:</b> {user.full_name} &nbsp;&nbsp;</div>
                    <div style={{float:"left"}} ><b>Email:</b> {user.email} &nbsp;&nbsp;<b> Knows this IMPBox
                        as:</b> {user.boxmobiles[0].nickname} &nbsp;&nbsp;</div>
                    <div style={{float:"left"}} ><b> Role:</b> {user.boxmobiles[0].role} &nbsp;&nbsp;</div>
                    <div style={{float:"left"}} ><b> box_mobile_id:</b> {user.boxmobiles[0].box_mobile_id} &nbsp;&nbsp;</div>
                    <div style={{float:"left"}} ><b> Box_Mobile created :</b> {box_mobile_created_days} &nbsp;&nbsp;</div>

                    <div style={{float:"left"}} ><b> App Version:</b> {user.appVersion} &nbsp;&nbsp;</div>
                    <div style={{float:"left"}} ><b> Mobile_Id:</b> {user.boxmobiles[0].mobile.mobile_id} &nbsp;</div>

                    <div style={{float:"left"}} > <b> Address_Book_Contact_Count:</b> { !!user.boxmobiles[0].mobile.address_book_contact_count ? user.boxmobiles[0].mobile.address_book_contact_count : "Not Set" } </div>

                    <div style={{float:"left", marginLeft:'10px'}} > <AuditModeCheckBox
                       audit_mode={user.boxmobiles[0].mobile.audit_mode  === 1 ? true : false}
                       network_api = {setMobileAuditMode}
                       mobile_id = {user.boxmobiles[0].mobile.mobile_id} />
                    </div>
                    <div style={{float:"left", marginLeft:'10px'}} > <DisableContactBumperCheckBox
                       bumper_mode={user.boxmobiles[0].mobile.disable_user_contact_bumper  === 1 ? true : false}
                       network_api = {setUserContactBumper}
                       mobile_id = {user.boxmobiles[0].mobile.mobile_id} />
                    </div>
                    <div style={{clear:'both'}}></div>
                    <div>
                        <div style={{...userButtonStyle, float:'left'}} onClick={()=>this.toggleOptions()}>{optLabel}</div>
                        <div style={{...userButtonStyle, float:"right", width:'132px'}} onClick={(BMid)=>this.showExportContacts(user.boxmobiles[0].box_mobile_id)}>{expLabel}</div>
                        <div style={{...userButtonStyle, float:"right", width:'132px'}} onClick={(BMid)=>this.showImportContacts(user.boxmobiles[0].box_mobile_id)}>{impLabel}</div>
                        <div style={{clear:"both"}}></div>
                        <div style={{ borderRadius:"5px",margin:'3px', padding:'3px' ,backgroundColor:"black", color:"white", display: dispExp, position: 'initial'}} >
                            <div>Export the imp contacts for box_mobile_id = {user.boxmobiles[0].box_mobile_id}</div>
                            <div >
                                Export to Batch Name:  <input style={{color:'black'}} size="50" value={this.state.expName} onChange={(e)=>this.updateExpName(e)}/>
                                <br />
                                <button onClick={()=>this.exportContacts()} style={{color:'black'}}>Export Now</button><button style={{color:'black'}} onClick={()=>this.toggleExport()}>Cancel</button>
                                <br />
                                <p style={{color:"red"}}>{this.state.error}</p>
                                <p>{this.state.success}</p>
                            </div>
                        </div>
                        <div style={{ borderRadius:"5px",margin:'3px', padding:'3px' ,backgroundColor:"black", color:"white",  display: dispImp, position: 'initial'}} >
                            <div>Import the imp contacts to box_mobile_id = {user.boxmobiles[0].box_mobile_id}</div>
                            Select the set of contacts to import: {batchPicker}
                            <br />
                            <button onClick={()=>this.importContacts()} style={{color:'black'}}>Import Now</button><button style={{color:'black'}} onClick={()=>this.toggleImport()}>Cancel</button>
                            <p style={{color:"red"}}>{this.state.error}</p>
                            <p>{this.state.success}</p>
                        </div>
                        <div style={{ display: dispOptions, position: 'initial'}} >
                            <JSONPretty id="json-pretty" data={user.boxmobiles[0].Options} theme={JSONPrettyMon}></JSONPretty>
                        </div>
                    </div>
                </div>
        }

        return (
            <div> {userToShow} </div>
        );
    }
}
export {UserDetails};

