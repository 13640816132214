import React, {Component} from 'react';

import {logCallApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";
import IMPBoxContext from "../../../contexts/IMPBoxContext";
import ApiInfo from "../../Util/ApiInfo";


const {disposition, action, resolution} = require('../../../util/logCallEnums');

class LogCall extends Component {

    static contextType = IMPBoxContext;

    //Api Details
    api = "logCall";
    service = "IMPBox";
    id = "impbox_uuid";

    constructor(props) {
        super(props);
        console.log("In LogCall constructor");
        this.state = {
            "boxdatetime": "",
            "call_start_time": "",
            "call_end_time": "",
            "from_number": "",
            "normalized_number": "",
            "lookup_number": "",
            "from_name": "",
            "from_location": "",
            "impbox_id": "",
            "disposition": disposition.GL,
            "action": action.RING,
            "resolution": resolution.ANSWERED,
            "type": "CALL",

            "message_id": "",
            "started_outgoing_message": false,

            "line_was_picked_up": false,
            "rang_phone": false,
            "imp_hung_up": false,
            "was_urgent": false,
            "was_DND": false,
            "was_AAC": false,
            "was_answering_machine_on": false,

            "error": "",
            "details": {}
        };
    }

    pickDisposition(e) {
        this.setState({disposition: e.target.value});
    }

    pickAction(e) {
        this.setState({action: e.target.value});
    }

    pickResolution(e) {
        this.setState({resolution: e.target.value});
    }

    pickLookup(e) {
        this.setState({lookup_number: e.target.value});
    }

    pickLine(e) {
        let line_was_picked_up = false;
        if (e.target.value == "true") {
            line_was_picked_up = true;
        }
        console.log(`Setting line_was_picked_up = ${line_was_picked_up} `);
        this.setState({line_was_picked_up: line_was_picked_up});
    }

    pickStartMessage(e) {
        let start_outgoing_message = false;
        if (e.target.value == "true") {
            start_outgoing_message = true;
        }
        console.log(`Setting start_outgoing_message = ${start_outgoing_message} `);
        this.setState({start_outgoing_message: start_outgoing_message});
    }

    pickRangPhone(e) {
        let rang_phone = false;
        if (e.target.value == "true") {
            rang_phone = true;
        }
        console.log(`Setting rang_phone = ${rang_phone} `);
        this.setState({rang_phone: rang_phone});
    }

    pickHungUp(e) {
        let imp_hung_up = false;
        if (e.target.value == "true") {
            imp_hung_up = true;
        }
        console.log(`Setting imp_hung_up = ${imp_hung_up} `);
        this.setState({imp_hung_up: imp_hung_up});
    }

    pickUrgent(e) {
        let was_urgent = false;
        if (e.target.value == "true") {
            was_urgent = true;
        }
        console.log(`Setting was_urgent = ${was_urgent} `);
        this.setState({was_urgent: was_urgent});
    }

    pickDND(e) {
        let was_DND = false;
        if (e.target.value == "true") {
            was_DND = true;
        }
        console.log(`Setting was_DND = ${was_DND} `);
        this.setState({was_DND: was_DND});
    }

    pickAAC(e) {
        let was_AAC = false;
        if (e.target.value == "true") {
            was_AAC = true;
        }
        console.log(`Setting was_AAC = ${was_AAC} `);
        this.setState({was_AAC: was_AAC});
    }

    pickAnsweringMachine(e) {
        let was_answering_machine_on = false;
        if (e.target.value == "true") {
            was_answering_machine_on = true;
        }
        console.log(`Setting was_answering_machine_on = ${was_answering_machine_on} `);
        this.setState({was_answering_machine_on: was_answering_machine_on});
    }

    changeText(e, fld_id) {
        let val = e.target.value;
        let no = {};
        no[fld_id] = val;
        this.setState(no);
    }

    logCall(impbox_id) {

        this.setState({"details": {}});
        this.setState({"error": ""});


        let cl = {};

        cl.impbox_id = impbox_id;

        cl.boxdatetime = (new Date()).toISOString();
        cl.call_start_time = (new Date(Date.now() - 10234)).toISOString();
        cl.call_end_time = (new Date(Date.now() - 1806)).toISOString();

        cl.from_number = this.state.from_number;
        cl.normalized_number = this.state.normalized_number;
        cl.lookup_number = this.state.lookup_number;
        cl.from_name = this.state.from_name;
        cl.from_location = this.state.from_location;

        cl.disposition = this.state.disposition;
        cl.action = this.state.action;
        cl.resolution = this.state.resolution;
        cl.type = this.state.type;

        cl.line_was_picked_up = this.state.line_was_picked_up;
        cl.rang_phone = this.state.rang_phone;
        cl.imp_hung_up = this.state.imp_hung_up;
        cl.was_urgent = this.state.was_urgent;
        cl.was_DND = this.state.was_DND;
        cl.was_AAC = this.state.was_AAC;
        cl.was_answering_machine_on = this.state.was_answering_machine_on;

        cl.message_id = this.state.message_id;
        cl.started_outgoing_message = this.state.started_outgoing_message;

        cl.debug = true;

        if (!cl.message_id || cl.message_id === '') {
            delete cl.message_id;
        }

        if (!cl.from_number || cl.from_number === '') {
            delete cl.from_number;
        }

        if (!cl.normalized_number || cl.normalized_number === '') {
            delete cl.normalized_number;
        }

        if (!cl.lookup_number || cl.lookup_number === '') {
            delete cl.lookup_number;
        }

        if (!cl.from_location || cl.from_location === '') {
            delete cl.from_location;
        }

        if (cl.line_was_picked_up === '') {
            delete cl.line_was_picked_up;
        }

        if (cl.started_outgoing_message === '') {
            delete cl.started_outgoing_message;
        }

        if (cl.rang_phone === '') {
            delete cl.rang_phone;
        }

        if (cl.imp_hung_up === '') {
            delete cl.imp_hung_up;
        }

        if (cl.was_urgent === '') {
            delete cl.was_urgent;
        }

        if (cl.was_DND === '') {
            delete cl.was_DND;
        }

        if (cl.was_AAC === '') {
            delete cl.was_AAC;
        }

        if (cl.was_answering_machine_on === '') {
            delete cl.was_answering_machine_on;
        }


        logCallApi(cl, (returnDetails) => {

                console.log("API return from logCall " + JSON.stringify(returnDetails));

                let details = {
                    api_message: "API Completed Successfully.",
                    history_message: returnDetails.debug.history_message,
                    apn_message: returnDetails.debug.apn_message,
                    notification_message: returnDetails.debug.notification_message,
                    message_message: returnDetails.debug.message_message
                };
                this.setState({"details": details});

            },
            (error) => {

                console.log("ERROR in getUser =  " + error);
                this.setState({"error": error});
            });

    }

    setCall(type) {
        if (type === disposition.GL) {
            this.setState({

                "from_number": "+14084446722",
                "normalized_number": "+14084446722",
                "lookup_number": "RAW",
                "from_name": "GL Caller",
                "from_location": "Unknown",
                "impbox_id": "",
                "disposition": disposition.GL,
                "action": action.RING,
                "resolution": resolution.ANSWERED,
                "type": "CALL",
                "line_was_picked_up": false,
                "started_outgoing_message": false

            })
        } else if (type === disposition.RL) {
            this.setState({

                "from_number": "+15108761123",
                "normalized_number": "+15108761123",
                "lookup_number": "RAW",
                "from_name": "RL Caller",
                "from_location": "Berkeley, CA",
                "impbox_id": "",
                "disposition": disposition.RL,
                "action": action.TERMINATE,
                "resolution": resolution.UNANSWERED,
                "line_was_picked_up": false,
                "type": "CALL",
                "started_outgoing_message": false

            })
        } else if (type === disposition.UNKNOWN) {
            this.setState({

                "from_number": "+14156781215",
                "normalized_number": "+14156781215",
                "lookup_number": "NORM",
                "from_name": "UNK Caller",
                "from_location": "San Francisco, CA",
                "impbox_id": "",
                "disposition": disposition.UNKNOWN,
                "action": action.RING,
                "resolution": resolution.UNANSWERED,
                "type": "CALL",
                "line_was_picked_up": false,
                "message_id": "",
                "started_outgoing_message": false

            })
        } else if (type === disposition.UNASSIGNED) {
            this.setState({

                "from_number": "+14156781212",
                "normalized_number": "+14156781212",
                "lookup_number": "RAW",
                "from_name": "Unassigned Caller",
                "from_location": "San Francisco, CA",
                "impbox_id": "",
                "disposition": disposition.UNASSIGNED,
                "action": action.RING,
                "resolution": resolution.ANSWERED,
                "type": "CALL",
                "line_was_picked_up": false,
                "message_id": "",
                "started_outgoing_message": false

            })
        } else if (type === disposition.URGENT_CALL) {
            this.setState({

                "from_number": "+14156784000",
                "normalized_number": "+14156784000",
                "lookup_number": "NORM",
                "from_name": "Urgent Caller",
                "from_location": "San Francisco, CA",
                "impbox_id": "",
                "disposition": disposition.URGENT_CALL,
                "action": action.MESSAGE,
                "resolution": resolution.ANSWERED,
                "type": "CALL",
                "line_was_picked_up": false,
                "message_id": "",
                "started_outgoing_message": false,
                "was_urgent": true
            })
        }
    }

    render() {

        //Used to get the API Info
        let id = this.context.impbox_uuid;

        const layoutStyle = {
            margin: 20,
            padding: 10,
            border: '1px solid #DDD'
        };


        return (
            <div>


                <div style={{'fontSize': '10pt'}}>
                    <div>

                        <ImpCollapsible collapsed={true} header={'Log Call'}>

                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.details.api_message}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.details.history_message}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.details.notification_message}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.details.apn_message}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.details.message_message}</p></div>

                            <ApiInfo api={this.api} service={this.service} id={id}/>

                            <div style={layoutStyle}>

                                <div style={layoutStyle}>

                                    <div style={{'margin': '0 auto', 'width': '60%'}}>
                                        <div style={{'padding': '5px', 'float': 'left'}}>
                                            <button onClick={(type) => this.setCall(disposition.UNKNOWN)}>Unknown Caller
                                            </button>
                                        </div>
                                        <div style={{'padding': '5px', 'float': 'left'}}>
                                            <button onClick={(type) => this.setCall(disposition.RL)}>Redlist</button>
                                        </div>
                                        <div style={{'padding': '5px', 'float': 'left'}}>
                                            <button onClick={(type) => this.setCall(disposition.GL)}>GreenList</button>
                                        </div>
                                        <div style={{'padding': '5px', 'float': 'left'}}>
                                            <button
                                                onClick={(type) => this.setCall(disposition.UNASSIGNED)}>Unassigned
                                            </button>
                                        </div>
                                        <div style={{'padding': '5px', 'float': 'left'}}>
                                            <button onClick={(type) => this.setCall(disposition.URGENT_CALL)}>
                                                Urgent Call
                                            </button>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>
                                </div>

                                <div style={layoutStyle}>


                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>IMPBox ID:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'text'} value={this.context.impbox_id}
                                                   onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>API Time:</div>
                                        <div className={'fieldInput'}>
                                            <input readOnly type={'text'} value={(new Date()).toISOString()}
                                                   disabled={false}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Call Start Time:</div>
                                        <div className={'fieldInput'}>
                                            <input readOnly type={'text'}
                                                   value={(new Date(Date.now() - 10234)).toISOString()} disabled={false}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Call End Time:</div>
                                        <div className={'fieldInput'}>
                                            <input readOnly type={'text'}
                                                   value={(new Date(Date.now() - 1806)).toISOString()} disabled={false}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Type:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'text'} value={'CALL'} disabled={true} id={'type'}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                </div>

                                <div style={layoutStyle}>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>From Number:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'text'} value={this.state.from_number}
                                                   onChange={(event) => this.changeText(event, 'from_number')}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Normalized Number:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'text'} value={this.state.normalized_number}
                                                   onChange={(event) => this.changeText(event, 'normalized_number')}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Lookup Number:</div>
                                        <div className={'fieldInput'}>
                                            <select id={'lookup_number'} value={this.state.lookup_number}
                                                    onChange={this.pickLookup.bind(this)}>
                                                <option value={'RAW'}>RAW</option>
                                                <option value={'NORM'}>NORM</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>


                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>From Name:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'text'} value={this.state.from_name}
                                                   onChange={(event) => this.changeText(event, 'from_name')}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>From Location:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'text'} value={this.state.from_location}
                                                   onChange={(event) => this.changeText(event, 'from_location')}
                                                   size={'60'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>
                                </div>


                                <div style={layoutStyle}>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Message ID:</div>
                                        <div className={'fieldInput'}>
                                            <input type={'number'} value={this.state.message_id}
                                                   onChange={(event) => this.changeText(event, 'message_id')}
                                                   size={'5'}/>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Started Outgoing Message:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.started_outgoing_message}
                                                    onChange={this.pickStartMessage.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>
                                </div>

                                <div style={layoutStyle}>


                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Disposition:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.disposition}
                                                    onChange={this.pickDisposition.bind(this)}>
                                                <option value={disposition.GL}>GL</option>
                                                <option value={disposition.RL}>RL</option>
                                                <option value={disposition.UNKNOWN}>UNKNOWN</option>
                                                <option value={disposition.UNASSIGNED}>UNASSIGNED</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>


                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Action:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'action'} value={this.state.action}
                                                    onChange={this.pickAction.bind(this)}>
                                                <option value={action.RING}>RING</option>
                                                <option value={action.TERMINATE}>TERMINATE</option>
                                                <option value={action.MESSAGE}>MESSAGE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Resolution:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'resolution'} value={this.state.resolution}
                                                    onChange={this.pickResolution.bind(this)}>
                                                <option value={resolution.ANSWERED}>ANSWERED</option>
                                                <option value={resolution.UNANSWERED}>UNANSWERED</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Was_Urgent:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.was_urgent}
                                                    onChange={this.pickUrgent.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                </div>

                                <div style={layoutStyle}>


                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Line_Was_Picked_Up:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.line_was_picked_up}
                                                    onChange={this.pickLine.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>IMP_Hung_Up:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.imp_hung_up}
                                                    onChange={this.pickHungUp.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Rang_Phone:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.rang_phone}
                                                    onChange={this.pickRangPhone.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>





                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Was_DND:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.was_DND}
                                                    onChange={this.pickDND.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Was_AAC:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.was_AAC}
                                                    onChange={this.pickAAC.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>


                                    <div className={'fieldHolder'}>
                                        <div className={'fieldLabel'}>Answering_Machine_On:</div>
                                        <div className={'fieldInput'}>

                                            <select id={'disposition'} value={this.state.was_answering_machine_on}
                                                    onChange={this.pickAnsweringMachine.bind(this)}>
                                                <option value={"true"}>TRUE</option>
                                                <option value={"false"}>FALSE</option>
                                            </select>
                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>

                                </div>

                                <button onClick={() => this.logCall(this.context.impbox_id)}>Log Call</button>
                            </div>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}

export {LogCall};
