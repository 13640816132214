import React, {Component} from 'react';
import {getAppAccessTokenApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible"

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class AuthenticateAsAppUser extends Component {

    constructor(props) {
        super(props);
        console.log("In AuthenticateAsAppUser constructor");
        this.state = {
            "error": "",
            "access_token": null,
            "access_success": null,
            "username": null
        };
    }

    changeText(e, fld_id) {

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        this.setState(no);
    }

    authenticateAsAppUser() {

        this.setState({"access_token": null});
        this.setState({"error": null});
        this.setState({"access_success": null});


        let cl = {};
        cl.username = this.state.username;

        getAppAccessTokenApi(cl, (returnDetails) => {

                console.log("API return from getAppAccessTokenApi " + JSON.stringify(returnDetails));

                this.setState({"access_token": returnDetails.jwt});

                console.log(`Adding to  local storage: ${returnDetails.Account.JWT} ${returnDetails.username}`)
                // Add to  Session.
                localStorage.setItem('app_access_token', returnDetails.Account.JWT);
                localStorage.setItem('app_username', returnDetails.Account.username);
                localStorage.setItem('app_user_id', returnDetails.Account.user_id);

                if (returnDetails.success === true) {
                    this.setState({"access_success": "Get Refresh Token: Successfull Call"});
                } else {
                    this.setState({"access_success": "Get Refresh Token: UnSuccessfull Call"});
                }

            },
            (error) => {
                console.log("ERROR in getAppAccessTokenApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        let auth_data = <div></div>;

        if (this.state.refresh_token) {
            let user_data = {
                access_token: this.state.access_token,
                username: this.state.username
            }
            auth_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={user_data} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Authenticate As App User'}>


                                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.access_success}</p></div>

                            <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Username</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.username}
                                               onChange={(event, fld_id) => this.changeText(event, 'username')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <button onClick={() => this.authenticateAsAppUser()}>Authenticate AS App User</button>


                            {auth_data}


                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {AuthenticateAsAppUser};

