import React, {Component} from 'react';
import {getAppVersions, updateAppVersion} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";
import {withAlert} from 'react-alert'


class AppVersions extends Component {

    constructor(props) {
        super(props);
        console.log("In AppVersions constructor");
        this.state = {
            "error": null,
            "details": null,
            "appversions": []
        };
    }

    changeTextOld(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));

        this.setState(no);

        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }

    changeText(e, appVersionIndex) {


        let new_app_version = e.target.value;

        console.log(`Changing AppVersion @ index ${appVersionIndex} to ${new_app_version} `);

        // 1. Make a shallow copy of the items
        let appversions = [...this.state.appversions];
        // 2. Make a shallow copy of the item you want to mutate
        let appversion = {...appversions[appVersionIndex]};
        // 3. Replace the property you're intested in
        appversion.app_version = new_app_version;
        // 4. Put it back into our array. N.B. we *are* mutating the array here,
        //    but that's why we made a copy first
        appversions[appVersionIndex] = appversion;
        // 5. Set the state to our new copy
        this.setState({appversions});


    }

    componentDidMount() {
        console.log("AppVersions did mount");
        this.refreshAppVersions();
    }


    updateSingleAppVersion(app_version_index) {
        console.log(`Calling updateAppVersions for ${app_version_index} `);

        // Get AppVersion from State at index id

        let appVersionToUpdate  = { "app_version" :  this.state.appversions[app_version_index]};

        updateAppVersion(appVersionToUpdate, returnDetails => {

                console.log("API return from updateAppVersion " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    appversion: returnDetails.app_version,
                };
                this.props.alert.success(`Updated version to ${returnDetails.app_version.app_version}`);

                //this.setState({"appversions": details.appversions});

            },
            (error) => {

                console.log("ERROR in getAppVersions =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
                this.props.alert.error(errStr);

            });




    }


    refreshAppVersions() {

        this.setState({"details": {}});
        this.setState({"error": ""});

        getAppVersions(returnDetails => {

                console.log("API return from getAppVersions " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    appversions: returnDetails.appversions,
                };
                this.setState({"appversions": details.appversions});

            },
            (error) => {

                console.log("ERROR in getAppVersions =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
            });

    }

    render() {

        let success_message, error_message, appVersToShow = [];
        let appVersionList = this.state.appversions;

        if (!!this.state.error) {
            appVersToShow.push(<div><p style={{color: 'red'}}>{this.state.error}</p></div>)
        } else if (!appVersionList || appVersionList.length === 0) {
            appVersToShow.push(<div> No App Versions Retrieved.</div>)
        } else {

            var header =
                <div key={"user_head"} className={'headerRow'}>
                    <div style={{'width': '200px'}} className={'headerItem'}>Device System</div>
                    <div style={{'width': '200px'}} className={'headerItem'}>Action</div>
                    <div style={{'width': '200px'}} className={'headerItem'}>Version</div>
                    {/*<div style={{'width': '100px'}} className={'listItem'}>*/}
                    {/*    <button onClick={() => this.updateAppVersions(ch.contexthelp_id)}>Update</button>*/}
                    {/*</div>*/}
                    <div className={'headerEnd'}></div>
                </div>

            appVersToShow.push(header);

            for (let appVersionIndex in appVersionList) {

                let thisVersion = <div key={appVersionList[appVersionIndex].app_version_id} className={'listRow'}>

                    <div style={{'width': '200px', 'minHeight': '2PX'}} className={'listItem'}>{appVersionList[appVersionIndex].device_system_name}</div>
                    <div style={{'width': '200px', 'minHeight': '2PX'}} className={'listItem'}>{appVersionList[appVersionIndex].action}</div>
                    {/*<div style={{'width': '200px', 'minHeight': '2PX'}} className={'listItem'}>{appVersionList[appVersionIndex].app_version}</div>*/}
                        <div className={'fieldInput'}>
                            <input type={'text'} value={appVersionList[appVersionIndex].app_version}
                                   onChange={(event, fld_id) => this.changeText(event, appVersionIndex)}
                                   size={'10'}/>
                        </div>
                    <div style={{'width': '70px'}} className={'listItem'}>
                        <button onClick={() => this.updateSingleAppVersion(appVersionIndex)}>Update</button>
                    </div>

                    <div className={'listEnd'}></div>

                </div>

                appVersToShow.push(thisVersion);
            }

        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'App Version Management'}>
                            {appVersToShow}
                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}

export default withAlert()(AppVersions);


