import React, {Component} from 'react';

import JSONInput from 'react-json-editor-ajrm';
import {v4 as uuidv4} from 'uuid';
import {APNLogs} from "./APNLogs";
import {ImpCollapsible} from "../Util/ImpCollapsible";
import {FCMForm} from "./FCMForm";
import {APNForm} from "./APNForm";


class PNAdmin extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing PNAdmin")

        let defAPNState = {
            "aps": {"content-available": 1},
            "details": {
                "message_type": "SILENT_CONTACT_UPDATE",
                "impbox_id": 44,
                "apn_id": uuidv4()
            }
        };

        let defFCMState = {
            "notification": {"body": "Sample message for Android endpoints", "title": "TitleTest"},
            "data": {"key": "value"}
        };

        this.state = {};
        this.state.error1 = "";
        this.state.error2 = "";
        this.state.response1 = "";
        this.state.response2 = "";
        this.state.apnJSON = defAPNState;
        this.state.showFCM = false;
        this.state.showAPN = false;
        //this.state.sns_android_arn = "";
    }

    componentDidUpdate(prevProps) {

        console.log(`PNForm: componentDidUpdate: this.props.userContext  ${this.props.userContext}`);

        if (this.props.userContext !== prevProps.userContext) {

            let uc = this.props.userContext;

            if (!!uc && uc.user) {

                try {
                    let defMobileToken = "";
                    let defMobileNotType = "";
                    let defMobileNotArn = "";
                    let username = "";
                    console.log("USER = " + JSON.stringify(uc.user))
                    defMobileToken = uc.user.Mobiles[0].notification_token;
                    defMobileNotType = uc.user.Mobiles[0].notification_type;
                    defMobileNotArn = uc.user.Mobiles[0].notification_sns_arn;
                    username = uc.user.PII.email;
                    this.setState({"username": username});
                    this.setState({"device_token": defMobileToken});
                    this.setState({"notification_type": defMobileNotType});
                    this.setState({"notification_sns_arn": defMobileNotArn});
                    console.log("User: " + uc.email + " Token = " + defMobileToken);

                    if (!!defMobileNotType ) {
                        if (defMobileNotType === "FCM"){
                            console.log(`PNAdmin: UnHiding FCM`);
                            this.setState({"showFCM": true});
                            this.setState({"showAPN": false});
                        }
                        if (defMobileNotType == "APN" || defMobileNotType == "APN_SANDBOX" ){
                            console.log(`PNAdmin: UnHiding APN`);
                            this.setState({"showAPN": true});
                            this.setState({"showFCM": false});
                        }
                    }
                } catch (e) {
                    console.error("No notification_token found for user " + uc.user.PII.email + " Error: " + e.message)
                    this.setState({"device_token": "No Notification Token for this user"});
                }
            }
        }
    }

    componentWillReceivePropsX(nextProps) {

        console.log(`PNAdmin: componentWillReceiveProps: nextProps.userContext  ${nextProps.userContext}`);

        let uc = nextProps.userContext;

        if (!!uc && uc.user) {
            try {
                let defMobileToken = "";
                let defMobileNotType = "";
                let defMobileNotArn = "";
                let username = "";
                console.log("USER = " + JSON.stringify(uc.user))
                defMobileToken = uc.user.Mobiles[0].notification_token;
                defMobileNotType = uc.user.Mobiles[0].notification_type;
                defMobileNotArn = uc.user.Mobiles[0].notification_sns_arn;
                username = uc.user.PII.email;
                this.setState({"username": username});
                this.setState({"device_token": defMobileToken});
                this.setState({"notification_type": defMobileNotType});
                this.setState({"notification_sns_arn": defMobileNotArn});
                console.log("User: " + uc.email + " Token = " + defMobileToken);

                if (!!defMobileNotType ) {
                    if (defMobileNotType === "FCM"){
                        console.log(`PNAdmin: UnHiding FCM`);
                        this.setState({"showFCM": true});
                        this.setState({"showAPN": false});
                    }
                    if (defMobileNotType == "APN"){
                        console.log(`PNAdmin: UnHiding APN`);
                        this.setState({"showAPN": true});
                        this.setState({"showFCM": false});
                    }
                }
            } catch (e) {
                console.error("No notification_token found for user " + uc.user.PII.email + " Error: " + e.message)
                this.setState({"device_token": "No Notification Token for this user"});
            }
        }
    }

    render() {


        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        let banner = <div> Pick a User Context to send Push Notifications.</div>

        if (!!this.state.username){
            console.log(`Rendering PNAdmin with showFCM ${this.state.showFCM} showAPN ${this.state.showAPN}   `);
            banner = <div>
                <p><b>Username</b>: {this.state.username}</p>
                <p><b>Notification type</b>: {this.state.notification_type}</p>
                <p><b>Device Token</b>: {this.state.device_token}</p>
                <p><b>SNS ARN</b>: {this.state.notification_sns_arn}</p>
            </div>

        }


        return (

            <div style={layoutStyle}>
                {banner}

                {this.state.showFCM && <FCMForm userContext={this.props.userContext}/>}
                {this.state.showAPN && <APNForm userContext={this.props.userContext}/>}

                <APNLogs userContext={this.props.userContext}/>

                </div>

        );
    }

}
export default PNAdmin;
