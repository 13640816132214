import React, {Component} from 'react';

class HomeMain extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing HomeMain")
        this.state= {"error" : ""};
        this.state= {"stats" : {}};
    }

    componentDidMount() {
        console.log("HomeMain did mount");
    }


    render() {
        return (
            <div className={'document'}>
                <div></div>
                <div className={'heading'}>Core KPIS</div>

                <div className={'paragraph'}>Firstly Install the iOS App via TestFlight. You will receive an email with a link to get you started.</div>

                <iframe style={{'width':'100%', 'height':'500px'}} src="https://docs.google.com/spreadsheets/d/14yMaG-KcDMaY8aAu1hDD1tD8Kqz1he7YK0_fSEmasqM/edit?usp=sharing?&amp;rm=minimal&amp;single=true&amp;"></iframe>


            </div>
        );
    }
}
export default HomeMain;
