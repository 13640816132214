import React, {Component} from 'react';
import {notificationRead, createContactAndRemoveNotification} from '../../util/apiServiceUtil';
import {ImpCollapsible} from "../Util/ImpCollapsible";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class NotificationsList extends Component {

    constructor(props) {
        super(props);
        console.log("In NL constructor");
    }

    getRowIcon(type) {
        if (type === 'GL') {
            return <span style={{'color': 'green'}} className="glyphicon glyphicon-ok-sign" aria-hidden="true"></span>;
        } else if (type === 'RL') {
            return <span style={{'color': 'red'}} className="glyphicon glyphicon-remove-sign"
                         aria-hidden="true"></span>;
        } else if (type === 'UNKNOWN') {
            return <span style={{'color': 'orange'}} className="glyphicon glyphicon-question-sign"
                         aria-hidden="true"></span>;
        }
    }

    getMsgIcon(msg_id) {
        if (msg_id) {
            return <span style={{'color': 'green'}} className="glyphicon glyphicon-phone-alt"
                         aria-hidden="true"></span>;
        }
    }

    assignNotification(notDetails, list) {
        console.log("assigning " + notDetails.notification_id + " to list " + list);

        let contactToSend = {};
        let dataToSend = {};
        let box_mobile_id = notDetails.box_mobile_id;

        if (!!notDetails.Notification_Metadata.contact_to_update) {
            contactToSend.contact_id = notDetails.Notification_Metadata.contact_to_update;
            contactToSend.list = list;

        } else {
            contactToSend.contact_id = "new_contact_" + notDetails.notification_id;
            contactToSend.list = list;
            contactToSend.phonenumbers = [];
            contactToSend.first_name = notDetails.Notification_Metadata.callerName;

            let numberToSend = {}
            numberToSend.label = notDetails.Notification_Metadata.callerName;
            numberToSend.phone_number = notDetails.History.from_number;

            contactToSend.phonenumbers.push(numberToSend)

        }
        contactToSend.box_mobile_id = box_mobile_id;

        dataToSend.contact = contactToSend;
        dataToSend.notification_id = notDetails.notification_id;
        createContactAndRemoveNotification(dataToSend, (resp) => {
                console.log("got back from server" + JSON.stringify(resp));
                this.props.onRefresh();
            },
            (error) => {

                console.log("ERROR in createContactAndRemoveNotification =  " + error);
                this.setState({"error": error});
            })
    }


    openNotification(notification_id) {
        notificationRead(notification_id,
            (flag) => {

                this.props.onRefresh();
            },
            (error) => {

                console.log("ERROR in getAllUsers =  " + error);
                this.setState({"error": error});
            })

        alert("open notification - " + notification_id);

    }

    render() {

        let notButton = {
            float: "left",
            borderRadius: "3px",
            margin: "5px",
            padding: "3px"
        }

        let nList = this.props.notifications;

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let notificationsToShow = [];

        // build list header
        let headers = <div key={'notification_header'} className={'headerRow'}>
            <div style={{'width': '20px', 'height': '10px'}} className={'listHeader'}></div>
            <div style={{'width': '300px'}} className={'listHeader'}>Subject</div>
            <div style={{'width': '400px'}} className={'listHeader'}>Message</div>
            <div style={{'width': '20px', 'height': '10px'}} className={'listHeader'}></div>
            <div className={'listEnd'}></div>
        </div>

        notificationsToShow.push(headers);

        if (nList === undefined || nList.length === 0) {
            return <div>No Notifications for this User.</div>
        }

        for (let notification of nList) {
            let thisN;

            let rowIcon = this.getRowIcon(notification.type);
            let msgIcon = this.getMsgIcon(notification.message_id);

            let fw = 'normal';
            let bg = 'white'
            if (notification.read === 0) {
                fw = 'bold';
                bg = 'orange';
            }

            let notDetails = {}

            notDetails.notification_id = notification.notification_id;
            notDetails.box_mobile_id = notification.box_mobile_id;
            notDetails.History = notification.History;
            notDetails.Notification_Metadata = notification.metadata;

            thisN = <div style={{'fontWeight': fw, 'background': bg}} key={notification.notification_id}
                         className={'listRow'}>

                <div style={{'width': '20px'}} className={'listItem'}>{rowIcon}</div>
                <div style={{'width': '300px'}} className={'listItem'}> {notification.subject}</div>
                <div style={{'width': '400px'}} className={'listItem'}> {notification.message} Call
                    Log: {notification.history_id}</div>
                <div style={{'width': '20px'}} className={'listItem'}>{msgIcon}</div>


                <div style={{float: "none"}}>&nbsp;</div>

                <div style={{clear: "both", marginLeft: "auto", width: "60%"}}>
                    <div style={{backgroundColor: "#f30e0e", ...notButton}}
                         onClick={(nd) => this.assignNotification(notDetails, "RED")}> RedList
                    </div>
                    <div style={{backgroundColor: "#17f314", ...notButton}}
                         onClick={(nd) => this.assignNotification(notDetails, "GREEN")}> GreenList
                    </div>
                    <div style={{backgroundColor: "#cecece", ...notButton}}
                         onClick={(nd) => this.assignNotification(notDetails, "UNASSIGNED")}> GrayList
                    </div>


                </div>


                <ImpCollapsible style={{float: 'none'}} collapsed={true} expandText="Details" header={''}>

                    <div style={{
                        'width': '700px',
                        'marginLeft': 50,
                        'textAlign': 'left',
                        'margin': '50',
                        'padding': 30,
                        "border": '0px solid #DDD'
                    }}>
                        <JSONPretty style={{'width': '700px'}} id="json-pretty" data={notDetails}
                                    theme={JSONPrettyMon}></JSONPretty>
                    </div>


                </ImpCollapsible>
                <div className={'listEnd'}></div>
            </div>

            notificationsToShow.push(thisN);
        }

        console.log(`notificationsToShow = ${notificationsToShow.length}`);

        return (
            <div style={layoutStyle}>

                <div> Notifications in DB</div>
                <div style={layoutStyle}>
                    {notificationsToShow}
                </div>
                <div>
                    <button style={{'display': 'none'}} name="Refresh Notifications"
                            onClick={this.props.onRefresh}>Refresh Notifications
                    </button>
                </div>
            </div>
        );
    }
}

export default NotificationsList;
