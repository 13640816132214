import React, {Component} from 'react';
const moment = require('moment');


class DateRangePicker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date()
        };
    }

    setRange(e){

        let ranges = this.getRange();
        let rsel = e.target.value;

        let fromDate = ranges[rsel][0].utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        let toDate = ranges[rsel][1].utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

        //alert(fromDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')+" - "+toDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));

        this.props.onRangeChange(fromDate,toDate)


}

    getRange() {


      return  {Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment()
                .subtract(1, "month")
                .startOf("month"),
            moment()
                .subtract(1, "month")
                .endOf("month")
        ],
        "This Year": [
            moment()
                .startOf("year"),
            moment()
                .endOf("year")
        ],
        "Last Year": [
            moment()
                .subtract(1, "year")
                .startOf("year"),
            moment()
                .subtract(1, "year")
                .endOf("year")
        ],
          "This and last 3 years": [
              moment()
                  .subtract(3, "year")
                  .startOf("year"),
              moment()
                  .endOf("year")
          ]}
    };

    render() {


        let options = [];
        let ranges = this.getRange();
        let thisOp;
        for (let rName in ranges)
        {

                thisOp = <option key={"k_"+rName} value={rName}>{rName}</option>

            options.push(thisOp)
        }


        return (
            <div>
                <select id="cars" defaultValue={"Last 7 Days"} name="cars" onChange={(e)=>this.setRange(e)}>
                    {options}
                </select>
            </div>
        )
    }
}
export {DateRangePicker}
