import React, {useState} from 'react'
import Checkbox from './Checkbox'

const DisableContactBumperCheckBox = (args) => {

    console.log(`DisableContactBumperCheckBox args =  ${JSON.stringify(args)}`);

    const [label, setLabel] = useState(`Bumper Mode: ${args["bumper_mode"]}`);
    const [checked, setChecked] = useState(args["bumper_mode"]);

    let setMobileBumperMode = args["network_api"];
    let mobile_id = args["mobile_id"];

    const updateMobileBumperMode = (checked) => {

        console.log("In updateMobileBumperMode with  ${}...")

        let payload = {};
        payload.mobile_id = mobile_id;
        payload.bumper_mode = checked;

        setMobileBumperMode(
            payload,
            (response) => {
                setLabel('Bumper Mode: ' + checked);
                setChecked(checked);
                return;

            },
            (error) => {
                console.log(`Error from setMobileBumperMode ${JSON.stringify(error)}`)
                setLabel('Bumper Mode Error: ' + error);
                return;
            })
    }


    const onChange = (event) => {
        const {value, name, checked} = event.target;
        updateMobileBumperMode(checked)
    };

    return (<div className=''>
        <div className=''>
            <div className=''>
                <div >
                    <Checkbox label={label} isSelected={checked} onCheckboxChange={onChange}></Checkbox>
                </div>
            </div>
        </div>
    </div>);

}
export default DisableContactBumperCheckBox;
