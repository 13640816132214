import React, {Component} from 'react';
import {ImpCollapsible} from "../Util/ImpCollapsible";
import {withAlert} from 'react-alert'
import {deleteAdminAccount} from "../../util/apiServiceUtil";

class AdminList extends Component {

    constructor(props) {
        super(props);
        console.log("In AdminList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false,
        }
    }

    deleteAdmin(account_id, username) {

        var answer = window.confirm("Are you sure you want to delete " + username + " ?");
        if (!answer) {
            return;
        }

        let delete_req = {account_id: account_id};

        console.log("Starting the Admin Deletion Process");
        deleteAdminAccount(delete_req, (returnDetails) => {

                console.log("API return from deleteAdmin " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    account: returnDetails.account,
                };
                this.setState({"details": details});
                this.props.alert.success(`Account ${details.account.account_id} deleted.`);
                this.props.refreshAdmins();

            },
            (error) => {

                console.log("ERROR in deleteUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR" + JSON.stringify(error));
            });
    }

    componentDidMount() {
        console.log("UserList did mount");
    }

    render() {

        let adminsToShow = [];
        let aList = this.props.adminAccounts;

        if (aList === undefined || aList.length === 0) {
            return <div></div>
        }

        let header = <div key={"user_head"} className={'headerRow'}>
            <div style={{'width': '100px'}} className={'headerItem'}>Account Id</div>
            <div style={{'width': '200px'}} className={'headerItem'}>UserName</div>
            <div style={{'width': '380px'}} className={'headerItem'}>Role</div>
            <div className={'headerEnd'}></div>
        </div>

        adminsToShow.push(header)

        for (let admin of aList) {


            let thisAdmin = <div key={admin.account_id} className={'listRow'}>

                <div style={{'width': '100px', 'minHeight': '2PX'}} className={'listItem'}>{admin.account_id}</div>
                <div style={{'width': '200px'}} className={'listItem'}>{admin.username}</div>
                <div style={{'width': '380px'}} className={'listItem'}>{admin.role}</div>
                <div style={{'width': '70px'}} className={'listItem'}>
                    <button onClick={() => this.deleteAdmin(admin.account_id, admin.username)}>Delete</button>
                </div>

                <div className={'listEnd'}></div>

            </div>

            adminsToShow.push(thisAdmin);
        }

        return (
            <div>
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {adminsToShow}
                </div>
            </div>
            </div>
        );
    }
}
export default withAlert()(AdminList);