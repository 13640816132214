import React, {Component} from 'react';
import {getSettingsApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class GetSettings extends Component {

    constructor(props) {
        super(props);
        console.log("In getSettings constructor");
        this.state = {
            "error": "",
            "settings": null
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));

        this.setState(no);

        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }

    getSettings() {

        this.setState({"settings": null});
        this.setState({"error": null});
        this.setState({"success": null});


        let cl = {};
        cl.impbox_id = this.state.impbox_id;

        getSettingsApi(cl, (returnDetails) => {

                console.log("API return from getSettingsApi " + JSON.stringify(returnDetails));

                this.setState({"settings": returnDetails.impbox_configuration});
                if (returnDetails.success === true) {
                    this.setState({"success": "Successfull Call"});
                } else {
                    this.setState({"success": "UnSuccessfull Call"});
                }

            },
            (error) => {

                console.log("ERROR in getSettingsApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        let settings_data = <div></div>;
        if (this.state.settings) {
            settings_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.settings} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>

                    <ImpCollapsible collapsed={true} header={'Get Settings'}>

                        <div>
                            <div className={'sectionBody'}>

                                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                                <div><p style={{color: 'green'}}>{this.state.success}</p></div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>IMPBox ID:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.impbox_id}
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <button onClick={() => this.getSettings()}>Get Settings</button>
                                {settings_data}

                            </div>

                        </div>

                    </ImpCollapsible>

                </div>

            </div>
        );
    }
}
export {GetSettings};

