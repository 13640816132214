import React, {Component} from 'react';
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import UserMobilesList from "../Users/UserMobilesList";
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



class InviteList extends Component {

    constructor(props) {
        super(props);
        console.log("In InviteList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false

        }
    }


    render() {

        let invitesToShow = [];
        let iList = this.props.invites;
        console.log("ILIST " + JSON.stringify(iList));

        if (iList === undefined || iList.length === 0) {
            return <div>No Invites</div>
        }

        let header = <div key={"user_head"} className={'headerRow'}>
            <div style={{'width': '280px'}} className={'headerItem'}>Invite UUID</div>
            <div style={{'width': '200px'}} className={'headerItem'}>Invitee Email</div>
            <div style={{'width': '80px'}} className={'headerItem'}>Invitor Id </div>
            <div style={{'width': '90px'}} className={'headerItem'}>Status</div>
            {/*<div style={{'width': '160px'}} className={'headerItem'}>Created</div>*/}
            <div className={'headerEnd'}></div>
        </div>

        invitesToShow.push(header)

        for (let invite of iList) {

            let thisInvite = <div key={invite.invite_id} className={'listRow'}>

                <div style={{'width': '280px', 'minHeight': '2PX'}} className={'listItem'}>{invite.invite_uuid}</div>
                <div style={{'width': '200px'}} className={'listItem'}>{invite.invitee_email}</div>
                <div style={{'width': '80px'}} className={'listItem'}>{invite.invitor}</div>
                <div style={{'width': '90px'}} className={'listItem'}>{invite.status}</div>
                {/*<div style={{'width': '160px'}} className={'listItem'}>{invite.created.substring(0, 19)}</div>*/}


                <ImpCollapsible collapsed={true} expandText="Details" header={''}>

                    <div style={{'width': '700px', 'marginLeft' : 50, 'textAlign' : 'left', 'margin': '50', 'padding': 30, "border": '0px solid #DDD'}}>
                        <JSONPretty id="json-pretty" data={invite} theme={JSONPrettyMon}></JSONPretty>
                    </div>

                </ImpCollapsible>


                <div className={'listEnd'}></div>

            </div>

            invitesToShow.push(thisInvite);
        }

        return (
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {invitesToShow}
                </div>
            </div>
        );
    }
}
export default withAlert()(InviteList);
