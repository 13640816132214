import React, {Component} from 'react';

import {settingAppliedApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";
var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



class SettingApplied extends Component {

    constructor(props) {
        super(props);
        console.log("In SettingApplied constructor");
        this.state = {
            "error": undefined,
            "details": undefined,
            "setting_name": undefined,
            "impbox_id": undefined,
            "state": undefined,
            "setting_details": undefined,
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));
        this.setState(no);
        console.log("changeText: after updating state :  " + this.state[fld_id]);
    }

    onChangeHandler = event => {

        console.log(event.target.files[0])

        this.setState({
            selectedFile: event.target.files[0]
        })

        console.log("FILE in state = " + JSON.stringify(this.state.selectedFile));


    }

    updateSetting() {

        this.setState({"details": {}});
        this.setState({"error": ""});


        let us = {};
        us.impbox_id = this.state.impbox_id;
        us.setting_name = this.state.setting_name;
        us.state = this.state.state;
        us.details = this.state.setting_details;


        console.log("SettingApplied Data = " + JSON.stringify(us));

        settingAppliedApi(us,  (returnDetails) => {

                console.log("API return from Update Setting " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    name_returned: returnDetails.setting_name,
                    updated_setting: returnDetails.updated_setting,
                };
                this.setState({"details": details});

            },
            (error) => {

                console.log("ERROR in Update Setting =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        let success_message;
        if ( this.state.details && !this.state.error  ) {
            success_message =
                <div>
                    <div><p style={{color: 'green'}}>Setting Updated: Name = {this.state.details.name_returned}</p></div>
                    <div>
                        <JSONPretty id="json-pretty" data={this.state.details.updated_setting} theme={JSONPrettyMon}></JSONPretty>
                    </div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Setting Applied'}>

                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            {success_message}

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>IMPBox Id:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox_id}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Setting Name:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.setting_name}
                                           onChange={(event, fld_id) => this.changeText(event, 'setting_name')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Current State:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.state}
                                           onChange={(event, fld_id) => this.changeText(event, 'state')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Settings Details:</div>
                                <div className={'fieldInput'}>
                                    <textarea id="text"
                                              name="text" rows="12" cols="60"
                                              value={this.state.setting_details}
                                              onChange={(event, fld_id) => this.changeText(event, 'setting_details')}
                                    />
                                    {/*<input type={'textarea'} value={this.state.setting_details}*/}
                                    {/*       onChange={(event, fld_id) => this.changeText(event, 'setting_details')}*/}
                                    {/*       size={'60'}/>*/}
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <button onClick={() => this.updateSetting()}>Appy Impbox Initiated Setting</button>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {SettingApplied};

