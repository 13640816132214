import React, {Component} from 'react';

import {pushAPNCustom, pushAPNGeneric, pushFCM} from "../../util/apiServiceUtil";
import JSONInput from 'react-json-editor-ajrm';
import {v4 as uuidv4} from 'uuid';
import {APNLogs} from "./APNLogs";
import {ImpCollapsible} from "../Util/ImpCollapsible";
import {FCMForm} from "./FCMForm";


class APNForm extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing APNForm")

        let defAPNState = {
            "aps": {"content-available": 1},
            "details": {
                "message_type": "SILENT_CONTACT_UPDATE",
                "impbox_id": 44,
                "apn_id": uuidv4()
            }
        };

        this.state = {};
        this.state.error1 = "";
        this.state.error2 = "";
        this.state.response1 = "";
        this.state.response2 = "";
        this.state.apnJSON = defAPNState;
    }

    componentDidMount() {
        const {userContext} = this.props;
        if (!!userContext) {
            let mobileToken = userContext.user.Mobiles[0].notification_token;
            let mobileNotType = userContext.user.Mobiles[0].notification_type;
            let mobileNotArn = userContext.user.Mobiles[0].notification_sns_arn;
            this.setState({"device_token": mobileToken});
            this.setState({"notification_type": mobileNotType});
            this.setState({"notification_sns_arn": mobileNotArn});
        }
    }

    componentDidUpdate(prevProps) {

        console.log(`APNForm: componentDidUpdate: this.props.userContext  ${this.props.userContext}`);

        if (this.props.userContext !== prevProps.userContext) {

            let uc = this.props.userContext;

            if (!!uc && !!uc.user && !!uc.user.Mobiles[0] ) {
                let mobileToken = uc.user.Mobiles[0].notification_token;
                let mobileNotType = uc.user.Mobiles[0].notification_type;
                let mobileNotArn = uc.user.Mobiles[0].notification_sns_arn;
                this.setState({"device_token": mobileToken});
                this.setState({"notification_type": mobileNotType});
                this.setState({"notification_sns_arn": mobileNotArn});
            } else {
                console.warn(`Incomplete User Context (missing notification_data).`);
            }

        }

    }

    pushCustomAPN(deviceToken, title, body, category, badge, sound, sns_apn_arn, apn_env) {
        console.log("pushMessageHandler  called...");

        let opts = {
            "deviceToken": deviceToken,
            "sns_apn_arn": sns_apn_arn,
            "title": title,
            "body": body,
            "category": category,
            "badge": badge,
            "sound": sound,
            "apn_env": apn_env
        };

        pushAPNCustom(
            opts,
            (pushresponse) => {
                console.log("PushResponse: " + pushresponse)
                this.setState({response1: pushresponse});
                this.setState({"error1": ""});
            },
            (error) => {
                console.log("PushError: " + error)
                this.setState({response1: ""});
                this.setState({"error1": error});
            },
        );
    }

    pushGenericAPN(deviceToken, apnObj, sns_apn_arn, apn_env) {

        let apnStr = JSON.stringify(apnObj)
        console.log("pushGenericAPN  called... with apn = " + apnStr);

        let opts = {
            "deviceToken": deviceToken,
            "sns_apn_arn": sns_apn_arn,
            "apnStr": apnStr,
            "apn_env": apn_env
        };

        pushAPNGeneric(
            opts,
            (pushresponse) => {
                console.log("PushResponse: " + pushresponse)
                this.setState({response2: pushresponse});
                this.setState({"error2": ""});
            },
            (error) => {
                console.log("PushError: " + error)
                this.setState({response2: ""});
                this.setState({"error2": error});
            },
        );
    }

    handleCustomAPN(e) {

        let deviceToken = this.refs.deviceToken.value;
        let sns_apn_arn = this.refs.sns_apn_arn.value;
        let title = this.refs.title.value;
        let body = this.refs.body.value;
        let category = this.refs.category.value;
        let badge = this.refs.badge.value;
        let sound = this.refs.sound.value;
        // let apn_env = this.refs.apn_env1.checked;
        // console.log("APN_ENV = " + apn_env);
        let uc = this.props.userContext;
        let  apn_env = "production";

        // if (apn_env) {
        //     apn_env = "staging";
        // } else {
        //     apn_env = "production";
        // }


        console.log("DeviceToken = " + deviceToken);
        this.pushCustomAPN(deviceToken, title, body, category, badge, sound, sns_apn_arn, apn_env);
        e.preventDefault();
    }

    handleGenericAPN(e) {


        let deviceToken = this.refs.deviceToken.value;
        let apnJSON = this.state.apnJSON;
        //let apn_env = this.refs.apn_env2.checked;
        let apn_env = "production";
        let sns_apn_arn = this.refs.sns_apn_arn.value;

        console.log("Generic apnJSON = " + apnJSON);
        //console.log("APN_ENV = " + apn_env);
        console.log("sns_apn_arn = " + sns_apn_arn);
        let uc = this.props.userContext;

        // if (apn_env) {
        //     apn_env = "staging";
        // } else {
        //     apn_env = "production";
        // }


        console.log("DeviceToken = " + deviceToken);
        this.pushGenericAPN(deviceToken, apnJSON, sns_apn_arn, apn_env);
        e.preventDefault();
    }

    changeJSON(e, fld_id) {

        console.log("changeJSON: updated field " + fld_id);
        let val = e.jsObject;
        this.setState({"apnJSON": val});
        console.log("changeJSON " + val);

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        let defAPN2 = this.state.apnJSON;

        let message1 = 'Custom Call Log APN Form';
        let message2 = 'Generic APN Form';


        return (


            <div style={layoutStyle}>

                <ImpCollapsible collapsed={false} header={'Generate APNs'}>

                    <div>Push test APN to registered iOS Mobile device.</div>

                    <div style={layoutStyle}>

                        <div style={{borderStyle: 'none', textAlign: 'center', padding: 20}}>

                            <div style={{
                                width: '560px',
                                border: '1px',
                                borderStyle: 'none',
                                borderRadius: '15px',
                                background: 'lightgrey',
                                margin: 'auto',
                                padding: 20,
                            }}>

                                <div><p>{message2}</p></div>
                                <div><p>Create Custom APN here.</p></div>
                                <div><p style={{color: 'red'}}>{this.state.error2}</p></div>
                                <div><p>{this.state.response2 !== "" && JSON.stringify(this.state.response2)}</p></div>

                                <form onSubmit={this.handleGenericAPN.bind(this)} autoComplete="on">

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>

                                        <div className={'fieldLabelLeft'}>Device Token</div>

                                        <input style={{width: '100%'}}
                                               type={'text'} value={this.state.device_token}
                                               ref="deviceToken"
                                               placeholder="deviceToken"
                                               autoComplete="on"
                                        />

                                        <div className={'fieldLabelLeft'}>SNS Endpoint ARN</div>

                                        <input style={{width: '100%'}}
                                               type={'text'} value={this.state.notification_sns_arn}
                                               ref="sns_apn_arn"
                                               placeholder="sns_apn_arn"
                                               autoComplete="on"
                                        />
                                    </div>

                                    <div className={'fieldHolder'}>
                                        {/*<div className={'fieldLabel'}>APN JSON</div>*/}
                                        <div className={'fieldInput'}>
                                            <div style={{textAlign: 'left'}}>

                                                <JSONInput
                                                    id='apn_imp_001'
                                                    placeholder={defAPN2}
                                                    //colors      = { {  string: "#DAA520"  }}
                                                    //locale      = { locale }
                                                    height='300px'
                                                    width='500px'
                                                    onChange={(event, fld_id) => this.changeJSON(event, 'apn_json')}
                                                />
                                            </div>

                                        </div>
                                        <div className={'fieldEnd'}></div>
                                    </div>


                                    {/*<div style={{*/}
                                    {/*    margin: 'auto',*/}
                                    {/*    padding: 5,*/}
                                    {/*}}>*/}
                                    {/*    <label htmlFor="apn_env_2">Use development APN</label>*/}
                                    {/*    <input style={{width: '100%'}}*/}
                                    {/*           id="apn_env_2"*/}
                                    {/*           type="checkbox"*/}
                                    {/*           ref="apn_env2"*/}
                                    {/*    />*/}
                                    {/*</div>*/}


                                    <div>
                                        <input
                                            type="submit"
                                            value="Send Message"/>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div style={{borderStyle: 'none', textAlign: 'center', padding: 20}}>

                            <div style={{
                                width: '360px',
                                border: '1px',
                                borderStyle: 'none',
                                borderRadius: '15px',
                                background: 'lightgrey',
                                margin: 'auto',
                                padding: 20,
                            }}>

                                <div><p>{message1}</p></div>
                                <div><p>This page sends test APNs (Alerts) to a registered Mobile Device. It does not
                                    create a Notification or Message in a user's account.</p></div>
                                <div><p style={{color: 'red'}}>{this.state.error1}</p></div>
                                <div><p>{this.state.response1 !== "" && JSON.stringify(this.state.response1)}</p></div>

                                <form onSubmit={this.handleCustomAPN.bind(this)} autoComplete="on">

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>
                                        <input style={{width: '100%'}}
                                               type={'text'} value={this.state.device_token}
                                               ref="deviceToken"
                                               placeholder="deviceToken"
                                               autoComplete="on"
                                        />
                                    </div>

                                    <input style={{width: '100%'}}
                                           type={'text'} value={this.state.notification_sns_arn}
                                           ref="sns_apn_arn"
                                           placeholder="sns_apn_arn"
                                           autoComplete="on"
                                    />

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>
                                        <input style={{width: '100%'}}
                                               type="text"
                                               ref="title"
                                               placeholder="enter title"
                                               autoComplete="off"/>
                                    </div>

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>
                                        <input style={{width: '100%'}}
                                               type="text"
                                               ref="body"
                                               placeholder="enter body"
                                               autoComplete="off"/>
                                    </div>

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>
                                        <input style={{width: '100%'}}
                                               type="text"
                                               ref="category"
                                               placeholder="CALLER_NOTIFICATION"
                                               autoComplete="off"/>
                                    </div>

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>
                                        <input style={{width: '100%'}}
                                               type="number"
                                               ref="badge"
                                               placeholder="badge"
                                               autoComplete="off"/>
                                    </div>

                                    <div style={{
                                        margin: 'auto',
                                        padding: 5,
                                    }}>
                                        <input style={{width: '100%'}}
                                               type="text"
                                               ref="sound"
                                               placeholder="default"
                                               autoComplete="off"/>
                                    </div>

                                    {/*<div style={{*/}
                                    {/*    margin: 'auto',*/}
                                    {/*    padding: 5,*/}
                                    {/*}}>*/}
                                    {/*    <label htmlFor="apn_env_1">Use development APN</label>*/}
                                    {/*    <input style={{width: '100%'}}*/}
                                    {/*           id="apn_env_1"*/}
                                    {/*           type="checkbox"*/}
                                    {/*           ref="apn_env1"*/}
                                    {/*    />*/}
                                    {/*</div>*/}


                                    <div>
                                        <input
                                            type="submit"
                                            value="Send Message"/>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </ImpCollapsible>
            </div>


        );
    }

}

export {APNForm};
