import React, {Component} from 'react';


class NotificationasForm extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing Notifications Form")
        this.state = {"error": ""};
        this.state = {"response": ""};
        this.state = {'messageType': 'RL'};
    }

    createNotification() {

        let notification = {};

        notification.subject = this.refs.subject.value;
        notification.message = this.refs.message.value;
        notification.type = this.state.messageType;
        notification.message_id = null;

        if (this.refs.hasMessage.checked) {
            notification.message_id = 1;
        }

        this.props.onFormSubmit(notification);

    }

    pickType(event) {
        this.setState({'messageType': event.target.value})
    }

    render() {

        return (
            <div className={'formHolder'}>
                Notificationas Form

                <div className={'fieldHolder'}>
                    <div className={'fieldLabel'}>Subject:</div>
                    <div className={'fieldInput'}>
                        <input type={'text'} ref={'subject'} size={'60'}/>
                    </div>
                    <div className={'fieldEnd'}></div>
                </div>

                <div className={'fieldHolder'}>
                    <div className={'fieldLabel'}>Message:</div>
                    <div className={'fieldInput'}>


                        <textarea type={'text'} ref={'message'} rows={'5'} cols={'60'}/>
                    </div>
                    <div className={'fieldEnd'}></div>
                </div>

                <div className={'fieldHolder'}>
                    <div style={{'height': '5px'}} className={'fieldLabel'}></div>
                    <div className={'fieldInput'}>

                        <div onChange={this.pickType.bind(this)}>
                            <div className={'radioField'}><input type="radio" value="RL" name="notType"
                                                                 defaultChecked={true}/> Red List
                                Caller
                            </div>
                            <div className={'radioField'}><input type="radio" value="GL" name="notType"/> Green List
                                Caller
                            </div>
                            <div className={'radioField'}><input type="radio" value="UNKNOWN" name="notType"/> Unknown
                                Caller
                            </div>

                        </div>

                    </div>
                    <div className={'fieldEnd'}></div>
                </div>

                <div className={'fieldHolder'}>
                    <div style={{'height': '5px'}} className={'fieldLabel'}></div>
                    <div className={'fieldInput'}>

                        <input type={'checkbox'} ref={'hasMessage'}/> Has Message
                    </div>
                    <div className={'fieldEnd'}></div>
                </div>

                <div className={'fieldHolder'}>
                    <div style={{'height': '5px'}} className={'fieldLabel'}></div>
                    <div className={'fieldInput'}>


                        <button className={''} onClick={() => this.createNotification()}>Create Notification</button>
                    </div>
                    <div className={'fieldEnd'}></div>
                </div>


            </div>
        );
    }
}
export default NotificationasForm;
