import React, {Component} from 'react';
import {withAlert} from 'react-alert'



class ErrorCodeList extends Component {

    constructor(props) {
        super(props);
        console.log("In ErrorCodeList constructor");
        this.state = {
            "error": null,
            "details": null,
            showHide: false

        }
    }

    clickCode(daCode)
    {

        console.log("in clickCode");
        this.props.localCodeFilter(daCode);
    }


    render() {

        const filterOptionStyle = {
            cursor:'pointer',
            float:'left',
            marginLeft:'2px',
            border: '2px solid #DDD',
            borderRadius:'15px',
            paddingLeft: '5px',
            paddingRight:'5px'
        };

        let errorCodesToShow = [];

        let eCodeList = this.props.errorCodes;
        let eCodeCounts = this.props.errorCodeCounts;
        let eCodeColors = this.props.errorCodeColors;
        let selectedCode = this.props.selectedCode;

        if (eCodeList === undefined || eCodeList.length === 0) {
            return <div>No Error Codes</div>
        }


        for (let eCode of eCodeList) {

            let daCode = eCode.substr(0, eCode.indexOf('~'));
            let daLevel = eCode.substr( eCode.indexOf('~')+1);

            let bStyle='outset';
            if(daCode===selectedCode)
            {
                bStyle = 'inset';
            }

            let date = new Date(eCode.created);

            let thisCode = <div style={{...filterOptionStyle, "background":eCodeColors[daLevel], borderStyle:bStyle}} key={daCode+daLevel} onClick={()=>this.clickCode(daCode)} >


                <div style={{}} >{daCode} ({eCodeCounts[daCode]})</div>


            </div>

            errorCodesToShow.push(thisCode);
        }


        return (
            <div>
                <div style={{'fontSize': '10pt'}}>
                    {errorCodesToShow}
                    <div style={{clear:'both'}}></div>
                </div>
            </div>
        );
    }
}
export default withAlert()(ErrorCodeList);
