import React, {Component} from 'react';
import {sendAdminEmail} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class AdminEmail extends Component {

    constructor(props) {
        super(props);
        console.log("In newMessage constructor");
        this.state = {
            "error": null,
            "details": null,
            "mail_to": "",
            "secret": ""
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));

        this.setState(no);

        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }


    adminEmail() {

        this.setState({"details": {}});
        this.setState({"error": ""});


        let ae = {};
        ae.email_to = this.state.email_to;
        ae.secret = this.state.secret;

        console.log("Admin Email Data = " + JSON.stringify(ae));

        sendAdminEmail(ae, returnDetails => {

                console.log("API return from sendAdminEmail " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    details: returnDetails.details,
                };
                this.setState({"details": details});

            },
            (error) => {

                console.log("ERROR in sendAdminEmail =  " + JSON.stringify(error));
                this.setState({"error": error.details});
            });

    }

    render() {

        let success_message;
        // if ( this.state.details ) {
        //     success_message =
        //        <div>
        //         <div><p style={{color: 'green'}}>Details = {this.state.details}</p></div>
        //        </div>
        // }

        if (this.state.details) {
            success_message =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.details} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }
        if (this.state.error ) {
            success_message =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.error} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Send Admin Email'}>

                            {/*<div><p style={{color: 'red'}}>{this.state.error}</p></div>*/}
                            {success_message}

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Email To:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.email_to}
                                           onChange={(event, fld_id) => this.changeText(event, 'email_to')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Secret</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.secret}
                                           onChange={(event, fld_id) => this.changeText(event, 'secret')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>



                            <button onClick={() => this.adminEmail()}>Send Email</button>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {AdminEmail};

