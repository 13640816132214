import React, {Component} from 'react';
import {getProducts, updateProduct, addProduct, deleteProduct} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";
import {withAlert} from 'react-alert'


class Products extends Component {

    constructor(props) {
        super(props);
        console.log("In Products constructor");
        this.state = {
            "error": null,
            "details": null,
            "products": []
        };
    }


    changeText(e, field, prodIndex) {

        let new_prod_data = e.target.value;

        console.log(`Changing ${field} @ index ${prodIndex} to ${new_prod_data} `);

        // 1. Make a shallow copy of the items
        let products = [...this.state.products];
        // 2. Make a shallow copy of the item you want to mutate
        console.log(`Looking for Product index ${prodIndex} `)
        let product = {...products[prodIndex]};
        //2.5. If it does not exist, Add it.
        if(!product || !products[prodIndex]) {
            console.log(`Product index ${prodIndex} does not exist, creating it. `)
            product = {};
            products.push(product);
        }
        // 3. Replace the property you're interested in
        product[field] = new_prod_data;
        // 4. Put it back into our array. N.B. we *are* mutating the array here,
        //    but that's why we made a copy first
        products[prodIndex] = product;
        // 5. Set the state to our new copy
        this.setState({products});

    }

    componentDidMount() {
        console.log("Products did mount");
        this.refreshProducts();
    }

    updateSingleProduct(product_index) {
        console.log(`Calling updateProduct for ${product_index} `);

        // Get AppVersion from State at index id

        let productToUpdate  = { "product" :  this.state.products[product_index]};

        updateProduct(productToUpdate, returnDetails => {

                console.log("API return from updateProduct " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    product: returnDetails.app_version,
                };

                this.refreshProducts();

                this.props.alert.success(`Updated Product ${returnDetails.product.product_id}`);

                //this.setState({"products": details.products});

            },
            (error) => {

                console.log("ERROR in getProducts =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
                this.props.alert.error(errStr);

            });




    }

    deleteSingleProduct(product_index) {

        console.log(`Calling deleteSingleProduct for ${product_index} `);

        let  p = this.state.products[product_index];

        var answer = window.confirm(`Are you sure you want to delete product ${ p.product_id }? `);
        if (!answer) {
            return;
        }

        // Get AppVersion from State at index id


        let productToDelete  = { "product" : {product_id: p.product_id }  };

        deleteProduct(productToDelete, returnDetails => {

                console.log("API return from updateProduct " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    product: returnDetails.app_version,
                };

                this.refreshProducts();

                this.props.alert.success(`Deleted Product ${returnDetails.product.product_id}`);


            },
            (error) => {

                console.log("ERROR in deleteProduct =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }

                this.setState({"error": errStr});
                this.props.alert.error(errStr);

            });




    }

    addSingleProduct(product_index) {
        console.log(`Calling addSingleProduct for ${product_index} `);

        // Get AppVersion from State at index id

        let productToAdd  = { "product" :  this.state.products[product_index]};

        addProduct(productToAdd, returnDetails => {

                console.log("API return from productToAdd " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    product: returnDetails.app_version,
                };
                this.refreshProducts();
                this.props.alert.success(`Product Created`);

            },
            (error) => {

                console.log("ERROR in addProduct =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
                this.props.alert.error(errStr);

            });




    }

    refreshProducts() {

        this.setState({"details": {}});
        this.setState({"error": ""});

        getProducts(returnDetails => {

                console.log("API return from getProducts " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    products: returnDetails.products,
                };
                this.setState({"products": details.products});

            },
            (error) => {

                console.log("ERROR in getProducts =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
            });

    }

    render() {

        let success_message, error_message, prodsToShow = [], prodToAdd, prodAddIndex = 0;
        let productList = this.state.products;


        if (!productList || productList.length === 0) {
            prodsToShow.push(<div> No Products Retrieved.</div>)
        } else {

            if (!!this.state.error) {
                prodsToShow.push(<div><p style={{color: 'red'}}>{this.state.error}</p></div>)
            }

            var header =
                <div key={"user_head"} className={'headerRow'}>
                    <div style={{'width': '25px'}} className={'headerItem'}>id</div>
                    <div style={{'width': '75px'}} className={'headerItem'}>platform</div>
                    <div style={{'width': '155px'}} className={'headerItem'}>product_id</div>
                    <div style={{'width': '325px'}} className={'headerItem'}>name</div>
                    <div style={{'width': '82px'}} className={'headerItem'}>months_free</div>
                    <div style={{'width': '90px'}} className={'headerItem'}>months_paid</div>
                    <div style={{'width': '80px'}} className={'headerItem'}>type</div>
                    <div style={{'width': '30px'}} className={'headerItem'}>plan_id</div>
                    <div className={'headerEnd'}></div>
                </div>



            prodsToShow.push(header);

            for (let prodIndex in productList) {

                if (!!productList[prodIndex].product_id) {

                    //This is the Count of Persisted Products
                    prodAddIndex +=1;

                    let thisVersion = <div key={productList[prodIndex].product_id} className={'listRow'}>

                        <div style={{'width': '25px', 'minHeight': '2PX'}}
                             className={'listItem'}>{productList[prodIndex].product_id}</div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].platform}
                                   onChange={(event, fld_id) => this.changeText(event, 'platform', prodIndex)}
                                   size={'10'}/>
                        </div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].platform_product_id}
                                   onChange={(event, fld_id) => this.changeText(event, 'platform_product_id', prodIndex)}
                                   size={'20'}/>
                        </div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].name}
                                   onChange={(event, fld_id) => this.changeText(event, 'name', prodIndex)}
                                   size={'45'}/>
                        </div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].months_free}
                                   onChange={(event, fld_id) => this.changeText(event, 'months_free', prodIndex)}
                                   size={'10'}/>
                        </div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].months_paid}
                                   onChange={(event, fld_id) => this.changeText(event, 'months_paid', prodIndex)}
                                   size={'10'}/>
                        </div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].type}
                                   onChange={(event, fld_id) => this.changeText(event, 'type', prodIndex)}
                                   size={'10'}/>
                        </div>
                        <div className={'fieldInput'}>
                            <input type={'text'} value={productList[prodIndex].plan_id}
                                   onChange={(event, fld_id) => this.changeText(event, 'plan_id', prodIndex)}
                                   size={'4'}/>
                        </div>
                        <div style={{'width': '70px'}} className={'listItem'}>
                            <button onClick={() => this.updateSingleProduct(prodIndex)}>Update</button>
                        </div>
                        <div style={{'width': '70px'}} className={'listItem'}>
                            <button onClick={() => this.deleteSingleProduct(prodIndex)}>Delete</button>
                        </div>

                        <div className={'listEnd'}></div>

                    </div>

                    prodsToShow.push(thisVersion);
                }
            }


            let prodToAdd = <div key={prodAddIndex + 1} className={'listRow'}>

                <div style={{'width': '25px', 'minHeight': '2PX'}} className={'listItem'}>+</div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'platform', prodAddIndex)}
                           size={'10'}/>
                </div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'platform_product_id', prodAddIndex)}
                           size={'20'}/>
                </div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'name', prodAddIndex)}
                           size={'45'}/>
                </div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'months_free', prodAddIndex)}
                           size={'10'}/>
                </div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'months_paid', prodAddIndex)}
                           size={'10'}/>
                </div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'type', prodAddIndex)}
                           size={'10'}/>
                </div>
                <div className={'fieldInput'}>
                    <input type={'text'}
                           onChange={(event, fld_id) => this.changeText(event,'plan_id', prodAddIndex)}
                           size={'4'}/>
                </div>
                <div style={{'width': '70px'}} className={'listItem'}>
                    <button onClick={() => this.addSingleProduct(prodAddIndex)}>Add</button>
                </div>

                <div className={'listEnd'}></div>

            </div>

            prodsToShow.push(prodToAdd);


        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Product Management'}>
                            {prodsToShow}
                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}

export default withAlert()(Products);


