import React, {Component} from 'react';
import {getFirmwareApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class GetFirmware extends Component {

    constructor(props) {
        super(props);
        console.log("In getFirmware constructor");
        this.state = {
            "error": "",
            "firmware_manifest": undefined
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));

        this.setState(no);

        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }

    getFirmware() {

        this.setState({"firmware_manifest": null});
        this.setState({"error": null});
        this.setState({"success": null});

        let fw = {};
        fw.impbox_id = this.state.impbox_id;
        fw.hardware_version = this.state.hardware_version;

        getFirmwareApi(fw, (returnDetails) => {

                console.log("API return from getFirmwareApi " + JSON.stringify(returnDetails));
                this.setState({"firmware_manifest": returnDetails.firmware_manifest});

                if (returnDetails.success === true) {
                    this.setState({"success": "Successfull Call"});
                } else {
                    this.setState({"success": "UnSuccessfull Call"});
                }

            },
            (error) => {

                console.log("ERROR in getFirmwareApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        let firmware_manifest_data = <div></div>;
        if (this.state.firmware_manifest) {
            firmware_manifest_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.firmware_manifest} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>

                    <ImpCollapsible collapsed={true} header={'Get Firmware'}>



                        <div>
                            <div className={'sectionBody'}>

                                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                                <div><p style={{color: 'green'}}>{this.state.success}</p></div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>IMPBox_ID:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.impbox_id}
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Hardware Version:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.hardware_version}
                                               onChange={(event, fld_id) => this.changeText(event, 'hardware_version')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <button onClick={() => this.getFirmware()}>Get Firmware</button>
                                {firmware_manifest_data}

                            </div>

                        </div>

                    </ImpCollapsible>

                </div>

            </div>
        );
    }
}
export {GetFirmware};

