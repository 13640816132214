import React, {Component} from 'react';
import InviteList from './InviteList';
import InviteCreate from './InviteCreate';
import {getAllInvites} from "../../util/apiServiceUtil";

class InviteAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            invites : []
        }
    }

    componentDidMount() {
        console.log("Invites Admin did mount");
        this.refreshInvites();
    }


    refreshInvites() {

        console.log("Calling refreshInvites from InviteAdmin")

        getAllInvites(
            (invites) => {
                console.log("invites - " + JSON.stringify(invites));
                this.setState({invites: invites.invites});
            },
            (error) => {

                console.log("ERROR in getAllInvites =  " + error);
                this.setState({"error": error});
            })

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>
                    <div style={layoutStyle}>
                        <InviteCreate refreshInvites={() => this.refreshInvites()}/>
                    </div>
                    <div style={layoutStyle}>
                        <InviteList
                            invites={this.state.invites}
                            refreshInvites={() => this.refreshInvites()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default InviteAdmin;
