import React, {Component} from 'react';


class IMPBoxPicker extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing IMPBoxPicker");
        this.state = {IMPBox_id: undefined};
    }

    pickIMPBox(e) {
        this.setState({IMPBox_id: e.target.value});
        this.props.onChange(e.target.value);
    }

    render() {

        let impboxes = this.props.impboxes.box_mobile;
        console.log("impBoxes = " + JSON.stringify(impboxes));
        let ibs = [<option key={"ibSelect_00"}
                           value={'00'}>Select IMPBox...</option>];

        if (impboxes.length === 0) {
            return <div></div>
        }

        for (let ib of impboxes) {
            let thisMob_name = <option key={"ibSelect_" + ib.IMPBox.impbox_id}
                                       value={ib.IMPBox.impbox_id}>{ib.nickname}</option>;
            ibs.push(thisMob_name);
        }

        return (
            <div>
                <select onChange={this.pickIMPBox.bind(this)}>
                    {ibs}
                </select>

            </div>
        );
    }
}
export default IMPBoxPicker;