import React, {useState} from 'react'
import Checkbox from './Checkbox'

const AuditModeCheckBox = (args) => {

    console.log(`AuditModeCheckBox args =  ${JSON.stringify(args)}`);

    const [label, setLabel] = useState(`Audit Mode: ${args["audit_mode"]}`);
    const [checked, setChecked] = useState(args["audit_mode"]);

    let setMobileAuditMode = args["network_api"];
    let mobile_id = args["mobile_id"];

    const updateMobileAuditMode = (checked) => {

        console.log("In updateMobileAuditMode with  ${}...")

        let payload = {};
        payload.mobile_id = mobile_id;
        payload.audit_mode = checked;

        setMobileAuditMode(
            payload,
            (response) => {
                setLabel('Audit Mode: ' + checked);
                setChecked(checked);
                return;

            },
            (error) => {
                console.log(`Error from setMobileAuditMode ${JSON.stringify(error)}`)
                setLabel('Audit Mode Error: ' + error);
                return;
            })
    }


    const onChange = (event) => {
        const {value, name, checked} = event.target;
        updateMobileAuditMode(checked)
    };

    return (<div className=''>
        <div className=''>
            <div className=''>
                <div >
                    <Checkbox label={label} isSelected={checked} onCheckboxChange={onChange}></Checkbox>
                </div>
            </div>
        </div>
    </div>);

}
export default AuditModeCheckBox;
