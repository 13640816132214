import React, {Component} from 'react';
//import {getContactListApi} from "../../../util/apiServiceUtil";
import {getPagedPhoneNumberListApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible"

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class GetContactList extends Component {

    constructor(props) {
        super(props);
        console.log("In newMessage constructor");
        this.state = {
            "error": "",
            "phone_number_list": null
        };
    }

    changeText(e, fld_id) {

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        this.setState(no);
    }

    getContactList() {

        this.setState({"phone_number_list": null});
        this.setState({"error": null});
        this.setState({"success": null});


        let cl = {};
        cl.impbox_id = this.state.impbox_id;

        getPagedPhoneNumberListApi(cl, (returnDetails) => {

                console.log("API return from getContactListApi " + JSON.stringify(returnDetails));

                this.setState({"phone_number_list": returnDetails.phone_number_list});
                if (returnDetails.success === true) {
                    this.setState({"success": "Successfull Call"});
                } else {
                    this.setState({"success": "UnSuccessfull Call"});
                }

            },
            (error) => {

                console.log("ERROR in getContactListApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        let contact_data = <div></div>;
        if (this.state.phone_number_list) {
            contact_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.phone_number_list} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Get Paged PhoneNumber List'}>


                                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                                <div><p style={{color: 'green'}}>{this.state.success}</p></div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>IMPBox ID:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.impbox_id}
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <button onClick={() => this.getContactList()}>Get ContactList</button>


                                {contact_data}


                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {GetContactList};

