import React, {Component, useContext} from "react";
import IMPBoxContext from "../../contexts/IMPBoxContext";


function  ApiInfo({api, service, id}){


    console.log (`api = ${JSON.stringify(api)}`);
    console.log (`Service = ${service}`);
    console.log (`id = ${id}`);

    let contextId = id;
    let authAs = !!contextId? contextId : "None";

    return (
        <div>
            <p style={{color: 'grey'}}>
                <b> {service} API</b>: {api}
                <b> Auth As</b>: {authAs}
            </p>
        </div>
    )

}

export default ApiInfo;