import React, {Component} from 'react';
import {getIMPBoxRefreshTokenApi, impBoxAuthenticateApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible"

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class AuthenticateAsIMPBox extends Component {

    constructor(props) {
        super(props);
        console.log("In AuthenticateAsIMPBox constructor");
        this.state = {
            "error": "",
            "refresh_token": null,
            "access": null,
            "impbox_id": null,
            "impbox_uuid": null
        };
    }

    changeText(e, fld_id) {

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        this.setState(no);
    }

    authenticateAsIMPBox() {

        this.setState({"refresh_token": null});
        this.setState({"access": null});
        this.setState({"impbox_id": null});
        this.setState({"impbox_uuid": null});
        this.setState({"error": null});
        this.setState({"refresh_success": null});
        this.setState({"access_success": null});


        let cl = {};
        cl.impbox_uuid = this.state.impbox_uuid;

        getIMPBoxRefreshTokenApi(cl, (returnDetails) => {

                console.log("API return from getIMPBoxRefreshTokenApi " + JSON.stringify(returnDetails));

                this.setState({"impbox_uuid": returnDetails.impbox_uuid});
                this.setState({"refresh_token": returnDetails.refresh_token});
                this.setState({"impbox_id": returnDetails.impbox_id});

                // Add to  Session.
                localStorage.setItem('impbox_refresh_token', returnDetails.refresh_token);
                localStorage.setItem('impbox_id', returnDetails.impbox_id);
                localStorage.setItem('impbox_uuid', returnDetails.impbox_uuid);

                if (returnDetails.success === true) {
                    this.setState({"refresh_success": "Get Refresh Token: Successfull Call"});
                } else {
                    this.setState({"refresh_success": "Get Refresh Token: UnSuccessfull Call"});
                }

                //Call impbox authenticate
                //https://itsmyphone.atlassian.net/wiki/spaces/DEV/pages/1623457797/authenticate+IMPBox


                let imp_auth = {};
                imp_auth.impbox_uuid = this.state.impbox_uuid;
                imp_auth.refresh_token = this.state.refresh_token;

                impBoxAuthenticateApi(imp_auth, (returnDetails2) => {

                        console.log("API return from impBoxAuthenticateApi " + JSON.stringify(returnDetails2));

                        this.setState({"access_token": returnDetails2.access_token});

                        // Add to  Session.
                        localStorage.setItem('impbox_access_token', returnDetails2.access_token);

                        if (returnDetails2.success === true) {
                            this.setState({"access_success": "Authenticate: Successfull Call"});
                        } else {
                            this.setState({"access_success": "Authenticate: UnSuccessfull Call"});
                        }

                    },
                    (error) => {
                        console.log("ERROR in impBoxAuthenticateApi =  " + JSON.stringify(error));
                        this.setState({"error": error});
                    });

            },
            (error) => {
                console.log("ERROR in getIMPBoxRefreshTokenApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {


        let auth_data = <div></div>;

        if (this.state.refresh_token) {
            let impbox_data = {
                refresh_token: this.state.refresh_token,
                access_token: this.state.access_token,
                impbox_uuid: this.state.impbox_uuid,
                impbox_id: this.state.impbox_id
            }
            auth_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={impbox_data} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Authenticate As IMPBox'}>


                                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.access_success}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.refresh_success}</p></div>

                            <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>IMPBox UUID</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.impbox_uuid}
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox_uuid')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <button onClick={() => this.authenticateAsIMPBox()}>Authenticate AS IMPBox</button>


                            {auth_data}


                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {AuthenticateAsIMPBox};

