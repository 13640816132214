import React, {Component} from 'react';

import {getAllSubscriptions, getProducts} from "../../util/apiServiceUtil";
import SubscriptionList from "./SubscriptionList";

class SubscriptionAdmin extends Component {

    constructor(props) {

        let plans = [
            {plan_id: 0, primary_plan: "--select--"},
            {plan_id: 1, primary_plan: "P Zero"},
            {plan_id: 2, primary_plan: "P X"},
            {plan_id: 3, primary_plan: "Annual Plan"},
            {plan_id: 4, primary_plan: "Monthly Plan"},
            {plan_id: 5, primary_plan: "Fixed Price"},
        ];
        super(props);
        this.state = {
            error: null,
            subscriptions: [],
            products: [],
            emailScope: "",
            idScope: "",
            planScope: "",
            plans: plans,
            showAll: false
        }
    }

    componentDidMount() {
        console.log("Invites Admin did mount");
        this.refreshSubscriptions();
        this.getAllProducts();
    }

    getAllProducts() {

        this.setState({"details": {}});
        this.setState({"error": ""});

        getProducts(returnDetails => {

                // console.log("API return from getProducts " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    products: returnDetails.products,
                };

                this.setState({"products": details.products});

            },
            (error) => {

                console.log("ERROR in getProducts =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
            });

    }


    refreshSubscriptions(emailScope, idScope, planScope, showAll) {

        console.log("Calling refreshSubscriptions from SubscriptionAdmin");

        let reqScope = {};

        if (showAll) {

        } else {

            console.log(`Scopes: emailScope ${emailScope} idScope ${idScope} planScope ${planScope}`)
            reqScope.emailScope = emailScope;
            reqScope.impboxIdScope = idScope;
            reqScope.planScope = planScope;
            if (!emailScope && !idScope && !planScope) {
                this.setState({subscriptions: []});
                return;
            }
        }


        getAllSubscriptions(reqScope,
            (subscriptions) => {
                // console.log("subscriptions - " + JSON.stringify(subscriptions));
                this.setState({subscriptions: subscriptions.subscriptions});
            },
            (error) => {

                console.log("ERROR in getAllSubscriptions =  " + error);
                this.setState({"error": error});
            })

    }

    changeEmailScope(e) {
        const newEmailScope = e.target.value;

        this.setState({emailScope: newEmailScope});
        this.refreshSubscriptions(newEmailScope, this.state.idScope, this.state.planScope, this.state.showAll);
    }

    changeIdScope(e) {
        const newIDScope = e.target.value;

        this.setState({idScope: newIDScope});
        this.refreshSubscriptions(this.state.emailScope, newIDScope, this.state.planScope, this.state.showAll);
    }

    changePlanScope(e) {
        let newPlanScope = e.target.value;
        if (newPlanScope == 0) {
            newPlanScope = "";
        }

        this.setState({planScope: newPlanScope});
        this.refreshSubscriptions(this.state.emailScope, this.state.idScope, newPlanScope, this.state.showAll);

    }

    changeShowAll() {
        let newVal = !this.state.showAll;

        this.setState({showAll: newVal});
        this.refreshSubscriptions(this.state.emailScope, this.state.idScope, this.state.planScope, newVal);
    }

    render() {

        const layoutStyle = {
            margin: 5,
            padding: 5,
            border: '1px solid #DDD'
        };
        let plans = this.state.plans;
        let plansToShow = [];
        for (let plan of plans) {
            let thisPlanShow = <option
                key={"planSelect_" + plan.plan_id}
                value={plan.plan_id}>{`${plan.primary_plan} `}
            </option>;

            plansToShow.push(thisPlanShow);
        }


        return (
            <div style={{width: "1000px", marginLeft: "15px"}}>
                <div style={layoutStyle}>
                    <div style={{backgroundColor: "#cecece"}}>

                        <div style={{float: "left", paddingLeft: "10px"}}>
                            Email: <input type={"text"} size={"40"} value={this.state.emailScope}
                                          onChange={(event) => this.changeEmailScope(event)}/>
                        </div>
                        <div style={{float: "left", paddingLeft: "10px"}}>
                            impbox ID: <input type={"text"} size={"5"} value={this.state.idScope}
                                              onChange={(event) => this.changeIdScope(event)}/>
                        </div>
                        <div style={{float: "left", paddingLeft: "10px"}}>
                            Plan:<select id="allPlans" value={this.state.planScope}
                                         onChange={(event, fld_id) => this.changePlanScope(event)}>
                            {plansToShow}
                        </select>
                        </div>
                        <div style={{float: "left", paddingLeft: "10px"}}>
                            Show All: <input type={"checkbox"} checked={this.state.showAll}
                                             onChange={() => this.changeShowAll()}/>
                        </div>
                    </div>
                    <div style={{clear: "BOTH"}}></div>
                    <div style={layoutStyle}>
                        <SubscriptionList
                            subscriptions={this.state.subscriptions}
                            products={this.state.products}
                            refreshSubscriptions={() => this.refreshSubscriptions(this.state.emailScope, this.state.idScope, this.state.planScope)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SubscriptionAdmin;
