import React, {Component} from 'react';
import ContactList from './ContactList';

var _ = require('lodash');


class MobileContactList extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing MobileAdmin")
        this.state = {};

    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.userContext !== prevProps.userContext) {
            this.setState({});
        }
    }


    render() {

        let contacts;

        if (this.props.contacts !== undefined) {
            contacts = this.props.contacts.contacts;
        } else {
            contacts = [];
        }

        return (
            <div>
                <ContactList contacts={contacts}/>
            </div>
        );
    }
}

export default MobileContactList;
