import React, {Component} from 'react';
import UserBoxMobilesList from './UserBoxMobilesList';



class UserMobilesList extends Component {

    constructor(props) {
        super(props);
        console.log("In UserList constructor");
    };


    render() {

        let mobilesToShow = [];

        let mList = this.props.mobiles;

        if (mList === undefined || mList.length === 0) {
            return <div></div>
        }
        for (let mobile of mList) {

            let thisMobile =
                <div style={{'paddingLeft': '20px'}} key={mobile.mobile_id} className={'listRow'}>


                    <div style={{'width': '30px', 'minHeight': '2PX', 'background': '#b8c9cc'}}
                         className={'listItem'}>{mobile.mobile_id}</div>
                    <div style={{'width': '150px', 'minHeight': '2PX', 'background': '#b8c9cc'}}
                         className={'listItem'}>{mobile.device_name}</div>
                    <div style={{'width': '150px', 'minHeight': '2PX', 'background': '#b8c9cc'}}
                         className={'listItem'}>{mobile.device_model}</div>
                    <div style={{'width': '50px', 'minHeight': '2PX', 'background': '#b8c9cc'}}
                         className={'listItem'}>{mobile.device_system_name}</div>
                    <div style={{'width': '50px', 'minHeight': '2PX', 'background': '#b8c9cc'}}
                         className={'listItem'}>{mobile.device_system_version}</div>

<br />

                    <div style={{'width': '300px', 'minHeight': '2PX'}} className={'listItem'}>
                        <div>IMP Boxes:</div>
                        <UserBoxMobilesList boxmobiles={mobile.box_mobiles}/>
                    </div>


                    <div className={'listEnd'}></div>
                </div>


            mobilesToShow.push(thisMobile);
        }


        return (
            <div>


                <div>
                    {mobilesToShow}
                </div>

            </div>
        );
    }
}
export default UserMobilesList;
