import React, {Component} from 'react';
import {registerUserWithDevice, registerIMPBox} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";

class UserList extends Component {

    constructor(props) {
        super(props);
        console.log("In UserCreate constructor");
        this.state = {
            "user": {
                "username": "",
                "password": "",
                "name": "",
                "marketing_agreement_acceptance": false,
                "eula_version_accepted": "0.5",
            },
            "device": {
                "device_model": "",
                "device_name": "",
                "device_system_name": "",
                "device_system_version": "",
                "device_uuid": "",
                "notification_token": "",
                "notification_type": ""
            },
            "impbox": {
                "impbox_uuid": "",
                "device_uuid": "",
                "nickname": "",
                "phone_number": "",
            },
            "error": "",
            "details": {}

        };
    }


    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        const target = e.target;
        const val = target.name === 'mkting_agree' ? target.checked : target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        console.log("changeText: updating state with  " + JSON.stringify(change));
        this.setState(change);
        console.log("changeText: after updating state :  " + this.state["user"]);

    }

    createNewUser() {
        console.log("Starting the User Creation Process");

        let userAndDevice = {
            "username": this.state.user.username,
            "password": this.state.user.password,
            "name": this.state.user.name,
            "marketing_agreement_acceptance": this.state.user.marketing_agreement_acceptance,
            "eula_version_accepted": this.state.user.eula_version_accepted,
            "deviceData": {
                "device_model": this.state.device.device_model,
                "device_name": this.state.device.device_name,
                "device_system_name": this.state.device.device_system_name,
                "device_system_version": this.state.device.device_system_version,
                "device_uuid": this.state.device.device_uuid,
                "notification_token": this.state.device.notification_token,
                "notification_type": this.state.device.notification_type
            }
        }

        registerUserWithDevice(userAndDevice, (returnDetails) => {

                console.log("API return from registerUserWithDevice " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    user: returnDetails.user
                };
                this.setState({"details": details});
                let success_message = "Created User: " + returnDetails.user.user_id;

                this.props.alert.success(success_message);
                this.props.refreshUsers();
            },
            (error) => {

                console.log("ERROR in createNewUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }

    registerImpBox() {

        console.log("Starting the Register ImpBox Process");

        console.log ("this.state.device " +  JSON.stringify( this.state.device ));

        let impBox = {
            "device_uuid": this.state.impbox.device_uuid,
            "impbox_uuid": this.state.impbox.impbox_uuid,
            "nickname": this.state.impbox.nickname,
            "phone_number": this.state.impbox.phone_number
        };

        console.log("Stuff: " + JSON.stringify(impBox));

        registerIMPBox(impBox, (returnDetails) => {

                console.log("API return from registerImpbox " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    impbox: returnDetails.impbox,
                    mobile: returnDetails.mobile,
                    box_mobile: returnDetails.box_mobile
                };


                this.setState({"details": details});
                let success_message = "Created IMPBox: " + returnDetails.impbox.impbox_id;
                this.props.alert.success(success_message);
                this.props.refreshUsers();

            },
            (error) => {

                console.log("ERROR in registerIMPBox  =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }

    render() {


        let success_message;
        if (this.state.details.impbox_id) {
            success_message =
                <div>
                    <p style={{color: 'green'}}>New IMPBox Created: impbox_id = {this.state.details.impbox_id}</p>
                </div>
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Create New User with Device'}>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>username:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.username}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.username')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>password:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.password}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.password')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.name}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>accept marketing:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'checkbox'} value={this.state.user.marketing_agreement_acceptance}
                                               name="mkting_agree"
                                               onChange={(event, fld_id) => this.changeText(event, 'user.marketing_agreement_acceptance')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>eula_version:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.eula_version_accepted}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.eula_version_accepted')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>
                            </div>

                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>device model:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.device.device_model}
                                               onChange={(event, fld_id) => this.changeText(event, 'device.device_model')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>device name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.device.device_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'device.device_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>


                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>device system name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.device.device_system_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'device.device_system_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>device system version:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.device.device_system_version}
                                               onChange={(event, fld_id) => this.changeText(event, 'device.device_system_version')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>device uuid:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.device.device_uuid}
                                               onChange={(event, fld_id) => this.changeText(event, 'device.device_uuid')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>notification token:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.device.notification_token}
                                               onChange={(event, fld_id) => this.changeText(event, 'device.notification_token')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>notification type:</div>
                                    <div className={'fieldInput'}>
                                        <select id={'notification_type'} value={this.state.device.notification_type}
                                                onChange={(event, fld_id) => this.changeText(event, 'device.notification_type')}>
                                            <option value={"APN"}>APN</option>
                                            <option value={"APN_SANDBOX"}>APN_SANDBOX</option>
                                            <option value={"FCM"}>FCM</option>
                                        </select>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>


                            </div>

                            <button onClick={() => this.createNewUser()}>Create User</button>
                        </div>


                        <div style={layoutStyle}>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>device uuid:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox.device_uuid}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox.device_uuid')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>impbox uuid:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox.impbox_uuid}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox.impbox_uuid')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>nickname:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox.nickname}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox.nickname')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>phone number:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox.phone_number}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox.phone_number')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                        </div>


                        <button onClick={() => this.registerImpBox()}>Register IMPBox</button>
                    </div>

                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(UserList);
