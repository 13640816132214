import React, {Component} from 'react';


import {uploadFirmwareApi} from "../../util/apiServiceUtil";
import {ImpCollapsible} from "../Util/ImpCollapsible";
import {withAlert} from "react-alert";


class FirmwareUpload extends Component {

    constructor(props) {
        super(props);
        console.log("In updateFirmware constructor");
        this.state = {
            "error": null,
            "details": null,
            "version": null,
            "environment": null,
            "selectedFile": null
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        this.setState(no);

    }
    changeEnvText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        if (val==='s' || val==='S')
        {
            val="stage";
        }else if (val==='d' || val==='D')
        {
            val="dev";
        }else if (val==='p' || val==='P')
        {
            val="prod";
        }else if (val==='l' || val==='L')
        {
            val="lab";
        }else {

            val="";
        }

        no[fld_id] = val;

        this.setState(no);

    }

    onChangeHandler = event => {

        console.log(event.target.files[0])

        this.setState({
            selectedFile: event.target.files[0]
        })

        console.log("FILE in state = " + JSON.stringify(this.state.selectedFile));


    }


    updateFirmware() {

        this.setState({"details": {}});
        this.setState({"error": ""});


        let um = {};
        um.version = this.state.version;
        um.environment = this.state.environment;
        um.debug = true;

        console.log("updateFirmware Data = " + JSON.stringify(um));
        console.log("updateFirmware File = " + this.state.selectedFile);

        uploadFirmwareApi(um, this.state.selectedFile, (returnDetails) => {

                console.log("API return from updateFirmware " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    firmware_id: returnDetails.firmware_id,
                    firmware_link: returnDetails.debug.firmware_link,
                    s3Firmware: returnDetails.debug.s3Firmware,
                    s3_status: returnDetails.debug.s3_status,
                };
                this.setState({"details": details});

                this.props.refreshFirmwares();

            },
            (error) => {

                console.log("ERROR in updateFirmware =  " + JSON.stringify(error));
                if(!!error && !!error.error){
                    error = error.error
                } else if (!!error && !!error.message){
                    error = error.message
                }
                this.setState({"error": error});
            });

    }

    render() {

        let success_message;
        if ( this.state.details && this.state.details.firmware_id) {
            success_message =
               <div>
                <div><p style={{color: 'green'}}>Firmware Created: Id = {this.state.details.firmware_id}</p></div>
                <div><p style={{color: 'green'}}>Message Link = {this.state.details.firmware_link}</p></div>
                <div><p style={{color: 'green'}}>S3 Link = {this.state.details.s3Firmware}</p></div>
                <div><p style={{color: 'green'}}>Status = {this.state.details.s3_status}</p></div>
               </div>
        }

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Upload Firmware'}>

                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            {success_message}

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Version:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.version}
                                           onChange={(event, fld_id) => this.changeText(event, 'version')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Environment:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.environment}
                                           onChange={(event, fld_id) => this.changeEnvText(event, 'environment')}
                                           size={'30'}/> You can only enter: lab, dev,stage or prod.
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Firmware File:</div>
                                <div className={'fieldInput'}>
                                    <input type="file" name="file" onChange={this.onChangeHandler}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <button onClick={() => this.updateFirmware()}>Upload Firmware</button>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export default (FirmwareUpload);

