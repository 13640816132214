import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible"
import {SettingsDetails} from "./SettingsDetails";
import {getSettingsApi, updateSettingApi} from "../../../util/apiServiceUtil";
import StandByModeCheckBox from "./StandByModeCheckBox";

class IMPBoxSettings extends Component {

    constructor(props) {
        super(props);
        console.log("In IMPBoxSettings constructor");
        this.state = {
            error: null,
            success: null,
            settings: null,
            owner_box_mobile: null,
            username: null
        };
    }


    refreshSettings(impbox_id) {

        console.log(`Refreshing IMPBoxSettings: Calling refreshSettings`);

        let cl = {};
        cl.impbox_id = impbox_id;

        getSettingsApi(cl, (returnDetails) => {
                let sb = returnDetails.impbox_configuration.settings["standby_mode"]
                console.log(`Refreshing IMPBoxSettings: Return from  getSettingsApi standby_mode =  ${JSON.stringify(sb)}: Updating this.state.settings ... Expecting Re-render...`);
                this.setState({settings: returnDetails.impbox_configuration});
            },
            (error) => {
                console.log("Refreshing IMPBoxSettings: ERROR in getSettingsApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });
    }

    componentDidUpdate(prevProps) {

        console.log(`IMPBoxSettings: in componentDidUpdate... Prev: ${prevProps.history} Current: ${this.props.history}  `)

        if (!!this.props.currentIMPBox.Setting) {
            let settings_stringX = this.props.currentIMPBox.Setting;
            let settingsX = settings_stringX.settings;
            let sb = settingsX["standby_mode"];
            console.log(`Rendering IMPBoxSettings: Checking standby_mode setting  : standby_mode =  ${JSON.stringify(sb)}`);
        }


            if (prevProps.currentIMPBox.impbox_id !== this.props.currentIMPBox.impbox_id) {

            console.log(`Rendering IMPBoxSettings: Update to impbox_id prop: Prev: ${prevProps.currentIMPBox.impbox_id}  Current: ${this.props.currentIMPBox.impbox_id}`);

            let box_mobile_id, username;

            if (!!this.props.currentIMPBox && !!this.props.currentIMPBox.box_mobiles && !!this.props.currentIMPBox.box_mobiles[0]) {
                box_mobile_id = this.props.currentIMPBox.box_mobiles[0].box_mobile_id;
                if (!!this.props.currentIMPBox.box_mobiles[0].Mobile && !!this.props.currentIMPBox.box_mobiles[0].Mobile.User && !!this.props.currentIMPBox.box_mobiles[0].Mobile.User.PII) {
                    username = this.props.currentIMPBox.box_mobiles[0].Mobile.User.PII.email;
                }
            }

            if (!!this.props.currentIMPBox.impbox_id) {
                this.setState({
                    impbox_id: this.props.currentIMPBox.impbox_id
                });
            }

            if (!!box_mobile_id) {
                this.setState({
                    owner_box_mobile: box_mobile_id
                });
            }

            if (!!username) {
                this.setState({
                    username: username
                });
            }

            let settings ={}, settings_string ;

            console.log(`Rendering IMPBoxSettings: Checking Settings: ${JSON.stringify(this.props.currentIMPBox.Setting)}`);
            if (!!this.props.currentIMPBox.Setting){
                settings_string = this.props.currentIMPBox.Setting;
                settings.settings =  settings_string.settings;
                let sb = settings.settings["standby_mode"];

                console.log(`Rendering IMPBoxSettings: adding  Settings to state : ${JSON.stringify(settings)} : standby_mode =  ${JSON.stringify(sb)}`);

                this.setState({settings: settings});
            } else {
                console.log(`Rendering IMPBoxSettings: Cannot find this.props.currentIMPBox.Setting `);
            }

        } else {

            console.log(`Rendering IMPBoxSettings: No update to impbox_id prop: Prev: ${prevProps.currentIMPBox.impbox_id}  Current: ${this.props.currentIMPBox.impbox_id}`);

        }
    }

    render() {

        console.log(`Rendering IMPBoxSettings with ImpBox: ${this.props.currentIMPBox.impbox_id} `)

        let api_result;
        if (this.state.success == true) {
            api_result = "API Call Completed";
        }
        let api_error;
        if (!!this.state.error && !!this.state.error.details) {
            api_error = this.state.error.details;
            console.log(`api_error = ${JSON.stringify(api_error)}`);
        } else {
            api_error = "";
        }
        let standby_mode = false;
        if (!!this.state.settings && !!this.state.settings.settings) {
            let standby_setting = this.state.settings.settings.standby_mode;
            console.log(`Rendering IMPBoxSettings with standby_setting = ${JSON.stringify(standby_setting)}`);
            if (!!standby_setting) {
                standby_mode = standby_setting.details.enabled;
            }
        } else {
            console.log(`IMPBoxSettings: this.state.settings appears to  be undefined or null`);
        }
        return (
            <ImpCollapsible collapsed={true} header={'Settings'}>
                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                <div><p style={{color: 'green'}}>{this.state.success}</p></div>
                <div style={{ overflow: "auto", textAlign: 'left'}}>
                        <StandByModeCheckBox standby_mode={standby_mode} network_api = {updateSettingApi} box_mobile_id = {this.state.owner_box_mobile} username = {this.state.username}
                                              refreshSettings = {this.refreshSettings.bind(this)} impbox_id={this.state.impbox_id}
                    />
                        <SettingsDetails key={"impbox_settings_" + this.state.impbox_id} settings={this.state.settings}/>
                </div>
            </ImpCollapsible>
        );
    }

}
export {IMPBoxSettings};
