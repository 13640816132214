import React, {Component} from 'react';
import {getContactsForMobile} from "../../util/apiServiceUtil";
import MobilePicker from "../Mobiles/MobilePicker";
import MobileContactList from './MobileContactList';
import ContactEdit from './ContactEdit';
import ContactCreate from './ContactCreate';

class ContactAdmin extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing MobileAdmin")
        this.state = {
            data: {contacts: []},
            mobilePicked: undefined,
            boxMobilePicked: undefined,
            impBoxPicked: undefined,
            mobile_contacts: []
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.userContext !== prevProps.userContext) {
            this.setState({
                data: {contacts: []},
                mobilePicked: undefined,
                impBoxPicked: undefined,
                boxMobilePicked: undefined,
                mobile_contacts: []
            });

            let uc = this.props.userContext;

            if (!!uc && !!uc.user) {
                if (!!uc.user.Mobiles &&  !!uc.user.Mobiles[0] &&  !!uc.user.Mobiles[0].box_mobiles && !!uc.user.Mobiles[0].box_mobiles[0] && !!uc.user.Mobiles[0].box_mobiles[0].box_mobile_id) {
                    console.log(`Setting ${uc.user.Mobiles[0].box_mobiles[0].box_mobile_id} as boxMobilePicked `)
                    this.setState({boxMobilePicked: uc.user.Mobiles[0].box_mobiles[0].box_mobile_id});
                }
            }
        }
    }

    pickMobile(mobile_id) {
        this.setState({mobilePicked: mobile_id});

        getContactsForMobile(mobile_id,
            (mobile_contacts) => {

                this.setState({contacts: mobile_contacts});
            },
            (error) => {
                console.log("ERROR in getContactsForMobile =  " + JSON.stringify(error));
                this.setState({"error": error});
            },
        );

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let uc = this.props.userContext;

        let mobiles = [];


        if (!!uc && !!uc.user) {
            mobiles = uc.user.Mobiles;
            console.log("Mobiles = " + JSON.stringify(mobiles));
        }

        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>

                    <div className={'fieldHolder'}>
                        <MobilePicker mobiles={mobiles} onChange={(mobile_id) => this.pickMobile(mobile_id)}/>
                    </div>

                    <ContactEdit mobile_id={this.state.mobilePicked} box_mobile_id={this.state.boxMobilePicked}
                                 onDelete={(mobile_id) => this.pickMobile(mobile_id)}
                                 onCreate={(mobile_id) => this.pickMobile(mobile_id)}>

                    </ContactEdit>

                    <ContactCreate mobile_id={this.state.mobilePicked} box_mobile_id={this.state.boxMobilePicked}
                                 onCreate={(mobile_id) => this.pickMobile(mobile_id)}>

                    </ContactCreate>

                    <MobileContactList contacts={this.state.contacts}/>

                </div>
            </div>
        );
    }
}

export default ContactAdmin;
