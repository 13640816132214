import React, {useState} from 'react';
import { useHistory, Link } from "react-router-dom";
import './Login.css';
//import {withRouter, RouteComponentProps} from "react-router";
import { useLocation } from "react-router-dom";
import useToken from "../../util/useToken";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}



export default  function Logout() {

    const history = useHistory();
    let query = useQuery();


    let user_message = "You have successfully logged out of IMP Admin";

    let message = query.get("msg");

    if(!!message){
        user_message = message;
    }

    const logout = () => {
        history.push("/");
    };

    let loginButton = <button onClick={logout}>Login to IMP Admin</button>;
    let loginLink =  <Link to="/">
        <button type="button">
            Login
        </button>
    </Link>

    return (
        <div className="login-wrapper">
            <h3>{user_message} </h3>
            <div>
                {loginLink}
            </div>


        </div>
    )
}
