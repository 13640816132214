import React, {useState, useEffect} from 'react';
import {getStripeSession} from "../util/apiServiceUtil";
import ReactGA from 'react-ga';


import {
    useLocation,
} from "react-router-dom";

const gaEventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

function PageBody({success, message, session}) {

    console.log(`Calling PageBody w/   ${success} ${message} ${session} `);

    let name_content, putTogether, shareasaleimg;
    if (success === true && !!session) {

       // Send Purchase Event to
        gaEventTrack('Purchase', 'Confirmed', 'IMPBox (Stripe)')


        let amt = (session.amount/100).toFixed(2);
        let merchID = session.shareasale_merchant_id;

        let src = `https://www.shareasale.com/sale.cfm?tracking=${session.order_id}"&amount=${amt}&merchantID=${merchID}&v=dev1.5&transtype=sale`;
        shareasaleimg = <img id='_SHRSL_img_1' src={src} width={1} height={1} />;
        name_content = (
                <div className="header-text center">
                    <span>Hi {session.customer_name}.</span>
                </div>
            );

        putTogether = <span>
            {shareasaleimg}
            {name_content}
            <p style={{fontWeight: "bold", fontSize: "35px"}}>
                Peace &amp; Quiet is on the Way!
            </p>
            <p style={{fontWeight: "bold", fontSize: "26px"}}>
                Thank you for your order.
            </p>
            <p style={{fontSize: "26px"}}>
                You will receive a confirmation email with order details and shipping information.
            </p>
            <p style={{fontSize: "26px"}}>
                If at any time you have a question, please feel free to email us at:
                <a href="mailto:help@joinimp.com" style={{
                    color: "rgb(0, 217, 113)",
                    textDecoration: "underline",
                    fontWeight: 700
                }}> {" "} help@joinimp.com </a>
            </p>
        </span>

    } else {

        if (!!message) {
            name_content = (
                <div className="header-text center">
                    <span>{message}</span>
                </div>);

            putTogether = <span>
            <p style={{fontWeight: "bold", fontSize: "35px"}}>
                        Oops... something doesn't look quite right.
                    </p>
                    <p style={{fontSize: "26px"}}>
                        The ducks aren't lining up... can you email us or call us to make sure your order got processed properly?
                    </p>
                    <p style={{fontSize: "26px"}}>
                        Email us at:
                        <a href="mailto:help@joinimp.com" style={{
                            color: "rgb(0, 217, 113)",
                            textDecoration: "underline",
                            fontWeight: 700
                        }}> {" "} help@joinimp.com </a>

                    </p>
            <p style={{fontSize: "26px"}}>OR</p>
            <p style={{fontSize: "26px"}}>
                Call us at: (800)374-0056
            </p>
        </span>
        }

    }


    // We return the Page Wrapper with specific variable sections.
    return (

        <div
            style={{ textAlign: "center", display: "flex", flexDirection: "column", position: "relative", flexShrink: 0, boxSizing: "border-box", marginTop: 20, width: "65%", marginLeft: "auto", marginRight: "auto" }} >

                <script src='https://www.dwin1.com/19038.js' type='text/javascript' defer='defer'></script>
            <div>
                <img width={234} height={94} src="https://s3.amazonaws.com/imp-cdn.itsmyphone.com/imp_hello_green.png" alt="" />
            </div>
            <div className="content grid">
                <div className="" style={{fontFamily: "Poppins, sans-serif", paddingBottom: 20}}>
                    {putTogether}
                </div>
            </div>
        </div>
    )

}

function StripeConfirmation() {

    const [success, setSuccess] = useState(null);
    const [session, setSession] = useState(null);
    const [message, setMessage] = useState("");

    let location = useLocation();
    let query = new URLSearchParams(location.search);
    let checkout_session_id = query.get("session_id");


    const layoutStyle = {
        margin: 20,
        padding: 20,
        border: '1px solid #DDD'
    };

    const getStripeSessionInfo = (checkout_session_id) => {

        console.log(`Calling getStripeSessionInfo with checkout_session_id = ${checkout_session_id} `);

        getStripeSession(checkout_session_id, (returnDetails) => {

                console.log("API return from getStripeSession " + JSON.stringify(returnDetails));
                setSuccess(returnDetails.success);
                if (returnDetails.success === true) {
                    setMessage("Got Stripe Session Details.");
                    setSession({
                        session_id: checkout_session_id,
                        customer_name: returnDetails.session.customer_name,
                        amount: returnDetails.session.amount,
                        currency: returnDetails.session.currency,
                        order_id: returnDetails.session.order_id,
                        shareasale_merchant_id: returnDetails.session.shareasale_merchant_id,

                    })
                    return Promise.resolve(returnDetails);
                } else {
                    setMessage(returnDetails.error);
                    return Promise.reject(returnDetails);
                }
            },
            (error) => {
                console.log(`ERROR in API getStripeSession = ${error}`);
                setSuccess(false);
                setMessage(error);
                // setMessage("Help Me!!!");
                return Promise.reject("Failure");

            });

    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getStripeSessionInfo(
                checkout_session_id
            );
            // All state logic inside callValidateEmail.
        };

        fetchData().catch(() => {
            setMessage("Error getting Stripe Session Data!!");
        });
    }, []);


    return (
        <div>
            <PageBody success={success} message={message} session={session}/>
        </div>
    )

}

export default StripeConfirmation;
