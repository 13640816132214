import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible"
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
var JSONPretty = require('react-json-pretty');

class HistoryDetails extends Component {

    constructor(props) {
        super(props);
        console.log("In ShowActivity constructor");
        this.state = {
            "error": "",
            "contact_list": null
        };
    }

    render() {

        let h = this.props.history;

        //{"history_id":22,"boxdatetime":"2021-04-29T17:50:29.000Z","created":"2021-04-29T17:50:29.000Z","from_number":"+14158889900","from_name":"Joe Bloggs","from_location":"No Caller Location","disposition":"2","action":"2","resolution":"1","type":"CALL","impbox_id":38,"message_id":null,"forwarded_by_user_id":null,"forwarded_to_user_id":null}

        var date = new Date(h.call_start_time);
        let hWhen = date.toLocaleString()

        let idAs
        //“GL” : 0 ,”RL” : 1, ”UNKNOWN” : 2, ”URGENT_CALL” : 3, “UNASSIGNED”: 4
        switch (h.disposition) {
            case '0':
                idAs = "Greenlist";
                break;
            case '1':
                idAs = "Redlist";
                break;
            case '2':
                idAs = "Unknown";
                break;
            case '3':
                idAs = "Urgent";
                break;
            case '4':
                idAs = "Unassigned";
                break;

            case '5':
                idAs = "Outgoing";
                break;

        }
        let actionAs
        //[“RING” : 0, ”TERMINATE” : 1, ”MESSAGE” : 2]
        switch (h.action) {
            case '0':
                actionAs = "Rang Phone";
                break;
            case '1':
                actionAs = "Terminated call";
                break;
            case '2':
                actionAs = "Took message";
                break;
            case '3':
                actionAs = "Dialed";
                break;

        }

        let resolutionAs
        //[“ANSWERED” : 0, ”UNANSWERED” : 1]
        switch (h.resolution) {
            case '0':
                resolutionAs = "Answered";
                break;
            case '1':
                resolutionAs = "Unanswered";
                break;

        }

        let bgCol = "#f0a5f5";
        if (h.type === "OUTGOING") {
            bgCol = "#e6f5a5"
        }

        let res = '';
        if (h.action === '0') {
            res = <span><b>resolution:</b> {resolutionAs} &nbsp;&nbsp;&nbsp;</span>
        }

        let thisH = <div key={"hist_" + h.history_id} style={{
            backgroundColor: bgCol,
            margin: '3px 3px 3px 20px',
            padding: '3px',
            borderRadius: '5px'
        }}>


            <span><b>When:</b> {hWhen} &nbsp;&nbsp;&nbsp;</span>
            <b>From Number:</b> {h.from_number} &nbsp;&nbsp;&nbsp;
            <b>disposition:</b>&nbsp;{idAs} &nbsp;&nbsp;&nbsp;
            <b>action:</b> {actionAs} &nbsp;&nbsp;&nbsp;
            {res} &nbsp;&nbsp;&nbsp;<b>Call Log:</b> {h.history_id}
            <div style={{'width': '600px',  'textAlign' : 'center', 'padding': 2, "border": '0px solid #DDD'}}>
                <ImpCollapsible collapsed={true} expandText="details" header={'Full Record'}>

                    <div style={{'width': '550px', 'marginLeft' : 30, 'textAlign' : 'left',  'padding': 2, "border": '0px solid #DDD'}}>
                        <JSONPretty id="json-pretty" data={h} theme={JSONPrettyMon}></JSONPretty>
                    </div>

                </ImpCollapsible>
            </div>

        </div>


        return (
            <div>

                {thisH}

            </div>
        );
    }
}
export {HistoryDetails};

