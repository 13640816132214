import React, {Component} from 'react';

import { AdminEmail } from "./APIs/AdminEmail";
import  AppVersions  from "./APIs/AppVersions";
import  Products  from "./APIs/Products";


class AdminTools extends Component {

    constructor(props) {
        super(props);
        console.log("In AdminTools constructor");
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <AdminEmail/>
                <AppVersions/>
                <Products/>
            </div>
        );
    }
}
export default AdminTools;
