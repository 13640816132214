import React, {Component} from 'react';
import ContextHelpList from './ContextHelpList';
import ContextHelpCreate from './ContextHelpCreate';
import {getAllContextHelps} from "../../util/apiServiceUtil";
import InviteCreate from "../Invites/InviteCreate";
import InviteList from "../Invites/InviteList";


class ContextHelpAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            contexthelps : []
        }
    }


    componentDidUpdate() {
        console.log("componentDidUpdate: in ContextHelpAdmin " );
    }

    refreshContextHelps() {

        console.log("Calling refreshContextHelps from CHList")

        getAllContextHelps(
            (contexthelps) => {
                console.log("contexthelps - " + JSON.stringify(contexthelps));
                this.setState({contexthelps: contexthelps.contexthelps});
            },
            (error) => {

                console.log("ERROR in getAllContextHelps =  " + error);
                this.setState({"error": error});
            })

    }

    componentDidMount() {
        console.log("CHAdmin did mount");
        this.refreshContextHelps();
    }


    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        let contexthelps = [];
        if(!!this.state && !!this.state.contexthelps) {
            contexthelps = this.state.contexthelps;
        }



        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>
                    <div style={layoutStyle}>
                        <ContextHelpCreate refreshContextHelps={e => this.refreshContextHelps()}/>
                    </div>
                    <div style={layoutStyle}>
                        <ContextHelpList
                            contexthelps={contexthelps}
                            refreshContextHelps={e => this.refreshContextHelps()}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

export default ContextHelpAdmin;
