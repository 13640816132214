import React, {Component} from 'react';
import {getCallStats} from '../../util/apiServiceUtil';



class StatsMain extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing StatsMain")
        this.state= {"error" : ""};
        this.state= {"stats" : {}};

    }

    componentDidMount() {

        console.log("StatsMain did mount");
        //get the user list
        getCallStats(
            (stats) => {
                //console.log("users - "+JSON.stringify(users));
                this.setState({stats: stats});
            },
            (error) => {

                console.log("ERROR in getAllUsers =  " + error);
                this.setState({"error": error});
            })
    }


    render() {

        let users = this.props.users;
        let stats={};

        stats.userCount = users.length;


        return (
            <div >
                <div style={{'width':'50%','float':'left'}}>
                    <div className={'sectionHead'}> Call Stats</div>

                    <div className={'sectionBody'}>
                        <h1>Total Calls: {this.state.stats.totalCalls}</h1>
                        <h2>{this.state.stats.RLCalls} Calls Blocked</h2>
                        <h2>{this.state.stats.UNKCalls} Calls Screened</h2>
                        <h2>{this.state.stats.GLCalls} Calls Accepted</h2>
                        <br/><br/>
                        <h2>{this.state.stats.MessagesTaken} Messages Taken</h2>

                    </div>

                </div>
                <div style={{'width':'50%','float':'left'}}>
                    <div className={'sectionHead'}> User Stats</div>

                    <div className={'sectionBody'}>
                        <h1>Total Number of Users : {stats.userCount}</h1>
                        <h2> Using {'X number'} of Mobile Devices </h2>
                        <h2>Accross {'Y number'} of IMP Boxes</h2>

                    </div>

                </div>

            </div>
        );
    }
}
export default StatsMain;
