import React, {Component} from 'react';
import {deleteContactsApi, createContactSetApi} from "../../util/apiServiceUtil";


class ContactEdit extends Component {


    constructor(props) {
        super(props);
        console.log("In ContactEdit constructor");
        this.state = {
            "delete_error": null,
            create_count: 0
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.mobile_id !== prevProps.mobile_id) {
            console.log(`ZZZ mobile_id = ${this.props.mobile_id} `)
            this.setState({mobile_id: this.props.mobile_id});
        }
        if (this.props.box_mobile_id !== prevProps.box_mobile_id) {
            console.log(`YYY box_mobile_id = ${this.props.box_mobile_id} `)
            this.setState({box_mobile_id: this.props.box_mobile_id});
        }
    }


    deleteContacts(mobile_id) {

        this.setState({"delete_error": undefined});
        this.setState({delete_response: undefined});


        deleteContactsApi(mobile_id,
            (delete_response) => {

                this.setState({delete_response: delete_response.contacts});
                this.props.onDelete(mobile_id);
            },
            (delete_error) => {

                console.log("ERROR in deleteContacts =  " + JSON.stringify(delete_error));
                this.setState({"delete_error": delete_error});
            },
        );

    }

    handleCountChange(e){

        // console.log(`${JSON.stringify(e.target)}`)

        const val = e.target.value;
        this.setState({"create_count": val});

    }

    createContactSet(box_mobile_id, count) {

        let request = {box_mobile_id: box_mobile_id , count:count };

        this.setState({"create_error": undefined});
        this.setState({create_response: undefined});

        createContactSetApi(request,
            (create_response) => {

                let respString= `Created ${create_response.contacts.length} contacts.`;
                console.log(`Response from createContactSetApi : ${JSON.stringify(create_response)} `)

                this.setState({create_response: respString});
                this.props.onCreate(this.state.mobile_id);
            },
            (create_error) => {

                console.log("ERROR in createContactSet =  " + JSON.stringify(create_error));
                this.setState({"create_error": JSON.stringify(create_error)});
            },
        );

    }


    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let delete_error = this.state.delete_error;
        let delete_response = this.state.delete_response;
        let create_error = this.state.create_error;
        let create_response = this.state.create_response;

        return (
            <div style={layoutStyle}>
                <div style={layoutStyle}>
                    <div><p style={{color: 'red'}}>{delete_error}</p></div>
                    <div><p style={{color: 'green'}}>{delete_response}</p></div>

                    <button onClick={() => this.deleteContacts(this.state.mobile_id)}>Delete Contacts for
                        mobile_id {this.state.mobile_id}</button>
                </div>

                <div style={layoutStyle}>
                    <div><p style={{color: 'red'}}>{create_error}</p></div>
                    <div><p style={{color: 'green'}}>{create_response}</p></div>


                    <button onClick={() => this.createContactSet(this.state.box_mobile_id, this.state.create_count)}>Create {this.state.create_count} Contact(s) for
                            mobile_id {this.state.mobile_id}</button>
                    <input type="number" pattern="[0-9]*"
                           onInput={this.handleCountChange.bind(this)} value={this.state.create_count} />
                </div>
            </div>

        );


    }
}

export default ContactEdit;