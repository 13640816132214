import React, {Component} from 'react';
import {updateFirmwareAdmin} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";

class FirmwareUpdate extends Component {

    constructor(props) {
        super(props);
        console.log("In FirmwareUpdate constructor");
        this.state = {
            "impbox": {
                "impbox_id": "",
                "firmware_version_desired": "",
            },
            "error": "",
            "details": {}

        };
    }


    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        const target = e.target;
        const val = target.name === 'mkting_agree' ? target.checked : target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        console.log("changeText: updating state with  " + JSON.stringify(change));
        this.setState(change);
        console.log("changeText: after updating state :  " + this.state["impbox"]);

    }

    updateFirmware() {
        console.log("Starting the Firmware Update Process");

        if (this.state.impbox.firmware_version_desired=='select' || !this.state.impbox.firmware_version_desired)
        {
            alert("You have to select a FW version");
            return;
        }


        let req = {
            "impbox_id": this.state.impbox.impbox_id,
            "firmware_version_desired": this.state.impbox.firmware_version_desired,
        }


        updateFirmwareAdmin(req, (returnDetails) => {

                console.log("API return from updateFirmware " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                };

                let impbox_details = returnDetails.impbox_details;
                this.setState({"details": details,"impbox_details":impbox_details });
                let success_message = "Update Firmware" ;

                this.props.alert.success(success_message);
                if (returnDetails.success == "false"){
                    success_message += ` ${returnDetails.error.message}`;

                }
                this.props.refreshFirmwares();
            },
            (error) => {

                console.log("ERROR in updateFirmware =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }

    render() {

        let fList = this.props.firmwares;

        let success_message;
        let detsToShow = "";
        if (this.state.details.success == "true") {
            success_message =
                <div>
                    <p style={{color: 'green'}}>Firmware Update Request Delivered</p>
                </div>
        }

        let fToShow = [];

        let selectOption=<option key={"fwSelect_select"} value={"select"}>-- Select one --</option>
        fToShow.push(selectOption);
       if(!!fList && fList.length>0) {
           for (let f of fList) {
               let thisFShow = <option
                   key={"fwSelect_" + f.version}
                   value={f.version}>{`${f.version} `}
               </option>;

               fToShow.push(thisFShow);
           }
       }


        if(this.state.impbox_details)
        {
            detsToShow = <div>{JSON.stringify(this.state.impbox_details)}</div>;
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Send Update Firmware Request to IMPBox' }>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>impbox_id:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.impbox.impbox_id}
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox.impbox_id')}
                                               size={'60'}/>
                                    </div>
                                    <div>You can enter a single impbox id like: "3" a comma seperated list like "3, 7, 10" or a range like "3-10" you can also put a range in a comma delimited list: "3,5-10,12" </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>version_desired:</div>
                                    <div className={'fieldInput'}>

                                        <select id="allPlans" value={this.state.impbox.firmware_version_desired}
                                                        onChange={(event, fld_id) => this.changeText(event, 'impbox.firmware_version_desired')}>
                                        {fToShow}
                                    </select>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                            </div>

                            <button onClick={() => this.updateFirmware()}>Update Firmware</button>
                        </div>

                        {detsToShow}

                    </div>

                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(FirmwareUpdate);
