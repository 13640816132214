import React, {Component} from 'react';
import {inviteUser} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";

class InviteCreate extends Component {


    constructor(props) {
        super(props);
        console.log("In InviteCreate constructor");
        this.state = {
            "invite": {
                "invitee_email": "",
                "invitor": "",
                "invitee_name": "",
                "invitee_role": "",
                "impbox_id": ""
            },
            "error": "",
            "details": {}

        };
    }


    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        const target = e.target;
        const val = target.name === 'mkting_agree' ? target.checked : target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        console.log("changeText: updating state with  " + JSON.stringify(change));
        this.setState(change);
        console.log("changeText: after updating state :  " + this.state["user"]);

    }

    inviteUser() {
        console.log("Starting the Invite Creation Process");

        let invite = {
            "invitee_email": this.state.invite.invitee_email,
            "invitor": this.state.invite.invitor,
            "invitee_name": this.state.invite.invitee_name,
            "invitee_role": this.state.invite.invitee_role,
            "impbox_id":this.state.invite.impbox_id
        }

        inviteUser(invite, (returnDetails) => {

                console.log("API return from inviteUser " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    invite_uuid: returnDetails.invite_uuid
                };
                this.setState({"details": details});
                let success_message = "Created Invite: UUID =  " + details.invite_uuid ;

                this.props.alert.success(success_message);
                this.props.refreshInvites();
            },
            (error) => {

                console.log("ERROR in inviteUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }

    render() {


        let success_message;
        if (this.state.details.invite_uuid) {
            success_message =
                <div>
                    <p style={{color: 'green'}}>New Invite Created: UUID = {this.state.details.invite_uuid}</p>
                </div>
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Create New Invite' }>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>invitee_email:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.invite.invitee_email}
                                               onChange={(event, fld_id) => this.changeText(event, 'invite.invitee_email')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>invitor:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.invite.invitor}
                                               onChange={(event, fld_id) => this.changeText(event, 'invite.invitor')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>invitee_name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.invite.invitee_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'invite.invitee_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>


                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>invitee_role:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.invite.invitee_role}
                                               onChange={(event, fld_id) => this.changeText(event, 'invite.invitee_role')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>impbox_id:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.invite.impbox_id}
                                               onChange={(event, fld_id) => this.changeText(event, 'invite.impbox_id')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                            </div>

                            <button onClick={() => this.inviteUser()}>Invite User</button>
                        </div>


                    </div>

                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(InviteCreate);
