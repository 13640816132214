import React, {Component} from 'react';
import FirmwareList from './FirmwareList';
import FirmwareCreate from './FirmwareCreate';
import FirmwareUpdate from './FirmwareUpdate';
import FirmwareUpload from './FirmwareUpload';
import {getAllFirmwares} from "../../util/apiServiceUtil";

class FirmwareAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            firmwares : []
        }
    }

    componentDidMount() {
        console.log("Firmwares Admin did mount");
        this.refreshFirmwares();
    }


    refreshFirmwares() {

        console.log("Calling refreshFirmwares from FirmwareAdmin")

        getAllFirmwares(
            (firmwares) => {
                console.log("firmwares - " + JSON.stringify(firmwares));
                this.setState({firmwares: firmwares.firmwares});
            },
            (error) => {

                console.log("ERROR in getAllFirmwares =  " + error);
                this.setState({"error": error});
            })

    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        return (
            <div style={{width: "950px"}}>
                <div style={layoutStyle}>

                    <div style={layoutStyle}>
                        <FirmwareUpdate
                            firmwares={this.state.firmwares}
                            refreshFirmwares={() => this.refreshFirmwares()}
                        />
                    </div>
                    <div style={layoutStyle}>
                        <FirmwareUpload

                            refreshFirmwares={() => this.refreshFirmwares()}
                        />
                    </div>
                    <div style={layoutStyle}>
                        <FirmwareList
                            firmwares={this.state.firmwares}
                            refreshFirmwares={() => this.refreshFirmwares()}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

export default FirmwareAdmin;
