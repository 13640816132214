import React, {Component} from 'react';


class MobilePicker extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing MobilePicker");
        this.state = {mobile_id: undefined};
    }

    pickMobile(e) {
        this.setState({mobile_id: e.target.value});
        this.props.onChange(e.target.value);
    }


    render() {

        let mobiles = this.props.mobiles;
        let mobs = [<option key={"mobSelect_00"}
                            value={'00'}>Select Mobile...</option>];

        if (mobiles.length === 0) {
            return <div></div>
        }

        for (let mob of mobiles) {

            let thisMob_name = <option key={"mobSelect_" + mob.mobile_id}
                                       value={mob.mobile_id}>{mob.device_name}</option>;

            mobs.push(thisMob_name);
        }

        return (
            <div>
                Mobile Picker
                <select onChange={this.pickMobile.bind(this)}>
                    {mobs}
                </select>


            </div>

        );
    }
}
export default MobilePicker;