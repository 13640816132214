
require('dotenv').config();  //instantiate environment variables

let CONFIG = {}; //Make this global to use all over the application

CONFIG.app_api_v1=process.env.REACT_APP_APP_API_V1 || "http://localhost:4000/app/v1";
CONFIG.boxservice_api_v1=process.env.REACT_APP_BOX_API_V1 || "http://localhost:4000/impbox/v1";
CONFIG.admin_api_v1=process.env.REACT_APP_ADMIN_API_V1 || "http://localhost:4000/admin/v1";


CONFIG.react_deployment=process.env.REACT_APP_DEPLOYMENT || "LOCAL";

//APP Service Calls
CONFIG.notificationRead_service_api = CONFIG.app_api_v1 + "/notificationRead" || "http://localhost:4000/app/v1/markNotificationUnread";
CONFIG.uploadOutgoingMessage_service_api = CONFIG.app_api_v1 + "/uploadOutgoingMessage" || "http://localhost:4000/app/v1/uploadOutgoingMessage";
CONFIG.registerUser_service_api = CONFIG.app_api_v1 + "/registerUserWithDevice" || "http://localhost:4000/app/v1/registerUserWithDevice";
CONFIG.getInvite_service_api = CONFIG.app_api_v1 + "/getInvite" || "http://localhost:4000/app/v1/getInvite";
CONFIG.getInvitesByEmail_service_api = CONFIG.app_api_v1 + "/getInvitesByEmail" || "http://localhost:4000/app/v1/getInvitesByEmail";
CONFIG.createContactAndRemoveNotification_service_api = CONFIG.app_api_v1 + "/createContactAndRemoveNotification" || "http://localhost:4000/app/v1/createContactAndRemoveNotification";


//Moved back from  admin api
CONFIG.updateSetting_service_api = CONFIG.app_api_v1 + "/updateSetting" || "http://localhost:4000/app/v1/updateSetting";
CONFIG.inviteUser_service_api = CONFIG.app_api_v1 + "/inviteUser" || "http://localhost:4000/app/v1/inviteUser";
CONFIG.registerIMPBox_service_api = CONFIG.app_api_v1 + "/registerIMPBox" || "http://localhost:4000/app/v1/registerIMPBox";

//Moved to admin api
CONFIG.getNotificationsForMobile_service_api = CONFIG.admin_api_v1 + "/getNotificationsForMobile"  || "http://localhost:4000/admin/v1/getNotificationsForMobile";
CONFIG.getIMPBoxesForMobile_service_api = CONFIG.admin_api_v1 + "/getBoxMobileByMobile" || "http://localhost:4000/admin/v1/getBoxMobileByMobile";
CONFIG.getIMPBoxUsers_service_api = CONFIG.admin_api_v1 + "/getIMPBoxUsers" || "http://localhost:4000/admin/v1/getIMPBoxUsers";
CONFIG.getIMPBoxActivity_service_api = CONFIG.admin_api_v1 + "/getHistory" || "http://localhost:4000/admin/v1/getHistory";

//BOX SERVICE CALLS
CONFIG.getSettings_service_api = CONFIG.boxservice_api_v1 + "/getSettings" || "http://localhost:4000/impbox/v1/getSettings";
CONFIG.logCall_service_api = CONFIG.boxservice_api_v1 + "/logCall" || "http://localhost:4000/impbox/v1/logCall";
CONFIG.newMessage_service_api = CONFIG.boxservice_api_v1 + "/newMessage" || "http://localhost:4000/impbox/v1/newMessage";
CONFIG.getContactList_service_api = CONFIG.boxservice_api_v1 + "/getContactList" || "http://localhost:4000/impbox/v1/getContactList";
CONFIG.getPagedPhoneNumberList_service_api = CONFIG.boxservice_api_v1 + "/getPagedPhoneNumberList" || "http://localhost:4000/impbox/v1/getPagedPhoneNumberList";
CONFIG.getShortPhoneNumberList_service_api = CONFIG.boxservice_api_v1 + "/getShortPhoneNumberList" || "http://localhost:4000/impbox/v1/getShortPhoneNumberList";

CONFIG.sendHeartbeat_service_api = CONFIG.boxservice_api_v1 + "/heartbeat" || "http://localhost:4000/impbox/v1/heartbeat";
CONFIG.setup_service_api = CONFIG.boxservice_api_v1 + "/setup" || "http://localhost:4000/impbox/v1/setup";
CONFIG.getFirmware_service_api = CONFIG.boxservice_api_v1 + "/getFirmware" || "http://localhost:4000/impbox/v1/getFirmware";
CONFIG.settingProcessed_service_api = CONFIG.boxservice_api_v1 + "/settingProcessed" || "http://localhost:4000/impbox/v1/settingProcessed";
CONFIG.settingApplied_service_api = CONFIG.boxservice_api_v1 + "/settingApplied" || "http://localhost:4000/impbox/v1/settingApplied";

CONFIG.getSettings_service_api = CONFIG.boxservice_api_v1 + "/getSettings" || "http://localhost:4000/impbox/v1/getSettings";



//ADMIN SERVICE CALLS
CONFIG.getCallStats_service_api = CONFIG.admin_api_v1 + "/getCallStats" || "http://localhost:4000/admin/v1/getCallStats";
CONFIG.getUser_service_api = CONFIG.admin_api_v1 + "/getUser" || "http://localhost:4000/admin/v1/getUser";
CONFIG.get_admin_accounts_service_api = CONFIG.admin_api_v1 + "/getAdminAccounts" || "http://localhost:4000/admin/v1/getAdminAccounts";
CONFIG.place_call_service_api = CONFIG.admin_api_v1 + "/placeCall" || "http://localhost:4000/admin/v1/placeCall";
CONFIG.create_admin_accounts_service_api = CONFIG.admin_api_v1 + "/createAdminAccount" || "http://localhost:4000/admin/v1/createAdminAccount";
CONFIG.delete_admin_accounts_service_api = CONFIG.admin_api_v1 + "/deleteAdminAccount" || "http://localhost:4000/admin/v1/deleteAdminAccount";
CONFIG.getAllUsers_service_api = CONFIG.admin_api_v1 + "/getAllUsers" || "http://localhost:4000/admin/v1/getAllUsers";
CONFIG.getAllSubscriptions_service_api = CONFIG.admin_api_v1 + "/getAllSubscriptions" || "http://localhost:4000/admin/v1/getAllSubscriptions";
CONFIG.updateSubscriptionDetails_service_api = CONFIG.admin_api_v1 + "/updateSubscriptionDetails" || "http://localhost:4000/admin/v1/updateSubscriptionDetails";
CONFIG.removeSubscription_service_api = CONFIG.admin_api_v1 + "/removeSubscription" || "http://localhost:4000/admin/v1/removeSubscription";
CONFIG.deleteUser_service_api = CONFIG.admin_api_v1 + "/deleteUser" || "http://localhost:4000/admin/v1/deleteUser";
CONFIG.archiveUser_service_api = CONFIG.admin_api_v1 + "/archiveUser" || "http://localhost:4000/admin/v1/archiveUser";
CONFIG.getAllContextHelps_service_api = CONFIG.admin_api_v1 + "/getAllContextHelps" || "http://localhost:4000/admin/v1/getAllContextHelps";
CONFIG.createOrUpdateContextHelp_service_api = CONFIG.admin_api_v1 + "/createOrUpdateContextHelp" || "http://localhost:4000/admin/v1/createOrUpdateContextHelp";
CONFIG.deleteContextHelp_service_api = CONFIG.admin_api_v1 + "/deleteContextHelp" || "http://localhost:4000/admin/v1/deleteContextHelp";
CONFIG.getAllIMPBoxes_service_api = CONFIG.admin_api_v1 + "/getAllIMPBoxes" || "http://localhost:4000/admin/v1/getAllIMPBoxes";
CONFIG.sendAdminEmail_service_api = CONFIG.admin_api_v1 + "/sendAdminEmail" || "http://localhost:4000/admin/v1/sendAdminEmail";
CONFIG.getAllInvites_service_api = CONFIG.admin_api_v1 + "/getAllInvites" || "http://localhost:4000/admin/v1/getAllInvites";
CONFIG.getAllFirmwares_service_api = CONFIG.admin_api_v1 + "/getAllFirmwares" || "http://localhost:4000/admin/v1/getAllFirmwares";
CONFIG.updateFirmwareAdmin_service_api = CONFIG.admin_api_v1 + "/updateFirmware" || "http://localhost:4000/admin/v1/updateFirmware";
CONFIG.createFirmwareAdmin_service_api = CONFIG.admin_api_v1 + "/createFirmware" || "http://localhost:4000/admin/v1/createFirmware";
CONFIG.uploadFirmware_service_api = CONFIG.admin_api_v1 + "/uploadFirmware" || "http://localhost:4000/admin/v1/uploadFirmware";
CONFIG.push_apn_service_api = CONFIG.admin_api_v1 + "/pushAPNMessage"  || "http://localhost:4000/admin/v1/pushAPNMessage";
CONFIG.push_fcm_service_api = CONFIG.admin_api_v1 + "/pushFCMMessage"  || "http://localhost:4000/admin/v1/pushFCMMessage";
CONFIG.create_subscription_data_with_user_api = CONFIG.admin_api_v1 + "/createSubscriptionWithUser"  || "http://localhost:4000/admin/v1/createSubscriptionWithUser";
CONFIG.factory_reset_service_api = CONFIG.admin_api_v1 + "/factoryReset"  || "http://localhost:4000/admin/v1/factoryReset";
CONFIG.updateSubscription_service_api = CONFIG.admin_api_v1 + "/updateSubscription"  || "http://localhost:4000/admin/v1/updateSubscription";
CONFIG.setMobileAuditMode_service_api = CONFIG.admin_api_v1 + "/setMobileAuditMode"  || "http://localhost:4000/admin/v1/setMobileAuditMode";
CONFIG.setUserContactBumper_service_api = CONFIG.admin_api_v1 + "/setUserContactBumper"  || "http://localhost:4000/admin/v1/setUserContactBumper";
CONFIG.updateIMPBoxPhoneNumber_service_api = CONFIG.admin_api_v1 + "/updateIMPBoxPhoneNumber"  || "http://localhost:4000/admin/v1/updateIMPBoxPhoneNumber";

//APPVersions
CONFIG.getAppVersions_service_api = CONFIG.admin_api_v1 + "/getAppVersions" || "http://localhost:4000/admin/v1/getAppVersions";
CONFIG.updateAppVersion_service_api = CONFIG.admin_api_v1 + "/updateAppVersion" || "http://localhost:4000/admin/v1/updateAppVersion";

//Products
CONFIG.getProducts_service_api = CONFIG.admin_api_v1 + "/getProducts" || "http://localhost:4000/admin/v1/getProducts";
CONFIG.updateProduct_service_api = CONFIG.admin_api_v1 + "/updateProduct" || "http://localhost:4000/admin/v1/updateProduct";
CONFIG.addProduct_service_api = CONFIG.admin_api_v1 + "/addProduct" || "http://localhost:4000/admin/v1/addProduct";
CONFIG.deleteProduct_service_api = CONFIG.admin_api_v1 + "/deleteProduct" || "http://localhost:4000/admin/v1/deleteProduct";

CONFIG.addNotification_service_api = CONFIG.admin_api_v1 + "/createNotification" || "http://localhost:4000/admin/v1/createNotification";

CONFIG.validateEmail_service_api = CONFIG.admin_api_v1 + "/validateEmail" || "http://localhost:4000/admin/v1/validateEmail";
CONFIG.getStripeSession_service_api = CONFIG.admin_api_v1 + "/getStripeSession" || "http://localhost:4000/admin/v1/getStripeSession";
CONFIG.getAPNLogsForDevice_service_api = CONFIG.admin_api_v1 + "/getAPNLogsForDevice" || "http://localhost:4000/admin/v1/getAPNLogsForDevice";
CONFIG.getAPNStats_service_api = CONFIG.admin_api_v1 + "/getAPNStats" || "http://localhost:4000/admin/v1/getAPNStats";
CONFIG.getIMPBoxCallHistory_service_api = CONFIG.admin_api_v1 + "/getIMPBoxCallHistory" || "http://localhost:4000/admin/v1/getIMPBoxCallHistory";
CONFIG.getContactsForMobile_service_api = CONFIG.admin_api_v1 + "/getContactsForMobile"|| "http://localhost:4000/admin/v1/getContactsForMobile";
CONFIG.getAllErrorLogs_service_api = CONFIG.admin_api_v1 + "/getErrorLogs"|| "http://localhost:4000/admin/v1/getErrorLogs";
CONFIG.deleteContacts_service_api = CONFIG.admin_api_v1 + "/deleteContacts"|| "http://localhost:4000/admin/v1/deleteContacts";
CONFIG.createContactSet_service_api = CONFIG.admin_api_v1 + "/createContactSet"|| "http://localhost:4000/admin/v1/createContactSet";
CONFIG.createContact_service_api = CONFIG.admin_api_v1 + "/createContact"|| "http://localhost:4000/admin/v1/createContact";

//AUTH
CONFIG.login_service_api = CONFIG.admin_api_v1 + "/login" || "http://localhost:4000/admin/v1/login";
CONFIG.getIMPBoxRefreshToken_service_api = CONFIG.admin_api_v1 + "/getIMPBoxRefreshToken" || "http://localhost:4000/admin/v1/authenticateAsImpbox";
CONFIG.impBoxAuthenticate_service_api = CONFIG.boxservice_api_v1 + "/authenticate" || "http://localhost:4000/impbox/v1/authenticate";
CONFIG.getAppAccessToken_service_api = CONFIG.admin_api_v1 + "/getAppAccessToken" || "http://localhost:4000/admin/v1/getAppAccessToken";

//CONTACT IMPORT/EXPORT
CONFIG.exportUserImpContacts_service_api = CONFIG.admin_api_v1 + "/exportUserImpContacts" || "http://localhost:4000/admin/v1/exportUserImpContacts";
CONFIG.getImportableBatches_service_api = CONFIG.admin_api_v1 + "/getImportableBatches" || "http://localhost:4000/admin/v1/getImportableBatches";
CONFIG.importUserImpContacts_service_api = CONFIG.admin_api_v1 + "/importUserImpContacts" || "http://localhost:4000/admin/v1/importUserImpContacts";


// Google Analytics
CONFIG.ga_imp_tracking_id=process.env.REACT_APP_GA_IMP_TRACKING_ID || "UA-206524986-1";

module.exports = CONFIG;
