import { useState } from 'react';

export default function useToken() {

    const logoutSession = (message) => {
        console.log(`Logging Out Session with Message ${message}`)
        localStorage.removeItem('admin-token');
        localStorage.removeItem('impbox_refresh_token');
        localStorage.removeItem('impbox_access_token');
        localStorage.removeItem('impbox_id');
        localStorage.removeItem('impbox_uuid');
        localStorage.removeItem('app_access_token');
        localStorage.removeItem('app_username');
        localStorage.setItem('admin_message', message);
        setToken("");
        setUsername("");
        setRole("");
    }

    const getToken = () => {
        const tokenString = localStorage.getItem('admin-token');
        const userToken = JSON.parse(tokenString);
        let storedToken;
        if (userToken && userToken.token){
           storedToken = userToken.token
        }
        return storedToken;
    };

    const getUserName = () => {
        const tokenString = localStorage.getItem('admin-token');
        const userToken = JSON.parse(tokenString);
        let storedUsername;
        if (userToken && userToken.username){
            storedUsername = userToken.username
        }
        return storedUsername;
    };


    // TODO We should be getting the Role/User name from the Toeken not from the External Payload
    const getRole = () => {
        const tokenString = localStorage.getItem('admin-token');
        const userToken = JSON.parse(tokenString);
        let storedRole;
        if (userToken && userToken.role){
            storedRole = userToken.role
        }
        return storedRole;
    };


    const [token, setToken] = useState(getToken());
    const [username, setUsername] = useState(getUserName());
    const [role, setRole] = useState(getRole());

    const saveToken = userToken => {
        console.log(`Setting Token ${userToken.token}`)
        localStorage.setItem('admin-token', JSON.stringify(userToken));
        if(!!userToken) {
            setToken(userToken.token);
            setUsername(userToken.username);
            setRole(userToken.role);
        }
    };

    return {
        setToken: saveToken,
        token,
        username,
        role,
        logoutSession
    }
}