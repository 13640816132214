import './Nav.css';
import {useHistory} from "react-router-dom";
import React, {useContext} from 'react';
import useToken from "../../util/useToken";
import AppUserContext from "../../contexts/AppUserContext";
import IMPBoxContext from "../../contexts/IMPBoxContext";


function getPages(role) {
    let _pages = [];
    _pages.push('Home');
    _pages.push('Users');
    _pages.push('IMP Boxes');
    _pages.push('Subscriptions');
    _pages.push('Context Help');
    //_pages.push('Mobiles');
    _pages.push('Contacts');
    _pages.push('IMP Sim');
    _pages.push('App Sim');
    _pages.push('Push Notifications');
    _pages.push('Admin Tools');
    _pages.push('Invites');
    _pages.push('Firmware');
    _pages.push('Notifications');
    _pages.push('Error Logs');
    _pages.push('KPIs');
    if (role === "SUPER_ADMIN") {
        _pages.push('Admin Manager');
    }
    //_pages.push('Stats');

    return _pages
}


function Nav({currentNav, deployment, onNav, background, setToken, username, role}) {

    const history = useHistory();
    console.log("currentNav - " + currentNav);
    const {logoutSession} = useToken();
    //const {app_user_username} = useContext(AppUserContext);

    let _menu = [];

    for (let _page of getPages(role)) {
        let cn = 'menuItem';
        if (_page === currentNav) {
            cn = 'currentMenuItem'
        }

        let _thisMenu = <div
            className={cn} onClick={(page) => onNav(_page)} key={_page}>
            {_page}
        </div>
        _menu.push(_thisMenu);
    }

    const logout = () => {
        //setToken("User Log Out");
        logoutSession("LoggedOut");
        history.push("/logout?msg=UserLogout");
    };

    let welcome = <div className="loginItem">ADMIN: {username} ({role})</div>
    let logoutButton = <button onClick={logout}>logout</button>;

    const {app_username, setUsername} = useContext(AppUserContext)
    const {impbox_uuid, impbox_id, refresh_token, access_token, setIMPBox} = useContext(IMPBoxContext)


    return (

        // <AppUserContext.Consumer>
        //     {({app_username, setUsername}) => (

        <div style={{background: background}} className="navWrapper">
            <div className="clear"></div>

            <div className={'loginBar'}>
                <table>
                    <tbody>
                    <tr><td>{welcome}</td></tr>
                    <tr>
                        <td><div className="loginItem">IMPBOX: {impbox_uuid}</div></td>
                    </tr>
                    <tr>
                        <td><div className="loginItem">USER: {app_username}</div></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={'loginBar'}>
                {deployment}
            </div>

            <div className={'logoutBar'}>
                {logoutButton}
            </div>
            <div className="clear"></div>

            <div className={'menuBar'}>
                {_menu}
            </div>
            <div className="clear"></div>
        </div>
        //         )}
        //
        // </AppUserContext.Consumer>


    );
}

export default Nav;

