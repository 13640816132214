import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible";

var _ = require('lodash');


class ShowActivity extends Component {

    constructor(props) {
        super(props);
        console.log("In ShowActivity constructor");
        this.state = {
            "error": "",
            "contact_list": null
        };
    }


    render() {

        let act = this.props.activity;
        let users = this.props.impboxUsers;

        if (users) {
            users = users.users
        }

        let actToShow;

        let at = act.action_type;
        let a = act.action;

        var date = new Date(act.createdAt);
        let actWhen = date.toLocaleString();

        let actBy = _.find(users, {"user_id": act.action_by});

        if (!actBy) {
            actBy = act.action_by;
        } else {
            actBy = actBy.full_name;
        }


        let defaultShow = <div className={'actDetail'}>{actBy} Action
            Type: {at} Action:{a} Details: {JSON.stringify(act.details)}  </div>

        if (at === "DEVICE") {
            if (a === "INVITE") {
                actToShow = <div className={'actDetail'}>"{actBy}" invited "{act.details.invitee}" </div>
            } else if (a === "LEAVE") {

                actToShow = <div className={'actDetail'}>"{actBy}" Removed user "{act.details.leaver}" </div>

            } else if (a === "JOIN") {
                actToShow = <div className={'actDetail'}>"{actBy}" joined </div>
            } else {
                actToShow = defaultShow
            }
        } else if (at === "CALLER") {
            if (a === "GREENLIST") {
                actToShow = <div className={'actDetail'}>"{actBy}" Greenlisted "{act.details.contact_id}" from a
                    notification </div>

            } else if (a === "REDLIST") {
                actToShow = <div className={'actDetail'}>"{actBy}" Redlisted "{act.details.contact_id}" from a
                    notification </div>

            } else if (a === "DELETE") {
                actToShow = <div className={'actDetail'}>"{actBy}" Deleted a notification about a call from
                    "{act.details.message_from}" </div>

            } else if (a === "LISTEN") {
                actToShow = <div className={'actDetail'}>"{actBy}" Listened to a message from
                    "{act.details.message_from}" </div>

            } else if (a === "CALLBACK") {
                actToShow = <div className={'actDetail'}>"{actBy}" Called back "{act.details.message_from}" </div>

            } else if (a === "CREATE") {
                actToShow = <div className={'actDetail'}> A notification was sent about call
                    log: {act.details.history_id}  </div>

            } else {
                actToShow = <div className={'actDetail'}>{actBy} Action
                    Type: {at} Action:{a} Details: {JSON.stringify(act.details)}  </div>
            }


        } else if (at === "LIST") {
            if (a === "GREENLIST") {
                actToShow =
                    <div className={'actDetail'}>"{actBy}" Greenlisted "{act.details.contact_id}" from list view </div>

            } else if (a === "REDLIST") {
                actToShow =
                    <div className={'actDetail'}>"{actBy}" Redlisted "{act.details.contact_id}" from list view </div>

            } else if (a === "UNASSIGN") {
                actToShow =
                    <div className={'actDetail'}>"{actBy}" Unassigned "{act.details.contact_id}" from list view </div>

            } else {
                actToShow = <div className={'actDetail'}>{actBy} Action
                    Type: {at} Action:{a} Details: {JSON.stringify(act.details)}  </div>
            }


        } else if (at === "SETTING") {
            if (a === "OUTGOING_MESSAGE") {
                actToShow = <div className={'actDetail'}>"{actBy}" Changed the outgoing message link to
                    "{act.details.message_link}" </div>

            } else if (a === "ANSWERING_MACHINE") {

                if (act.details.take_message_from_greenlist === true) {
                    actToShow = <div className={'actDetail'}>"{actBy}" Turned on Answering Machine </div>
                } else {
                    actToShow = <div className={'actDetail'}>"{actBy}" Turned off Answering Machine </div>
                }

            } else if (a === "AAC") {
                if (act.details.enabled === true) {
                    actToShow =
                        <div className={'actDetail'}>"{actBy}" Turned on AAC for "{act.details.duration}" hours </div>
                } else {
                    actToShow = <div className={'actDetail'}>"{actBy}" Turned off AAC </div>
                }

            } else if (a === "DND") {
                if (act.details.enabled === true) {
                    actToShow =
                        <div className={'actDetail'}>"{actBy}" Turned on DND for "{act.details.duration}" hours </div>
                } else {
                    actToShow = <div className={'actDetail'}>"{actBy}" Turned off DND </div>
                }

            } else if (a === "URGENT_CALLS") {


                if (act.details.enabled === true) {
                    actToShow = <div className={'actDetail'}>"{actBy}" Turned on Urgent Calls if someone calls
                        "{act.details.call_count}" times in "{act.details.urgent_window_duration} minutes" </div>
                } else {
                    actToShow = <div className={'actDetail'}>"{actBy}" Turned off Urgent Calls </div>
                }

            } else {
                actToShow = <div className={'actDetail'}>{actBy} Action
                    Type: {at} Action:{a} Details: {JSON.stringify(act.details)}  </div>
            }


        } else {
            actToShow = defaultShow
        }

        actToShow = <div>
            <div style={{fontSize: '8pt', fontWeight: 'bold'}}>{actWhen}</div>
            {actToShow}</div>

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div style={{
                        backgroundColor: '#aafcff',
                        margin: '3px 3px 3px 20px',
                        padding: '3px',
                        borderRadius: '5px',
                        textAlign: 'left'
                    }}>

                        {actToShow}

                    </div>
                </div>

            </div>
        );
    }
}
export {ShowActivity};

