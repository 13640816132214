import React, {Component} from 'react';
import './Admin.css';
import {react_deployment} from "../util/config";

import PNAdmin from './PushNotifications/PNAdmin';
import NotificationAdmin from './Notifications/NotificationAdmin';
import UserAdmin from './Users/UserAdmin';
import MobileAdmin from './Mobiles/MobileAdmin';
import IMPBoxAdmin from './IMPBox/IMPBoxAdmin';
import ContextHelpAdmin from './ContextHelp/ContextHelpAdmin';
import ContactAdmin from "./Contacts/ContactAdmin";
import IMPSim from "./IMPBoxSim/IMPSim";
import AdminTools from "./AdminTools/AdminTools";
import InviteAdmin from "./Invites/InviteAdmin";
import FirmwareAdmin from "./Firmware/FirmwareAdmin";
import AppSim from "./App/AppSim";
import StatsMain from "./Stats/StatsMain";
import ErrorLogAdmin from "./ErrorLogs/ErrorLogAdmin";
import HomeMain from "./Home/HomeMain";
import Nav from './Nav/Nav'
import UserPicker from './Users/UserPicker'
import KPIS from './KPIS/KPIS';
import {getUser, getAllUsers } from '../util/apiServiceUtil';
import AppUserContext from "../contexts/AppUserContext";
import AdminManagement from "./Management/AdminManagement";
import SubscriptionAdmin from "./Subscriptions/SubscriptionAdmin";

class Admin extends Component {

    static contextType = AppUserContext;


    constructor(props) {
        super(props);
        console.log("In Admin Constructor");
        this.state = {pageToShow: "Home", userContext: {}, users: []};
    }

    changePage(page) {
        this.setState({pageToShow: page})
    }

    getAllUsers(e) {

        console.log("Calling getAllUsers from Admin")

        getAllUsers(
            (users) => {
                console.log("users - " + JSON.stringify(users));
                this.setState({users: users.users});
            },
            (error) => {

                console.log("ERROR in getAllUsers =  " + error);
                this.setState({"error": error});
            })
    }

    componentDidMount() {

        console.log("Admin did mount");
        this.getAllUsers();
    }

    getUserContext(user_id) {

        console.log("get user - " + user_id);

        if (user_id === undefined || user_id === "00") {
            return;
        }

        getUser(user_id, (user) => {
                this.setState({userContext: user});
            },
            (error) => {
                console.log("ERROR in getUser =  " + error);
                this.setState({"error": error});
            })
    }


    render() {

        let uc = this.state.userContext;
        console.log("User = " + JSON.stringify(uc.user));
        let nameToShow = "";
        if (uc.user) {
            nameToShow = uc.user.PII.full_name + " (" + uc.user.PII.nickname + ")";
        }

        let username = localStorage.getItem('app_username');
        if (!username) {
            username = "Login As User to proceed."
        }

        let userPicker =  <div className={'userContextHolder'}>
            Select User Context:
            <UserPicker users={this.state.users} onChange={(user_id) => this.getUserContext(user_id)}/>
            <div style={{'float': 'right', 'position': 'relative', 'top': '-20px'}}> {nameToShow}</div>
        </div>

        const contextNameToShow = this.context.app_username;
        //this.getUserContext(contextNameToShow);

        let userPickerContext =  <div className={'userContextHolder'}>
            Logged In App User:
            {/*<UserPicker users={this.state.users} onChange={(user_id) => this.getUserContext(user_id)}/>*/}
            <div style={{'float': 'right', 'position': 'relative', 'top': '0px'}}> {contextNameToShow}</div>
        </div>

        let pts = "";

        if (this.state.pageToShow === 'Notifications') {
            // pts = <div> {userPickerContext} <NotificationAdmin userContext={this.state.userContext}/> </div>
            pts = <div> {userPicker} <NotificationAdmin userContext={this.state.userContext}/> </div>
        } else if (this.state.pageToShow === 'Users') {
            pts = <UserAdmin/>
        }else if (this.state.pageToShow === 'Error Logs') {
            pts = <ErrorLogAdmin/>
        } else if (this.state.pageToShow === 'Push Notifications') {
            pts = <div> {userPicker} <PNAdmin userContext={this.state.userContext}/> </div>
        } else if (this.state.pageToShow === 'Admin Tools') {
            pts = <div> <AdminTools userContext={this.state.userContext}/> </div>
        } else if (this.state.pageToShow === 'Invites') {
            pts = <InviteAdmin/>
        } else if (this.state.pageToShow === 'Firmware') {
            pts = <FirmwareAdmin/>
        } else if (this.state.pageToShow === 'Mobiles') {
            pts = <MobileAdmin userContext={this.state.userContext}/>
        }  else if (this.state.pageToShow === 'IMP Boxes') {
            pts = <IMPBoxAdmin/>
        }  else if (this.state.pageToShow === 'Context Help') {
            pts = <ContextHelpAdmin/>
        } else if (this.state.pageToShow === 'Contacts') {
            pts = <div> {userPicker} <ContactAdmin userContext={this.state.userContext}/> </div>
        } else if (this.state.pageToShow === 'IMP Sim') {
            pts = <div> <IMPSim/> </div>
        } else if (this.state.pageToShow === 'App Sim') {
            pts = <div> <AppSim/> </div>
        } else if (this.state.pageToShow === 'Stats') {
            pts = <StatsMain users={this.state.users} userContext={this.state.userContext}/>
        } else if (this.state.pageToShow === 'Home') {
            pts = <HomeMain/>
        }else if (this.state.pageToShow === 'KPIs') {
            pts = <KPIS/>
        } else if (this.state.pageToShow === 'Admin Manager') {
            pts = <AdminManagement/>
        } else if (this.state.pageToShow === 'Subscriptions') {
            pts = <SubscriptionAdmin />
        }



        let bgc = "white";
        if(react_deployment=="LOCAL"){
            bgc = "#f599ab"
        }else if(react_deployment=="DEV"){
            bgc = "#f5c999"
        }else if(react_deployment=="LAB"){
            bgc = "#189bf5"
        }else if(react_deployment=="STAGE") {
            bgc = "#c4f599"
        }


        return (
            <div style={{width: "1050px"}}>
                <div>
                    <Nav currentNav={this.state.pageToShow} deployment={react_deployment} onNav={(page => this.changePage(page))} background={bgc}  setToken={this.props.setToken} username={this.props.username} role={this.props.role}/>
                </div>
                <div>
                    {pts}
                </div>
            </div>
        );

    }
}

export default Admin;
