import React, {Component} from 'react';

class HomeMain extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing HomeMain")
        this.state= {"error" : ""};
        this.state= {"stats" : {}};
    }

    componentDidMount() {
        console.log("HomeMain did mount");
    }


    render() {
        return (
            <div className={'document'}>
                <div></div>
                <div className={'heading'}> System Instructions.</div>

                <div className={'paragraph'}>Firstly Install the iOS App via TestFlight. You will receive an email with a link to get you started.</div>

                <div className={'paragraph'} >After installation create an account via “Get Started”. Enter an unique email.
                    If you want to re-use an email you can delete an existing account and all dependent data from the <i>Users</i> section of this site.
                    You can only register a device/mobile one time. If you try to go through registration  on a previously registered device you will get a <i>this device is already registered to another user</i> error.
                    In this case you can use the <i>Users</i> section to clean up the old device data by deleting the user that it was registered with.
                    When entering a phone number in the IMPBox Setup Page it should be in the format 4151112222. We will provide better validation/ for this in the future. The Wifi Data collected during setup is not being used currently.
                </div>

                <div className={'paragraph'}>You can automatically or manually import your AddressBook contacts as GreenList contacts as part of registration. Contacts that you do not select will be imported as Unassigned.</div>

                <div className={'paragraph'}>To  generate a Call/Message go to  the <i>IMP Simulator</i> section of this site.</div>

                        <div>
                            <div>1. Create Message (using a local wav file).</div>
                            <div>2. Generate an "Unknown Caller" Call in Log Call with a phone number not already in your imported contacts.</div>
                        </div>

                <div className={'paragraph'}> You will need to  know your "IMPBox Id" for each of these calls. You can get this via your “User Details” in the <i>Users</i> tab or the <i>IMPBox</i> Tab.</div>

                <div className={'paragraph'}>Navigate to the <i>Callers</i> tab on the iOS App to see the list of Unknown callers. You will have the opportunity there to  RedList them, GreenList them or delete them.</div>

                <div className={'paragraph'}>You can also set your outgoing message via Devices-> Your "IMPBox Name" -> Greeting</div>

                <div className={'paragraph'}>The current deployed release is v15.1.</div>


            </div>
        );
    }
}
export default HomeMain;
