import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {login} from '../../util/apiServiceUtil';


import './Login.css';


export default function Login({setToken}) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const loginUser = async credentials => {

        console.log("Login Data = " + JSON.stringify(credentials));

        await login(credentials, returnDetails => {

                console.log("API return from login " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    token: returnDetails.token,
                };

                console.log("TOKEN = " + details.token);
                setToken(returnDetails);

            },
            (error) => {
                console.log("ERROR in login =  " + JSON.stringify(error));
                setError(error);
            });

    }

    const handleSubmit = async e => {
        e.preventDefault();
        //Todo Error Handing
        setError("");
        await loginUser({
            "username": username,
            "password": password
        }).then(() => {
            console.log("Finished loginUser ..." );
        }).catch(e => {
            console.error(e)
            setError(e);
        })
    }

    return (
        <div className="login-wrapper">
            <h1>IMP Admin Login</h1>
            <div className="error">{error}</div>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Username</p>
                    <input type="text" onChange={e => setUserName(e.target.value)}/>
                </label>
                <div/>
                <label>
                    <p>Password</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div className="formElement">
                    <button type="submit">Submit</button>
                </div>
            </form>

        </div>
    )
}


Login.propTypes = {
    setToken: PropTypes.func.isRequired
}