import React, {Component} from 'react';
import {createSubWithUser, getProducts} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";
import _ from "lodash";



class AddUserWithSubscription extends Component {


    constructor(props) {
        super(props);
        console.log("In UserCreate constructor");
        this.state = {
            "products": [],
            "user": {
                "email": "",
                "first_name": "",
                "last_name": "",
                "subscription" : {
                    "platform_product_id" : "prod_PLPG1X7zAdrzN2"
                }
            }
        }
    }

    componentDidMount() {
        console.log("AddUserWithSubscription did mount");
        this.getAllProducts();
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        const target = e.target;
        const val = target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        console.log("changeText: updating state with  " + JSON.stringify(change));
        this.setState(change);
        console.log("changeText: after updating state :  " + JSON.stringify(this.state["user"]));

    }

    changeProduct(e) {

        const product = e.target.value;

        console.log(`Picked Prod ${product}`)

        // Choose Selected Product  from  State.
        let chosen = _.find(this.state.products, {platform_product_id:product});

        if(!!chosen) {
            chosen.order_id = Math.random().toString().slice(2, 12);
            chosen.currency = "USD";

            const myRe = /.*\$([0-9]+)/g;
            const price = myRe.exec(chosen.name);

            console.log(`REGEX: ${JSON.stringify(price)}`)
            chosen.price = !!price && !!price[1] ? `${price[1]}.00` : "$50.00";

            console.log(`ChoseProd ${JSON.stringify(chosen)}`)

            console.log(`Setting user dot subscription  :   ${JSON.stringify([chosen])}`);

            // let chosenSub  = {user : {subscription: chosen}} ;
            // let newState = Object.assign({user: this.state.user}, chosenSub  );

            let stateToChange = this.state.user;
            stateToChange.subscription = chosen;

            this.setState({user: stateToChange });
        } else {
            console.log("No Product Chosen.");
            this.setState({user: {subscription: {}}});
        }

    }

    getAllProducts() {

        this.setState({"details": {}});
        this.setState({"error": ""});

        getProducts(returnDetails => {

                console.log("API return from getProducts " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    products: returnDetails.products,
                };

                this.setState({"products": details.products});

            },
            (error) => {

                console.log("ERROR in getProducts =  " + JSON.stringify(error));
                let errStr = "";
                if (!!error.details) {
                    errStr = error.details;
                } else {
                    errStr = JSON.stringify(error);
                }
                this.setState({"error": errStr});
            });

    }


    createUserWithSubscription() {
        console.log("Starting the User Creation Process");


        let userWithSub = {
            "email" : this.state.user.email,
            "first_name" : this.state.user.first_name,
            "last_name":this.state.user.last_name,
            "subscription":this.state.user.subscription
        };

        if(!!userWithSub.subscription) {
            userWithSub.subscription.origin = "ADMIN";
        }





        createSubWithUser(userWithSub, (returnDetails) => {

                console.log("API return from registerUserWithDevice " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success
                };
                this.setState({"details": details});

                let success_message;

                if (!!returnDetails && !!returnDetails.subscription && !!returnDetails.subscription.message){
                    success_message = returnDetails.subscription.message;
                } else {
                    success_message = "Created User: " + returnDetails.email;
                }

                /*
                 API return from registerUserWithDevice
                 {"email":"qwqwq@1212.com","subscription":{"message":"Only processing products of type impbox at the moment"},
                 "success":true,"req_id":"9439aaa6-1e36-44cf-93b4-85aad201ee36"}
                 */

                this.props.alert.success(success_message);
                this.props.refreshUsers();
            },
            (error) => {

                console.log("ERROR in createNewUser =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }


    render() {


        let success_message;


        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let products = this.state.products;
         let prods = [
         //    <option key={"prodSelect_00"} value={'00'}>Select Product...</option>
         ];

        if (!products || products.length === 0) {
            return <div></div>
        }

        for (let prod of products) {
            let thisProdShow = <option
                key={"prodSelect_" + prod._product_id}
                value={prod.platform_product_id}>{ `${prod.name} (${prod.platform})`}
            </option>;

            prods.push(thisProdShow);
        }


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Create New User with Subscription'}>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Email:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.email}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.email')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>First Name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.first_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.first_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>


                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Last Name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.user.last_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'user.last_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Product</div>
                                    <div className={'fieldInput'}>

                                        <select id="allProducts" value={this.state.user.subscription.platform_product_id}
                                                onChange={(event, fld_id) => this.changeProduct(event)}>
                                            {prods}
                                        </select>

                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                            </div>

                            <button onClick={() => this.createUserWithSubscription()}>Create User</button>
                        </div>

                    </div>


                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(AddUserWithSubscription);
