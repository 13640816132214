const crypto = require('crypto');

module.exports.hashPhoneNumberIdentifier = function (identifier) {



        console.log("hashPhoneNumberIdentifier: creating hashed identifier");

        try {
            const secret = "qwertyXYZ";
            const hashedIdentifier = crypto.createHmac('sha256', secret)
                .update(identifier)
                .digest('hex');

            return(hashedIdentifier);
        } catch (err){
            console.log(`Error creating hash. ${err.message}`);
            return(err);
        }


};

