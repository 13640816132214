import React, {Component} from 'react';
import {getShortPhoneNumberListApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible"
import IMPBoxContext from "../../../contexts/IMPBoxContext";
import ApiInfo from "../../Util/ApiInfo";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


class GetShortNumberList extends Component {

    static contextType = IMPBoxContext;
    //Api Details
    api = "getShortPhoneNumberList";
    service = "IMPBox";
    id = "impbox_uuid";

    constructor(props) {
        super(props);
        console.log("In GetShortNumberList constructor");
        this.state = {
            "error": "",
            "phone_number_list": null,
            "details": null
        };
    }

    changeText(e, fld_id) {

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        this.setState(no);
    }

    getShortPhoneNumberList(impbox_id) {

        this.setState({"phone_number_list": null});
        this.setState({"details": null});
        this.setState({"error": null});
        this.setState({"success": null});


        let cl = {};
        cl.impbox_id = impbox_id;
        cl.updated_since = this.state.updated_since;
        cl.number_of_phonenumbers = this.state.number_of_phonenumbers;
        cl.starts_at = this.state.starts_at;

        getShortPhoneNumberListApi(cl, (returnDetails) => {

                console.log("API return from getShortPhoneNumberListApi " + JSON.stringify(returnDetails));

                this.setState({"phone_number_list": returnDetails.phone_number_list});
                this.setState({"details": returnDetails.details});
                if (returnDetails.success === true) {
                    this.setState({"success": "Successfull Call"});
                } else {
                    this.setState({"success": "UnSuccessfull Call"});
                }

            },
            (error) => {

                console.log("ERROR in getShortPhoneNumberListApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        //Used to get the API Info
        let id = this.context.impbox_uuid;

        let contact_data = <div></div>;
        if (this.state.phone_number_list) {
            contact_data =
                <div className={'fieldHolder'} style={{textAlign: 'left', 'height': '250px', overflow: "auto"}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.phone_number_list} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }
        let meta_data = <div></div>;

        if (this.state.details) {
            meta_data =
                <div className={'fieldHolder'} style={{textAlign: 'left'}}>
                    <div className={'fieldLabel'}></div>
                    <JSONPretty id="json-pretty" data={this.state.details} theme={JSONPrettyMon}></JSONPretty>
                    <div className={'fieldEnd'}></div>
                </div>
        }


        return (

            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div style={{margin: '50', border: '0px solid #DDD'}}>
                        <ImpCollapsible collapsed={true} header={'Get ShortPhoneNumber List'}>


                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            <div><p style={{color: 'green'}}>{this.state.success}</p></div>
                            <ApiInfo api={this.api} service={this.service} id={id}/>


                            <div style={{'margin-left': '180px', 'margin-right': '150px', border: '0px solid #AAA'}}>
                                <div className={'fieldHolder'} style={{border: '0px solid #DDD'}}>
                                    <div className={'fieldLabel'}>IMPBox ID:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.context.impbox_id} placeholder='7783'
                                               onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                               size={'30'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'} style={{border: '0px solid #DDD'}}>

                                    <div className={'fieldLabel'}>Updated Since:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.updated_since} placeholder='YYYY-MM-DDTHH:mm:ssZ'
                                               onChange={(event, fld_id) => this.changeText(event, 'updated_since')}
                                               size={'30'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>

                                </div>

                                <div className={'fieldHolder'} style={{border: '0px solid #DDD'}}>

                                    <div className={'fieldLabel'}>No. of phonenumbers:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.number_of_phonenumbers} placeholder='75'
                                               onChange={(event, fld_id) => this.changeText(event, 'number_of_phonenumbers')}
                                               size={'30'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>

                                </div>

                                <div className={'fieldHolder'} style={{border: '0px solid #DDD'}}>

                                    <div className={'fieldLabel'}>Starts At:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.starts_at} placeholder='0'
                                               onChange={(event, fld_id) => this.changeText(event, 'starts_at')}
                                               size={'30'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>

                                </div>


                                <div style={{'margin-right': '30px'}} >
                                    <button onClick={() => this.getShortPhoneNumberList(this.context.impbox_id)}>Get Short PhoneNumber List
                                    </button>
                                </div>


                            </div>

                            {meta_data}
                            {contact_data}

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {GetShortNumberList};

