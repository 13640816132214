import React, {Component} from 'react';
import {getAllUsers} from "../../util/apiServiceUtil";


class UserPicker extends Component {

    constructor(props) {
        super(props);
        let u_tmp = [
            {user_id: 498, PII: {email: "test_owner_8129510293@itsmyphone.com"}}
        ];

        console.log("Constructing UserPicker");
        this.state = {
            mobile_id: undefined,
            users: [],
            userFilter:""
        };
    }

    pickUser(e) {
        this.setState({mobile_id: e.target.value});
        this.props.onChange(e.target.value);
    }

    setFilter(e){
        this.setState({userFilter: e.target.value});
    }

    refreshUsers()  {

        console.log("Calling getAllUsers from UserPicker")

        getAllUsers(
            (users) => {
                console.log("users - " + JSON.stringify(users));
                this.setState({users: users.users});
            },
            (error) => {

                console.log("ERROR in getAllUsers =  " + error);
                this.setState({"error": error});
            })

    }


    componentDidMount() {
        console.log("UserPicker did mount");
        this.refreshUsers();
    }

    render() {

        let users = this.state.users;
        let usrs = [
            <option key={"usrSelect_00"} value={'00'}>Select User...</option>
        ];

        if (!users || users.length === 0) {
            return <div></div>
        }

        for (let usr of users) {

            if(usr.PII.email.toUpperCase().includes(this.state.userFilter.toUpperCase()))
            {
            let thisUsrShow = <option
                key={"mobSelect_" + usr.user_id}
                value={usr.user_id}>{usr.PII.email}
            </option>;

            usrs.push(thisUsrShow);
            }
        }

        return (
            <div>
                <div style={{padding:"3px"}}>
                    Search Users:<input type={"text"} onChange={this.setFilter.bind(this)} value={this.state.userFilter} size={"40"}/>
                </div>
                <select onChange={this.pickUser.bind(this)}>
                    {usrs}
                </select>
            </div>

        );
    }
}

export default UserPicker;
