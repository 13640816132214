import React, {Component} from 'react';
import {createContactApi} from "../../util/apiServiceUtil";
import {ImpCollapsible} from "../Util/ImpCollapsible";


class ContactCreate extends Component {


    constructor(props) {
        super(props);
        console.log("In ContactCreate constructor");
        this.state = {
            "contact": {
                "first_name": "",
                "last_name": "",
                "list": "UNASSIGNED",
                "addressbook_type": "IMP",
                "phone_number": ""
            },
            create_count: 0
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.mobile_id !== prevProps.mobile_id) {
            console.log(`ZZZ mobile_id = ${this.props.mobile_id} `)
            this.setState({mobile_id: this.props.mobile_id});
        }
        if (this.props.box_mobile_id !== prevProps.box_mobile_id) {
            console.log(`YYY box_mobile_id = ${this.props.box_mobile_id} `)
            this.setState({box_mobile_id: this.props.box_mobile_id});
        }
    }

    changeText(e, fld_id) {

        let val = e.target.value;
        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        this.setState(change);

    }


    handleCountChange(e){

        // console.log(`${JSON.stringify(e.target)}`)

        const val = e.target.value;
        this.setState({"create_count": val});

    }

    createContact(box_mobile_id) {

        console.log(`Calling  createContact with ${box_mobile_id}`)

        let contact = {};
        contact= this.state.contact;

        let request = {box_mobile_id: box_mobile_id, contact: contact };

        this.setState({"create_error": undefined});
        this.setState({create_response: undefined});

        createContactApi(request,
            (create_response) => {

                let respString= `Created  contact.`;
                console.log(`Response from createContactApi : ${JSON.stringify(create_response)} `)

                this.setState({create_response: respString});
                this.props.onCreate(this.state.mobile_id);
            },
            (create_error) => {

                console.log("ERROR in createContact =  " + JSON.stringify(create_error));
                this.setState({"create_error": JSON.stringify(create_error)});
            },
        );

    }


    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let create_error = this.state.create_error;
        let create_response = this.state.create_response;

        return (
            <div style={layoutStyle}>

                <ImpCollapsible collapsed={true} header={'Create new Contact'}>

                    <div style={{'fontSize': '10pt'}}>

                        {/*<div><p style={{color: 'red'}}>{this.state.error}</p></div>*/}
                        {/*{create_response}*/}
                            <div><p style={{color: 'red'}}>{create_error}</p></div>
                            <div><p style={{color: 'green'}}>{create_response}</p></div>

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>first_name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.contact.first_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'contact.first_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>last_name:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.contact.last_name}
                                               onChange={(event, fld_id) => this.changeText(event, 'contact.last_name')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>



                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>list:</div>
                                    <div className={'fieldInput'}>
                                        <select id={'notification_type'} value={this.state.contact.list}
                                                onChange={(event, fld_id) => this.changeText(event, 'contact.list')}>
                                            <option value={"UNASSIGNED"}>UNASSIGNED</option>
                                            <option value={"GREEN"}>GREEN</option>
                                            <option value={"RED"}>RED</option>
                                        </select>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>addressbook_type:</div>
                                    <div className={'fieldInput'}>
                                        <select id={'notification_type'} value={this.state.contact.addressbook_type}
                                                onChange={(event, fld_id) => this.changeText(event, 'contact.addressbook_type')}>
                                            <option value={"IMP"}>IMP</option>
                                            <option value={"iOS"}>iOS</option>
                                            <option value={"android"}>android</option>
                                        </select>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>phone_number:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.contact.phone_number}
                                               onChange={(event, fld_id) => this.changeText(event, 'contact.phone_number')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>


                            </div>

                            <button onClick={() => this.createContact(this.state.box_mobile_id)}>Create Contact</button>
                        </div>
                    </div>


                </ImpCollapsible>


                {/*<span>Create Single Contact</span>*/}

                {/*<div style={layoutStyle}>*/}
                {/*    <div><p style={{color: 'red'}}>{create_error}</p></div>*/}
                {/*    <div><p style={{color: 'green'}}>{create_response}</p></div>*/}


                {/*    <button onClick={() => this.createContactSet(this.state.box_mobile_id, this.state.create_count)}>Create {this.state.create_count} Contact(s) for*/}
                {/*            mobile_id {this.state.mobile_id}</button>*/}
                {/*    <input type="number" pattern="[0-9]*"*/}
                {/*           onInput={this.handleCountChange.bind(this)} value={this.state.create_count} />*/}
                {/*</div>*/}
            </div>

        );


    }
}

export default ContactCreate;