import React, {useState, useEffect} from 'react';


import {validateEmail} from "../util/apiServiceUtil";

import {
    useLocation,
} from "react-router-dom";


function PageBody({theSuccess, theName}){

    let nameSpan = <span></span>;
    let messageSpan =
        <span>
            <p>Well, that's strange. We had a problem with your Email Validation.</p>
            Please, go back to the imp app and try again. If the problem persists try contacting support at: <a style={{"text-decoration": 'underline', "font-weight": "700"}} href="mailto:IMP@joinimp.com"> imp@joinimp.com</a>
          </span>

     ;

    console.log("TheSuccess = " + JSON.stringify(theSuccess));

    if (theSuccess == true){
        nameSpan = (
            <div className="header-text center">
            <span>Hi {theName}.</span>
            </div>
        );

        messageSpan = (
            <span>
            <p>Congratulations, you are now verified.</p>
            Make sure to print out this message and post it on your wall, so all
            your friends know you are verified as well.
          </span>
        );
    }


    return (
        <div className="email-template grid">
            <div className="content-email grid">
                <div className="header grid center">
                    <div className="image center">
                        <img width="234" height="94" src="https://s3.amazonaws.com/imp-cdn.itsmyphone.com/imp_hello_green.png" alt="" />
                    </div>
                    <div className="header-text center">
                        {nameSpan}
                    </div>

                </div>
                <div className="content grid">
                    <div className="content-text center" style={{paddingBottom:"20px"}}>
                        {messageSpan}
                    </div>
                </div>
                <div className="footer">
                    <div className="image">
                        <img width="45px" height="45px" src="https://s3.amazonaws.com/imp-cdn.itsmyphone.com/just_imp.png" alt="" /> <a className="footer_link" href={'http://joinimp.com'}>joinimp.com</a>

                    </div>
                </div>
            </div>
        </div>
    )

}





function EmailValidate() {

    const [success, setSuccess] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [message, setMessage] = useState("");

    let location = useLocation();
    let query = new URLSearchParams(location.search);
    let email_validation_code = query.get("evc");

    const layoutStyle = {
        margin: 20,
        padding: 20,
        border: '1px solid #DDD'
    };

    const callValidateEmail = ( email_validation_code) => {

        console.log("Calling callValidateEmail with code = " + JSON.stringify(email_validation_code) );

        validateEmail( email_validation_code, (returnDetails) => {

                console.log("API return from validateEmail " + JSON.stringify(returnDetails));
                setSuccess(returnDetails.success);
                if (returnDetails.success === true) {
                    setMessage("Email Validated.");
                    setFullName(returnDetails.full_name);
                    return Promise.resolve(returnDetails);
                } else {
                    setMessage(returnDetails.error);
                    return Promise.reject(returnDetails);
                }
            },
            (error) => {

                console.log("ERROR in validateEmail =  " + JSON.stringify(error));
                setMessage(error);
                return Promise.reject(error);

            });
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await callValidateEmail(
                email_validation_code
            );
           // All state logic inside callValidateEmail.
        };

        fetchData();
    }, []);

    const greeting = 'Hello Function Component!';


    return (
        <div>
            <PageBody theSuccess={success} theName={fullName} />
        </div>
    )



}

export default EmailValidate;
