import React, {Component} from 'react';


import {uploadOutgoingMessageApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";
import AppUserContext from "../../../contexts/AppUserContext";


class UploadOutgoingMessage extends Component {

    static contextType = AppUserContext;


    constructor(props) {
        super(props);
        console.log("In newMessage constructor");
        this.state = {
            "error": null,
            "details": null,
            "impbox_id": null,
            "updateMessage_id": null,
            "settings_id": null,
            "updateMessage_msgId": null,
            "selectedFile": null
        };
    }

    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);

        let val = e.target.value;
        let no = {};

        no[fld_id] = val;

        console.log("changeText: updating state with  " + JSON.stringify(no));

        this.setState(no);

        console.log("changeText: after updating state :  " + this.state[fld_id]);

    }

    onChangeHandler = event => {

        console.log(event.target.files[0])

        this.setState({
            selectedFile: event.target.files[0]
        })

        console.log("FILE in state = " + JSON.stringify(this.state.selectedFile));


    }


    newMessage() {

        this.setState({"details": {}});
        this.setState({"error": ""});


        let um = {};
        um.impbox_id = this.state.impbox_id;
        um.debug = true;

        console.log("newMessage Data = " + JSON.stringify(um));
        console.log("newMessage File = " + this.state.selectedFile);

        uploadOutgoingMessageApi(um, this.state.selectedFile, (returnDetails) => {

                console.log("API return from newMessage " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    updateMessage_id: returnDetails.debug.updateMessage_id,
                    outgoing_message_link: returnDetails.debug.outgoing_message_link,
                    settings_id: returnDetails.debug.settings_id,
                    updateMessage_msgId: returnDetails.debug.updateMessage_msgId,
                };
                this.setState({"details": details});

            },
            (error) => {

                console.log("ERROR in newMessage =  " + JSON.stringify(error));
                this.setState({"error": error});
            });

    }

    render() {

        let success_message;
        if ( this.state.details && this.state.details.updateMessage_id) {
            success_message =
               <div>
                <div><p style={{color: 'green'}}>Message created: Id = {this.state.details.updateMessage_id}</p></div>
                <div><p style={{color: 'green'}}>Message Link = {this.state.details.outgoing_message_link}</p></div>
                <div><p style={{color: 'green'}}>Settings Id = {this.state.details.settings_id}</p></div>
                <div><p style={{color: 'green'}}>UpdateMessageId = {this.state.details.updateMessage_msgId}</p></div>
               </div>
        }

        let api = "uploadOutgoingMessage";
        let authAs = !!this.context.app_username ? this.context.app_username : "None";

        return (
            <div>

                <div style={{'fontSize': '10pt'}}>
                    <div>
                        <ImpCollapsible collapsed={true} header={'Upload Outgoing Message'}>

                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            {success_message}

                            <div><p style={{color: 'grey'}}>
                                <b>API</b>: {api}
                                <b> App User Auth As</b>: {authAs}
                            </p></div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>IMPBox ID:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox_id}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox_id')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Message File:</div>
                                <div className={'fieldInput'}>
                                    <input type="file" name="file" onChange={this.onChangeHandler}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <button onClick={() => this.newMessage()}>Upload Message</button>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export {UploadOutgoingMessage};

