import React, {Component} from 'react';
import {ImpCollapsible} from "../../Util/ImpCollapsible"
import {SettingsDetails} from "./SettingsDetails";
import {updateSubscriptionApi} from "../../../util/apiServiceUtil";

class IMPBoxSubscription extends Component {

    constructor(props) {
        super(props);
        console.log("In IMPBoxSubscription constructor");
        this.state = {
            error: null,
            success: null,
            currentIMPBox: null,
        };
    }

    updateSubscription(level) {
        console.log(`Setting Subscription App Alert to ${level}... `)


        let cl = {};
        cl.impbox_id = this.state.currentIMPBox.impbox_id;
        cl.subscription_id = this.state.currentIMPBox.Subscription.subscription_id;
        cl.app_alert = level;

        document.getElementById('bt_none').style.background = '#ffffff';
        document.getElementById('bt_warn').style.background = '#ffffff';
        document.getElementById('bt_force').style.background = '#ffffff';
        document.getElementById(`bt_${level}`).style.background = '#b3b3b3';


        updateSubscriptionApi(cl, (returnDetails) => {
                //Update currentIMPBox.Subscription.app_alert
                console.log(`Response from updateSubscriptionApi ${JSON.stringify(returnDetails)} `);
                let subToChange = this.state.currentIMPBox;
                subToChange.Subscription.app_alert = level;
                this.setState({currentIMPBox: subToChange});
                this.setState({success: "Subscription Updated..."});

            },
            (error) => {
                console.log("ERROR in updateSubscriptionApi =  " + JSON.stringify(error));
                this.setState({"error": error});
            });


    }

    componentDidUpdate(prevProps) {


        if (prevProps.currentIMPBox !== this.props.currentIMPBox) {
            console.log(`IMPBoxSubscription: in componentDidUpdate... Prev: ${prevProps.history} Current: ${this.props.history}  `)

            if (!!this.props.currentIMPBox) {
                this.setState({
                    currentIMPBox: this.props.currentIMPBox
                });
                this.setState({success: null});

            }
        }
    }

    getButtonStyle(button){

        let level = this.state.currentIMPBox.Subscription.app_alert;
        let but_background = "#ffffff";

        if ( button === level ) {
            but_background = "#b3b3b3";
        }

        const buttonStyle = {
            cursor:'pointer',
            // float:'left',
            marginLeft:'2px',
            border: '1px solid #DDD',
            // borderRadius:'15px',
            background: but_background,
            padding: '5px'
        };

        return buttonStyle;


    }

    render() {

        console.log(`Rendering IMPBoxSubscription with ${JSON.stringify(this.state.currentIMPBox)} Props: ${this.props.impbox_id} `)

        let primaryPlan = [], pp_key = "pp_001";
        let ibDetails = [];
        console.log(`currentIMPBox ${JSON.stringify(this.state.currentIMPBox)}`);
        if (!!this.state.currentIMPBox && !!this.state.currentIMPBox.Subscription && !!this.state.currentIMPBox.Subscription.subscription_id ) {
            if (!!this.state.currentIMPBox.Subscription.Plan) {

                var date = new Date(this.state.currentIMPBox.Subscription.end_date);
                let vtDetails = date.toLocaleDateString('en-us', {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                });

                primaryPlan.push(<span key={"p_01"}>{this.state.currentIMPBox.Subscription.Plan.primary_plan}</span>)
                primaryPlan.push(<span key={"p_02"}
                                       style={{fontSize: "smaller"}}>&nbsp;&nbsp;(valid to - {vtDetails})</span>)
                pp_key = this.state.currentIMPBox.impbox_id;
                ibDetails.push(<div style={{marginBottom: "10px"}} key={pp_key}><b>Plan:</b> {primaryPlan}</div>)
                ibDetails.push(<div style={{marginBottom: "10px"}} key={`${pp_key}_01`}><b> ImpBox Subscription App
                    Alert Status:</b> {this.state.currentIMPBox.Subscription.app_alert}</div>)
                ibDetails.push(<div style={{marginBottom: "10px"}} key={`${pp_key}_02`}>
                    <button key={"bt_01"} id={"bt_none"} onClick={() => this.updateSubscription("none")} style={this.getButtonStyle("none")}>None</button>
                    <button key={"bt_02"} id={"bt_warn"} onClick={() => this.updateSubscription("warn")} style={this.getButtonStyle("warn")}>Warn</button>
                    <button key={"bt_03"} id={"bt_force"} onClick={() => this.updateSubscription("force")} style={this.getButtonStyle("force")}>Force</button>
                </div>)

            } else {
                console.log("Rendering IMPBoxSubscription with no Plan ")
            }
        } else {
            console.log("Rendering IMPBoxSubscription with no Subscription ")
            ibDetails.push(<div><span key={"p_01"}>No Subscription</span></div>)
        }


        return (
            <ImpCollapsible collapsed={true} header={'Subscription'}>
                <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                <div><p style={{color: 'green'}}>{this.state.success}</p></div>
                {ibDetails}
            </ImpCollapsible>
        );
    }

}

export {IMPBoxSubscription};
