import React, {Component} from 'react';


class ImpCollapsible extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: props.collapsed,
            header: props.header
        };
    }

    toggleCollapse()
    {
        let newState = !this.state.collapsed;
        console.log(newState);
        this.setState({collapsed:newState});
    }

    render() {

        let expandText = this.props.expandText || "expand";

        let body;
        let but;
        if(this.state.collapsed)
        {
            body = <div></div>
            but=<div>{expandText}</div>
        }else
        {
            body =  <div> {this.props.children}</div>
            but=<div>collapse</div>
        }


        return (
            <div>
                <div className={'sectionHead'}>
                    {this.state.header}
                    <div style={{'float': 'right','paddingRight':'20px','cursor':'pointer'}} onClick={()=>this.toggleCollapse()}>{but}</div>
                </div>
                <div className={'sectionBody'}>
                    {body}
                </div>
            </div>
        )
    }
}
export {ImpCollapsible}