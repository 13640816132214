import React, {Component} from 'react';
import IMPBoxContext from "../../contexts/IMPBoxContext";


class IMPBoxList extends Component {

    static contextType = IMPBoxContext;

    constructor(props) {
        super(props);
        console.log("Constructing IMPBoxList")
        console.log(`IMPBoxList: props: this.props.IMPBoxes  ${this.props.IMPBoxes}`);

        this.state = {
            "impboxes" : this.props.IMPBoxes,
            "filter":'both',
            "planFilter":[1,2,3,4,5,6],
            "showStandby":false
        };
    }

    filterImpboxes(filter)
    {
        this.setState({'filter':filter})
    }

    componentDidUpdate(prevProps) {

        console.log(`IMPBoxList: props: this.props.IMPBoxes  ${this.props.IMPBoxes}`);

        const currentIMPBoxID = this.props.currentIMPBoxID;
        if (this.props.IMPBoxes !== prevProps.IMPBoxes) {
            this.setState({'impboxes': this.state.IMPBoxes});

        }

        if(this.props.currentIMPBoxID!==prevProps.currentIMPBoxID)
        {
            this.setState({"selectedImpbox":currentIMPBoxID});
        }



    }
    toggleStandby()
    {
        let newSsb = !this.state.showStandby;

        this.setState({showStandby:newSsb});
    }

    togglePlanFilter(plantoToggle)
    {
        let pf = this.state.planFilter;

        if(pf.includes(plantoToggle))
        {
            pf = pf.filter(function(item) {
            return item !== plantoToggle
        })
        }else
        {
            pf.push(plantoToggle);
        }

        this.setState({planFilter:pf});

    }

    switchImpbox(impbox_id, impbox, setIMPBoxContext)
    {
        this.setState({"selectedImpbox":impbox_id});
        this.props.showDetails(impbox_id, impbox, setIMPBoxContext)

    }

    dayDiff(d1, d2){


            var t2 = d2.getTime();
            var t1 = d1.getTime();

            return Math.floor((t2-t1)/(24*3600*1000));

    }

    render() {

        const setIMPBoxContext = this.context.setIMPBox;
        let sb_cnt=0;

        let onlineCnt=0, offlineCnt=0, neverCnt=0;
        let plan1Cnt=0, plan2Cnt=0, plan3Cnt=0, plan4Cnt=0, plan5Cnt=0, plan6Cnt = 0;

        /*****************************
         * Show the list of impboxes *
         ****************************/
        let ibToShow = [];
        let filter= this.state.filter;
        let planFilter= this.state.planFilter;
        let wts = "email";

        if(!!this.props.whatToShow) {
            wts = this.props.whatToShow;
        }

        let lts="Phone"; //label to show
        if(wts=="number")
        {
            lts="Phone";
        }else if(wts=="UUID")
        {
            lts="uuid";
        }else if(wts=="email")
        {
            lts="Email";
        }

        if (!!this.state.impboxes ) {

            //console.log (`this.state.impboxes = ${JSON.stringify(this.state.impboxes)}`)
            let daBoxes = this.state.impboxes;
            let header = <div key={"impbox_head"} style={{'fontSize': '10pt'}} className={'headerRow'}>
                <div style={{'width': '45px'}} className={'headerItem'}>ID</div>
                <div style={{'width': '110px'}} className={'headerItem'}>{lts}</div>
                <div style={{'width': '60px'}} className={'headerItem'}>Actions</div>
                <div className={'headerEnd'}></div>
            </div>

            ibToShow.push(header);


            for (let ib of daBoxes) {

                let rowCol = "#585757";
                let dDiff="";
                let bgcolor="white";
                let ssb = this.state.showStandby;

                if(ib.Setting) {
                    let settings = ib.Setting.settings;
                    let sb = settings.standby_mode;
                    let sbEnabled = sb.details.enabled;
                    if (sbEnabled) {
                        bgcolor = "red";
                        sb_cnt++;
                        if(!ssb)
                        {continue;}
                    }

                }

                if (ib.Heartbeats) {


                    if (ib.Heartbeats.length > 0) {
                        var lpdate = new Date(ib.Heartbeats[0].lastPing);
                        let hb = ib.Heartbeats[0];
                        let ibInactive = hb.isInactive
                        if (ibInactive === true) {
                            if(filter=='online'||filter=='never')
                            {
                                continue;
                            }

                            dDiff = "("+this.dayDiff(lpdate,new Date())+")";
                            rowCol = "#ea0707";
                        } else {
                            if(filter=='offline'||filter=='never')
                            {
                                continue;
                            }

                            rowCol = "#56ea07";
                        }
                    }else{
                        if(filter=='online'||filter=='offline')
                        {
                            continue;
                        }

                    }
                }


                if (ib.impbox_id==this.state.selectedImpbox){
                    bgcolor="#f7f9c7";
                }

                let daPlan=0;
                if(!!ib.Subscription) {
                    if (!!ib.Subscription.Plan) {
                        daPlan = ib.Subscription.Plan.plan_id;
                    }
                }

                let pBG="#EE0000";
                if (daPlan==1 )
                {
                    pBG = "#A8E2F2";
                    if(!planFilter.includes(1))
                    {continue}
                    plan1Cnt++;

                } else if (daPlan==2 )
                {
                    pBG = "#fdfc0e";
                    if(!planFilter.includes(2))
                    {continue}

                    plan2Cnt++;
                }  else if (daPlan==3 )
                {
                    pBG = "#fd09e7";
                    if(!planFilter.includes(3))
                    {continue}
                    plan3Cnt++;

                }  else if (daPlan==4 )
                {
                    pBG = "#88FD00";
                    if(!planFilter.includes(4))
                    {continue}
                    plan4Cnt++;
                } else if (daPlan==5 )
                {
                    pBG = "#fd9006";
                    if(!planFilter.includes(5))
                    {continue}
                    plan5Cnt++;
                } else
                {
                    if(!planFilter.includes(6))
                    {continue}
                    plan6Cnt++;
                }

                let idts;//id to show
                if(wts=="number")
                {
                    idts=ib.phone_number;
                }else if(wts=="UUID")
                {
                    idts=ib.impbox_uuid;
                }else if(wts=="email")
                {
                    let bms = ib.box_mobiles;
                    if(bms.length<1)
                    {
                        //default to number
                        idts=ib.phone_number;
                    }else
                    {
                        //grab the first boxMobile
                        let bm=bms[0];
                        if(!bm.Mobile || !bm.Mobile.User)
                        {
                            //default to number
                            idts=ib.phone_number;
                        }else
                        {
                            idts = bm.Mobile.User.PII.email;
                        }
                    }



                }

                let thisIb = <div key={"impbox_" + ib.impbox_id} className={'listRow'} style={{backgroundColor:bgcolor, paddingBottom:'1px', paddingTop:'1px'}}>
                    <div style={{'width': '45px', backgroundColor:pBG, 'fontSize': '12px', borderRadius:'10px',textAlign:'center',  paddingLeft: '0px'}} className={'listItem'}>{ib.impbox_id}</div>
                    <div style={{'width': '110px', 'fontSize': '12px', 'overflow': 'auto'}}
                         className={'listItem'}>{idts}</div>
                    <div style={{'width': '90px', color: rowCol, fontSize:"12px"}} className={'listItem'}
                         onClick={showDetails => this.switchImpbox(ib.impbox_id, ib, setIMPBoxContext)}>Details {dDiff}
                    </div>

                    <div className={'listEnd'}></div>
                </div>


                if (ib.Heartbeats.length > 0) {
                    let hb = ib.Heartbeats[0];
                    let ibInactive = hb.isInactive
                    if(ibInactive)
                    {
                        offlineCnt++;
                    }else {
                        onlineCnt++;
                    }
                }else
                {
                    neverCnt++;
                }

                ibToShow.push(thisIb)
            }
        }




        return (
            <div>
                <div style={{backgroundColor:"#efefef", padding:"1px", fontSize:"9pt" }}>
                    <input type={"checkbox"} id={"showStandby"} defaultChecked={false} checked={this.state.showStandby} onClick={()=>this.toggleStandby()}/> Show Standby ({sb_cnt})

                </div>
                <div style={{backgroundColor:"#efefef", padding:"1px", fontSize:"9pt" }}>
                    <input type="radio" id="onlineStatus_online"
                           name="onlineStatus" value="online" onClick={()=>this.filterImpboxes("online")} />&nbsp;
                    <label htmlFor="onlineStatus_online">Online({onlineCnt}) </label> &nbsp; &nbsp;
                    <input type="radio" id="onlineStatus_offline"
                           name="onlineStatus" value="offline" onClick={()=>this.filterImpboxes("offline")} />&nbsp;
                    <label htmlFor="onlineStatus_offline">Offline({offlineCnt})</label> &nbsp; &nbsp; <br/>


                    <input type="radio" id="onlineStatus_never"
                           name="onlineStatus" value="never" onClick={()=>this.filterImpboxes("never")} />&nbsp;
                    <label htmlFor="onlineStatus_never">Never Connected({neverCnt})</label> &nbsp; &nbsp;

                    <input type="radio" id="onlineStatus_all"
                           name="onlineStatus" value="all" defaultChecked={true} onClick={()=>this.filterImpboxes("All")} />&nbsp;
                    <label htmlFor="onlineStatus_all">All</label>
                </div>

                <div style={{backgroundColor:"#efefef", padding:"3px", fontSize:"9pt" }}>
                    <div  style={{float:"left",borderRadius:"10px", backgroundColor:"#A8E2F2", padding:"3px", paddingTop:'0px', paddingBottom:'0px'}}>
                        <input type="checkbox" id="planType_1"
                               name="plan1" value="1" onClick={()=>this.togglePlanFilter(1)} checked={planFilter.includes(1)} />&nbsp;
                        <label htmlFor="planType_1">P Zero({plan1Cnt}) </label>
                    </div>
                    <div  style={{float:"left",borderRadius:"10px", backgroundColor:"#fdfc0e", padding:"3px", paddingTop:'0px', paddingBottom:'0px'}}>
                        <input type="checkbox" id="planType_2"
                               name="plan2" value="2"  onClick={()=>this.togglePlanFilter(2)}  checked={planFilter.includes(2)} />&nbsp;
                        <label htmlFor="planType_2">P X({plan2Cnt}) </label>
                    </div>
                    <div  style={{float:"left",borderRadius:"10px", backgroundColor:"#fd09e7", padding:"3px", paddingTop:'0px', paddingBottom:'0px'}}>
                        <input type="checkbox" id="planType_3"
                               name="plan3" value="3"  onClick={()=>this.togglePlanFilter(3)}  checked={planFilter.includes(3)} />&nbsp;
                        <label htmlFor="planType_3">Annual({plan3Cnt}) </label>
                    </div>
                    <div  style={{float:"left",borderRadius:"10px", backgroundColor:"#88FD00", padding:"3px", paddingTop:'0px', paddingBottom:'0px'}}>
                        <input type="checkbox" id="planType_4"
                               name="plan4" value="4"  onClick={()=>this.togglePlanFilter(4)}  checked={planFilter.includes(4)} />&nbsp;
                        <label htmlFor="planType_4">Monthly({plan4Cnt}) </label>
                    </div>
                    <div  style={{float:"left",borderRadius:"10px", backgroundColor:"#fd9006", padding:"3px", paddingTop:'0px', paddingBottom:'0px'}}>
                        <input type="checkbox" id="planType_5"
                               name="plan5" value="5"  onClick={()=>this.togglePlanFilter(5)}  checked={planFilter.includes(5)} />&nbsp;
                        <label htmlFor="planType_5">Fixed({plan5Cnt}) </label>
                    </div>
                    <div  style={{float:"left",borderRadius:"10px", backgroundColor:"#EE0000", padding:"3px", paddingTop:'0px', paddingBottom:'0px'}}>
                        <input type="checkbox" id="planType_6"
                               name="plan6" value="6"  onClick={()=>this.togglePlanFilter(6)}  checked={planFilter.includes(6)} />&nbsp;
                        <label htmlFor="planType_6">No Sub({plan6Cnt}) </label>
                    </div>
                </div>

                <div>
                    {ibToShow}
                </div>
            </div>
        );
    }
}
export default IMPBoxList;
