import React, {Component} from 'react';
import {IMPBoxUsers} from "./ImpBoxUsers";
import {placeCall, factoryReset, updateIMPBoxPhoneNumberApi} from "../../../util/apiServiceUtil";


import _ from "lodash";
import {withAlert} from "react-alert";

class IMPBoxDetails extends Component {

    constructor(props) {
        super(props);
        console.log("In IMPBoxDetails constructor");
        this.state = {
            "error": "",
            "currentIMPBox": null,
            "owner_box_mobile_id" : null
        };
    }

    componentDidUpdate(prevProps) {

        console.log(`IMPBoxDetails: in componentDidUpdate... Prev: ${JSON.stringify(prevProps.currentIMPBox)} Current: ${JSON.stringify(this.props.currentIMPBox)}  `)

        let uniView = [];

        if (prevProps.currentIMPBox !== this.props.currentIMPBox) {
            this.setState({
                currentIMPBox: this.props.currentIMPBox
            });
        }
    }

    impboxFactoryReset(impbox) {

        var answer = window.confirm(`Are you sure you want to reset impbox ${impbox.impbox_id}? This will delete the impbox and delete all Limited Users and Co-Owners.`);
        if (answer) {

        }
        else {
            return
        }


        console.log("Starting the IMPBox Reset Process" );
        factoryReset(impbox, (returnDetails) => {

                console.log("API return from factoryReset " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                };
                this.setState({"details": details});
                console.log(`Setting state details : success = ${returnDetails.success}`);
                if (!this.props.refreshIMPBoxes){
                    console.log(`refreshIMPBoxes not found!!`);
                } else {
                    console.log(`Calling refreshIMPBoxes!!`);
                    this.props.refreshIMPBoxes();
                }
                this.props.alert.success(`${JSON.stringify(returnDetails)}`);


            },
            (error) => {

                console.log("ERROR in factoryReset =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR" + JSON.stringify(error));
                this.props.refreshIMPBoxes();
            });
    }

    placeCall(impbox_id, is_international)
    {

        let isExecuted = window.confirm("Are you sure you want to place a call?");
        if(!isExecuted)
        {
            return;
        }

        placeCall(

            {
                "impbox_id":impbox_id,
                "is_international":is_international

            },
            (resp) => {

            },
            (error) => {
                console.log("ERROR in getIMPBoxes =  " + error);
                this.setState({"error": error});
            })
    }

    changePhoneNumber(e, fld_id) {

        console.log("changePhoneNumber: updated field " + fld_id);
        const target = e.target;
        const val = target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        console.log("changeText: updating state with  " + JSON.stringify(change));
        this.setState(change);
        console.log("changeText: after updating state :  " + JSON.stringify(this.state["user"]));

    }

    updatePhoneNumber() {
        console.log("Starting the phonenumber update Process");


        let impbox_details = {
            "impbox_id" : this.state.currentIMPBox.impbox_id,
            "phone_number" : this.state.currentIMPBox.phone_number,
            "box_mobile_id" : this.state.owner_box_mobile_id
        };


        updateIMPBoxPhoneNumberApi(impbox_details, (returnDetails) => {

                console.log("API return from updateIMPBoxPhoneNumberApi " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success
                };
                this.setState({"details": details});

                let success_message;
                success_message = "Updated IMPBox PhoneNumberq: " + returnDetails.impbox.phone_number;
                this.props.alert.success(success_message);
                this.props.refreshIMPBoxes();
            },
            (error) => {

                console.log("ERROR in update phone number =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
                this.props.refreshIMPBoxes();
            });
    }

    getOwner(owner) {
        console.log(`The box_mobile_id is ${JSON.stringify(owner)}`);
        this.setState({
            owner_box_mobile_id: owner
        });
    }
    render() {

        let ibDetails = [];
        let hbDetails = "";
        let rowCol = "#585757";


        let ibState = <h1>
            <div style={{color: rowCol, textAlign: 'center'}}></div>
        </h1>


        if (this.state.currentIMPBox) {
            if (this.state.currentIMPBox.Heartbeats) {
                if (this.state.currentIMPBox.Heartbeats.length > 0) {
                    var date = new Date(this.state.currentIMPBox.Heartbeats[0].lastPing);
                    hbDetails = date.toLocaleString([], {timeZoneName: 'long'});
                    if (this.state.currentIMPBox.Heartbeats[0].isInactive) {
                        rowCol = "#ea0707";
                        ibState = <h1>
                            <div style={{color: rowCol, textAlign: 'center'}}>Offline</div>
                        </h1>
                    } else {
                        rowCol = "#56ea07";
                        ibState = <h1>
                            <div style={{color: rowCol, textAlign: 'center'}}>Online</div>
                        </h1>
                    }
                } else {
                    ibState = <h1 key={"h2"}>
                        <div style={{color: rowCol, textAlign: 'center'}}>Never Connected</div>
                    </h1>
                }
            }

            let usrs;
            if(!this.state.currentIMPBox)
            {
                usrs=<div></div>
            }else
            {
                usrs = <IMPBoxUsers impbox_id={this.state.currentIMPBox.impbox_id} ownerFunct={this.getOwner.bind(this)}  />
            }


            ibDetails.push(ibState)
            ibDetails.push(<div>
                <div style={{float: "left"}}><p key={"det1"}><b>IMPBox_id:</b> {this.state.currentIMPBox.impbox_id}</p>
                </div>
                &nbsp;&nbsp;
                <button style={{marginLeft: "10px", float: "left", fontSize: "8pt"}}
                        onClick={() => this.placeCall(this.state.currentIMPBox.impbox_id, false)}>Place Call
                </button>
                &nbsp;&nbsp;
                <button onClick={() => this.placeCall(this.state.currentIMPBox.impbox_id, true)}
                        style={{marginLeft: "10px", float: "left", fontSize: "8pt"}}>Place International Call
                </button>
            </div>)
            ibDetails.push(<p style={{clear:"both"}} key={"det2"}><b>IMPBox_UUID:</b> {this.state.currentIMPBox.impbox_uuid}</p>)
            ibDetails.push(<div key={"det3"}><b>Users:</b> {usrs}</div>)

            ibDetails.push(<p key={"det4"}><b>impbox_hardware_id:</b> {this.state.currentIMPBox.impbox_hardware_id}</p>)
            ibDetails.push(<p key={"det5"}><b>hardware_version:</b> {this.state.currentIMPBox.hardware_version}</p>)
            ibDetails.push(<p key={"det6"}><b>firmware_version_desired:</b> {this.state.currentIMPBox.firmware_version_desired}</p>)
            ibDetails.push(<p key={"det7"}><b>firmware_version_reported:</b> {this.state.currentIMPBox.firmware_version_reported}
            </p>)
            // ibDetails.push(<p key={"det8"}><b>phone_number:</b> {this.state.currentIMPBox.phone_number}</p>)
            ibDetails.push(<p key={"det13"}><b>phone_number:</b>

                        <input type={'text'} value={this.state.currentIMPBox.phone_number}
                               onChange={(event, fld_id) =>  this.changePhoneNumber(event, 'currentIMPBox.phone_number') }
                               size={'20'}/>

                <button onClick={() => this.updatePhoneNumber()}>Update PhoneNumber</button>

            </p>)
            ibDetails.push(<p key={"det10"}><b>Last Ping:</b> {hbDetails}</p>)
            ibDetails.push(<p key={"det12"}><b>Impbox Administration: </b>
                <button onClick={() => this.impboxFactoryReset({"impbox_id" : this.state.currentIMPBox.impbox_id})} >Reset to  Factory Settings</button>
            </p>)


        } else {
            ibDetails.push(<h1 key={"h1"}><div style={{color: rowCol, textAlign: 'center'}}>No IMPBox Selected</div></h1>)
        }


        return (
            <div>
                {ibDetails}

            </div>
        );
    }
}
export default withAlert()(IMPBoxDetails);


