import React, {Component} from 'react';

import {pushFCM} from "../../util/apiServiceUtil";
import JSONInput from 'react-json-editor-ajrm';
import {ImpCollapsible} from "../Util/ImpCollapsible";


class FCMForm extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing FCMAdmin")

        let defFCMState = {
            "notification": {"body": "Sample message for Android endpoints", "title": "TitleTest"},
            "data": {"key": "value"}
        };

        this.state = {};
        this.state.error = "";
        this.state.response = "";
        this.state.fcmJSON = defFCMState;
    }

    componentDidMount(){
        const {userContext} = this.props;
        if (!!userContext) {
            let mobileToken = userContext.user.Mobiles[0].notification_token;
            let mobileNotType = userContext.user.Mobiles[0].notification_type;
            let mobileNotArn = userContext.user.Mobiles[0].notification_sns_arn;
            this.setState({"device_token": mobileToken});
            this.setState({"notification_type": mobileNotType});
            this.setState({"notification_sns_arn": mobileNotArn});
        }
    }

    componentDidUpdate(prevProps) {

        console.log(`FCMForm: componentDidUpdate: this.props.userContext  ${this.props.userContext}`);

        if (this.props.userContext !== prevProps.userContext) {

            let uc = this.props.userContext;

            if (!!uc && !!uc.user && !!uc.user.Mobiles[0] ) {
                let mobileToken = uc.user.Mobiles[0].notification_token;
                let mobileNotType = uc.user.Mobiles[0].notification_type;
                let mobileNotArn = uc.user.Mobiles[0].notification_sns_arn;
                this.setState({"device_token": mobileToken});
                this.setState({"notification_type": mobileNotType});
                this.setState({"notification_sns_arn": mobileNotArn});
            } else {
                console.warn(`Incomplete User Context (missing notification_data).`);
            }

        }

    }

    fcmPushHandler(deviceToken, apnObj, sns_android_arn, apn_env) {

        let apnStr = JSON.stringify(apnObj)
        console.log("fcmPushHandler  called... with apn = " + apnStr);
        console.log("fcmPushHandler  called... with sns_android_arn = " + sns_android_arn);

        let opts = {
            "deviceToken": deviceToken,
            "sns_android_arn": sns_android_arn,
            "apnStr": apnStr,
            "apn_env": apn_env
        };

        pushFCM(
            opts,
            (pushresponse) => {
                console.log("PushResponse: " + pushresponse)
                this.setState({response: pushresponse});
                this.setState({"error": ""});
            },
            (error) => {
                console.log("PushError: " + error)
                this.setState({response: ""});
                this.setState({"error": error});
            },
        );
    }

    handlePushFCM(e) {

        let deviceToken = this.refs.deviceToken.value;
        let fcmJSON = this.state.fcmJSON;
        let sns_android_arn = this.refs.sns_android_arn.value;
        console.log("fcmJSON = " + fcmJSON);
        console.log("sns_android_arn = " + sns_android_arn);

        let apn_env = "production";


        console.log("DeviceToken = " + deviceToken);
        this.fcmPushHandler(deviceToken, fcmJSON, sns_android_arn, apn_env);
        e.preventDefault();
    }

    changeJSON(e, fld_id) {
        console.log("changeJSON: updated field " + fld_id);
        let val = e.jsObject;
        this.setState({"fcmJSON": val});
        console.log("changeJSON " + val);
    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };

        let defFCM = this.state.fcmJSON;
        let message2 = 'Generic FCM Form';

        console.log ("Rendering FCMForm ... ")

        return (

                <div style={layoutStyle}>

                    <ImpCollapsible collapsed={false} header={'Generate FCMs'}>

                        <div>Push test FCM to registered Android Mobile device.</div>

                        <div style={layoutStyle}>

                            <div style={{borderStyle: 'none', textAlign: 'center', padding: 20}}>

                                <div style={{
                                    width: '560px',
                                    border: '1px',
                                    borderStyle: 'none',
                                    borderRadius: '15px',
                                    background: 'lightgrey',
                                    margin: 'auto',
                                    padding: 20,
                                }}>

                                    <div><p>{message2}</p></div>
                                    <div><p>Create Custom FCM  here.</p></div>
                                    <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                                    <div><p>{this.state.response !== "" && JSON.stringify(this.state.response)}</p>
                                    </div>

                                    <form onSubmit={this.handlePushFCM.bind(this)} autoComplete="on">

                                        <div style={{
                                            margin: 'auto',
                                            padding: 5,
                                        }}>
                                            <div className={'fieldLabelLeft'}>Device Token</div>

                                            <input style={{width: '100%'}}
                                                   type={'text'} value={this.state.device_token}
                                                   ref="deviceToken"
                                                   placeholder="deviceToken"
                                                   autoComplete="on"
                                            />

                                            <div className={'fieldLabelLeft'}>SNS Endpoint ARN</div>

                                            <input style={{width: '100%'}}
                                                   type={'text'} value={this.state.notification_sns_arn}
                                                   ref="sns_android_arn"
                                                   placeholder="sns_android_arn"
                                                   autoComplete="on"
                                            />
                                        </div>

                                        <div className={'fieldHolder'}>
                                            {/*<div className={'fieldLabel'}>APN JSON</div>*/}
                                            <div className={'fieldInput'}>
                                                <div style={{textAlign: 'left'}}>

                                                    <JSONInput
                                                        id='apn_imp_003'
                                                        placeholder={defFCM}
                                                        //colors      = { {  string: "#DAA520"  }}
                                                        //locale      = { locale }
                                                        height='300px'
                                                        width='500px'
                                                        onChange={(event, fld_id) => this.changeJSON(event, 'fcm_json')}
                                                    />
                                                </div>

                                            </div>
                                            <div className={'fieldEnd'}></div>
                                        </div>


                                        <div>
                                            <input
                                                type="submit"
                                                value="Send Message"/>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </ImpCollapsible>

                </div>

        );
    }

}
export {FCMForm};

