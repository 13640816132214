import React, {useContext, useState} from 'react'
import Checkbox from './Checkbox'
import AppUserContext from "../../../contexts/AppUserContext";
import {getAppAccessTokenApi} from "../../../util/apiServiceUtil";


const StandByModeCheckBox = (args) => {

    console.log(`StandByModeCheckBox args =  ${JSON.stringify(args)}`);
    const contextType = AppUserContext;
    const {app_username, setUsername} = useContext(AppUserContext)

    let setStandByMode = args["network_api"];
    let box_mobile_id = args["box_mobile_id"];
    let username = args["username"];
    let impbox_id = args["impbox_id"];
    let standby_modeX = args["standby_mode"];
    let refreshSettings =  args["refreshSettings"];

    const [label, setLabel] = useState(`StandBy Mode: ${standby_modeX}`);
    console.log (`StandByModeCheckBox: from IMPBoxSettings: Before Use State: standby_mode = ${standby_modeX} `)
    // const [checked, setChecked] = useState(standby_modeX);
    // console.log(`StandByModeCheckBox from IMPBoxSettings after Use State  :  ${username}  checked : ${checked} standby_mode : ${standby_modeX} ` );
    // console.log(`XX: label ${label} standby_modeX ${standby_modeX} username: ${username} `);

    setUsername(username);

    //Authenticate As User

    const authenticateAsAppUser = (username, setUsername, checked) =>{


        let cl = {};

        cl.username = username;
        console.log(`StandByModeCheckBox : authenticateAsAppUser:   ${username}`);


        getAppAccessTokenApi(cl, (returnDetails) => {

                console.log("API return from getAppAccessTokenApi " + JSON.stringify(returnDetails));


                console.log(`Adding to  local storage: ${returnDetails.Account.JWT} ${returnDetails.username}`)
                // Add to  Session.
                localStorage.setItem('app_access_token', returnDetails.Account.JWT);
                localStorage.setItem('app_username', returnDetails.Account.username);
                localStorage.setItem('app_user_id', returnDetails.Account.user_id);

                if (returnDetails.success === true) {
                    setUsername(username);
                    updateStandByMode(checked)
                } else {
                    setLabel('Standby Mode Error: ' + 'Get Refresh Token: UnSuccessful Call');
                    return;
                }
            },
            (error) => {
                console.log("ERROR in getAppAccessTokenApi =  " + JSON.stringify(error));
                setLabel('Standby Mode Error: ' + error);
                return;
            });

    }

    const updateStandByMode = (checked) => {

        console.log("In StandByModeCheckBox with  ${}...")

        let payload = {};
        payload.box_mobile_id = box_mobile_id;
        payload.setting_name = "standby_mode";
        payload.state = "Pending";
        payload.details = { enabled: checked};

        setStandByMode(
            payload,
            (response) => {

                console.log(`StandByModeCheckBox: updateStandByMode: checked = ${checked} changing label also.`);
                setLabel('Standby Mode: ' + checked);
                // setChecked(checked);
                refreshSettings(impbox_id)
                return;

            },
            (error) => {
                console.log(`Error from setStandByMode ${JSON.stringify(error)}`)
                setLabel('Standby Mode Error: ' + error);
                return;
            })
    }

    const onChange = (event) => {
        const {value, name, checked} = event.target;
        authenticateAsAppUser(username, setUsername, checked);
    };


    console.log (`Rendering IMPBoxSettings in StandByModeCheckBox with standby_modeX =  ${standby_modeX} `)
    return (<div className=''>
        <div className=''>
            <div className=''>
                <div >
                    <Checkbox label={`StandBy Mode: ${standby_modeX}`} isSelected={standby_modeX} onCheckboxChange={onChange}></Checkbox>
                </div>
            </div>
        </div>
    </div>);

}
export default StandByModeCheckBox;




