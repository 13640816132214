import React, {Component} from 'react';
import {createFirmware} from "../../util/apiServiceUtil";
import {withAlert} from 'react-alert'
import {ImpCollapsible} from "../Util/ImpCollapsible";


//Test Only!!!
class FirmwareCreate extends Component {


    constructor(props) {
        super(props);
        console.log("In FirmwareCreate constructor");
        this.state = {
            "firmware": {
                "version": "",
                "location": "",
            },
            "error": "",
            "details": {}

        };
    }


    changeText(e, fld_id) {

        console.log("changeText: updated field " + fld_id);
        const target = e.target;
        const val = target.name === 'mkting_agree' ? target.checked : target.value;

        let change = {};

        if (fld_id.includes(".")) {

            const objs = fld_id.split('.');
            let change2 = this.state[objs[0]];
            change2[objs[1]] = val;
        } else {
            change[fld_id] = val;
        }

        console.log("changeText: updating state with  " + JSON.stringify(change));
        this.setState(change);
        console.log("changeText: after updating state :  " + this.state["user"]);

    }

    createFirmware() {
        console.log("Starting the Firmware Creation Process");

        let firmware = {
            firmware: {
                "version": this.state.firmware.version,
                 "location": this.state.firmware.location
            }
        }

        createFirmware(firmware, (returnDetails) => {

                console.log("API return from Create Firmware " + JSON.stringify(returnDetails));

                let details = {
                    success: returnDetails.success,
                    firmware_id: returnDetails.firmware_id
                };
                this.setState({"details": details});
                let success_message = "Created Firmware: Id =  " + details.firmware_id ;

                this.props.alert.success(success_message);
                this.props.refreshFirmwares();
            },
            (error) => {

                console.log("ERROR in Create Firmware =  " + JSON.stringify(error));
                this.setState({"error": error.error});
                this.props.alert.error("ERROR: " + JSON.stringify(error));
            });
    }

    render() {


        let success_message;
        if (this.state.details.firmware_id) {
            success_message =
                <div>
                    <p style={{color: 'green'}}>New Firmware Created: Id = {this.state.details.firmware_id}</p>
                </div>
        }

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };


        return (
            <div>
                <ImpCollapsible collapsed={true} header={'Create New Firmware' }>

                    <div style={{'fontSize': '10pt'}}>

                        <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                        {success_message}

                        <div style={layoutStyle}>
                            <div style={layoutStyle}>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>Version:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.firmware.version}
                                               onChange={(event, fld_id) => this.changeText(event, 'firmware.version')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                                <div className={'fieldHolder'}>
                                    <div className={'fieldLabel'}>location:</div>
                                    <div className={'fieldInput'}>
                                        <input type={'text'} value={this.state.firmware.location}
                                               onChange={(event, fld_id) => this.changeText(event, 'firmware.location')}
                                               size={'60'}/>
                                    </div>
                                    <div className={'fieldEnd'}></div>
                                </div>

                            </div>

                            <button onClick={() => this.createFirmware()}>Create Firmware</button>
                        </div>


                    </div>

                </ImpCollapsible>

            </div>
        );
    }
}
export default withAlert()(FirmwareCreate);
