import React, {Component} from 'react';

import {setupApi} from "../../../util/apiServiceUtil";
import {ImpCollapsible} from "../../Util/ImpCollapsible";

class Setup extends Component {

    constructor(props) {
        super(props);
        console.log("In LogCall constructor");
        this.state = {
            "impbox_hardware_id": "FF-FF-FF-FF-FF-FF",
            "impbox_uuid": "AB445",
            "hardware_version": "1.0",
            "firmware_version": "0.5",
            "ssid": "wifi-name",
            "wifi_password": "wifi-password",
            "error": "",
            "details" : {}

        };
    }

    pickDisposition(e) {
        this.setState({disposition: e.target.value});

    }

    pickAction(e) {
        this.setState({action: e.target.value});

    }

    pickResolution(e) {
        this.setState({resolution: e.target.value});

    }

    changeText(e, fld_id) {
        let val = e.target.value;
        let no = {};

        no[fld_id] = val;


        this.setState(no);
    }

    setup() {

        this.setState( { "details" : {} });
        this.setState({"error": ""});

        let setup = {};
        setup.impbox_hardware_id = this.state.impbox_hardware_id;
        setup.impbox_uuid = this.state.impbox_uuid;
        setup.hardware_version = this.state.hardware_version;
        setup.firmware_version = this.state.firmware_version;

        setup.settings =  [
            {
                "setting_name" : "wifi",
                "details" : {
                    "ssid" : this.state.ssid,
                    "password" : this.state.wifi_password
                }
            }]


        setupApi(setup, (returnDetails) => {

                console.log("API return from setup " + JSON.stringify(returnDetails));

                let details = {
                    impbox_id: returnDetails.impbox_id
                };

                this.setState( { "details" : details });

            },
            (error) => {

                console.log("ERROR in getUser =  " + error);
                this.setState({"error": error});
            });

    }


    render() {

        let success_message;
        if ( this.state.details.impbox_id) {
            success_message =
                <div>
                    <p style={{color: 'green'}}>New IMPBox Created: impbox_id = {this.state.details.impbox_id}</p>
                </div>
        }

        return (
            <div>


                <div style={{'fontSize': '10pt'}}>
                    <div>

                        <ImpCollapsible collapsed={true} header={'Setup'}>

                            <div><p style={{color: 'red'}}>{this.state.error}</p></div>
                            {success_message}


                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>IMPBox Hardware Id: MAC Address:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox_hardware_id}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox_hardware_id')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>IMPBox UUID: Serial Number:</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.impbox_uuid}
                                           onChange={(event, fld_id) => this.changeText(event, 'impbox_uuid')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Hardware Version</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.hardware_version}
                                           onChange={(event, fld_id) => this.changeText(event, 'hardware_version')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Firmware Version</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.firmware_version}
                                           onChange={(event, fld_id) => this.changeText(event, 'firmware_version')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Wifi SSID</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.ssid}
                                           onChange={(event, fld_id) => this.changeText(event, 'ssid')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <div className={'fieldHolder'}>
                                <div className={'fieldLabel'}>Wifi Password</div>
                                <div className={'fieldInput'}>
                                    <input type={'text'} value={this.state.wifi_password}
                                           onChange={(event, fld_id) => this.changeText(event, 'wifi_password')}
                                           size={'60'}/>
                                </div>
                                <div className={'fieldEnd'}></div>
                            </div>

                            <button onClick={() => this.setup()}>Setup</button>

                        </ImpCollapsible>

                    </div>
                </div>

            </div>
        );
    }
}
export { Setup };
